const pipesSystemsPEHDRouter = {
    path: "zbiorniki-rurowe-pehd",
    name: "pipes-systems-pehd",
    redirect: { name: "pipes-systems-pehd.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            name: "pipes-systems-pehd.investment-data",
            meta: { partner: "uponor-pipes-systems-PEHD" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            name: "pipes-systems-pehd.choosing-parameters",
            meta: { partner: "uponor-pipes-systems-PEHD" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-zbiornika",
            name: "pipes-systems-pehd.selecting-products",
            meta: { partner: "uponor-pipes-systems-PEHD" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            meta: { partner: "uponor-pipes-systems-PEHD" },
            name: "pipes-systems-pehd.archive",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default pipesSystemsPEHDRouter;

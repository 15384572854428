import { defineStore } from 'pinia';
import { api } from '@/axios/axios';
import { getAsync, postAsync, putAsync } from '@/shared/asyncAxiosUtil';
import { Cookies } from '@/shared/cookies';
import { SupportedCountries } from '@/constant/constant';
export const useMainStore = defineStore('main', {
    state: () => ({
        unauthorizedSettings: {},
        lang: 'pl',
        country: 'PL',
        //TODO: get user from backend
        user: {
            userData: {
                accountTypeId: 1,
            },
            firmData: {
                firmAdres: '',
                firmName: '',
                firmPostcode: '',
                firmTown: '',
            },
        },
        userId: null,
        currentPartner: { id: '', label: '' },
        userWithoutPermission: process.env.VUE_APP_PERMISSION_SEND_EMAIL === 'production'
            ? [
                3069, 834, 3061, 3041, 3066, 3065, 3063, 6007, 1026, 3285, 419, 42,
                45, 6015, 43, 63, 93, 58,
            ]
            : [],
        status: {
            error: {
                value: false,
                message: '',
            },
            success: false,
            pending: false,
        },
        isEmbedded: false,
        exportFilesMaxDate: null,
        isAdminFromWF2: false,
    }),
    getters: {
        isAfterExportFileMaxDate: state => {
            if (!state.exportFilesMaxDate) {
                return false;
            }
            const maxDate = new Date(Date.parse(state.exportFilesMaxDate));
            const currentDate = new Date();
            return currentDate.getTime() > maxDate.getTime();
        },
    },
    actions: {
        async fetchSettings() {
            const response = await api.get('settings');
            this.unauthorizedSettings = response.data.data;
            this.setCountry(response.data.data.userSettings.countryCode);
            // TODO: add default country if country not supported.
        },
        setLang(lang) {
            this.lang = lang;
            api.defaults.headers.common['Accept-Language'] = lang;
            Cookies.setCookie('lang-choice', lang, 100000);
            // i18n.locale.value = lang;
        },
        setCountry(country) {
            country = country.toUpperCase(); // just to be sure it's always upper case
            const isSupported = Object.values(SupportedCountries).includes(country);
            if (!isSupported) {
                // if county set by ip (fetchSettings) or from url (router) it may be unsupported - e.g EN
                // it may lead to errors - e.g in investment coordinates
                country = SupportedCountries.PL;
            }
            this.country = country;
            api.defaults.headers.common['Custom-Header-Country'] = country;
            Cookies.setCookie('country-choice', country, 100000);
        },
        setStatus(newStatus) {
            this.status[newStatus.key] = newStatus.value;
        },
        sendFile(payload) {
            return postAsync({
                url: 'file/sendurl',
                payload: {
                    base64: payload.base64,
                    calculationKey: payload.calculationKey,
                    typeFile: payload.fileType,
                },
                setStatus: this.setStatus,
            });
        },
        downloadPDF(payload) {
            return postAsync({
                url: 'file',
                payload: {
                    base64: payload.base64,
                    calculationKey: payload.calculationKey,
                    typeFile: payload.fileType,
                },
                setStatus: this.setStatus,
            });
        },
        checkGPS(val) {
            return getAsync({
                url: `check/gps?gps=${val}`,
                setStatus: this.setStatus,
            });
        },
        sendEnquiry(payload) {
            return postAsync({
                url: 'formContact',
                payload: {
                    id: payload.id,
                    calculationKey: payload.calculationKey,
                    message: payload.message,
                },
                setStatus: this.setStatus,
            });
        },
        setInitialStateApp(payload) {
            if (payload) {
                this.user = payload;
                const permission = this.userWithoutPermission.some(el => el === payload.userData.userId);
                this.userId = permission;
            }
        },
        updateProfileData(userData) {
            this.status.error = { value: false, message: '' };
            this.status.success = false;
            return putAsync({
                url: 'user',
                payload: userData,
                setStatus: this.setStatus,
            });
        },
        updateUserAgreement(calculatorId, email, phone) {
            return api.post('/user/agreements', [
                { id: calculatorId, email: email, phone: phone },
            ]);
        },
        async initApp() {
            const res = await getAsync({ url: 'user', setStatus: this.setStatus });
            this.setInitialStateApp(res);
        },
        addFavorite(calculationKey) {
            return api.put('user/historyFavorite', { calculationKey });
        },
        deleteFavorite(calculationKey) {
            return api.delete('user/historyFavorite', { data: { calculationKey } });
        },
        // only for WF-vue3
        async fetchChartData(moduleId, chartData, status) {
            const response = await postAsync({
                url: `module/${moduleId}/chart`,
                payload: chartData,
                setStatus: status,
            });
            return response;
        },
    },
});

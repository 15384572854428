const pumpingStationsRouter = {
    path: "pompownie",
    name: "pumping-stations",
    redirect: { name: "pumping-stations.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            meta: { partner: "ecol_unicon-pompownie" },
            name: "pumping-stations.investment-data",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            name: "pumping-stations.choosing-parameters",
            meta: { partner: "ecol_unicon-pompownie" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "przewody-tloczne",
            name: "pumping-stations.discharge-lines",
            meta: { partner: "ecol_unicon-pompownie" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-zbiornika",
            name: "pumping-stations.selecting-products",
            meta: { partner: "ecol_unicon-pompownie" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            meta: { partner: "ecol_unicon-pompownie" },
            name: "pumping-stations.archive",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default pumpingStationsRouter;

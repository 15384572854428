import iconPL from '@/assets/images/flags/flag-pl.svg';
import iconIN from '@/assets/images/flags/flag-in.svg';
import iconLT from '@/assets/images/flags/flag-lt.svg';
import i18n from '@/i18n';
export const getDefaultCalculatorMapConfig = () => {
    return {
        lat: 51.758745,
        lon: 19.455147,
        zoom: 6,
    };
};
export const TOKEN_COOKIE = 'rain-roomapitoken';
export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = 3600;
export var SupportedCountries;
(function (SupportedCountries) {
    SupportedCountries["PL"] = "PL";
    SupportedCountries["IN"] = "IN";
    SupportedCountries["LT"] = "LT";
})(SupportedCountries || (SupportedCountries = {}));
export const SUPPORTED_COUNTRIES_OBJECTS = [
    {
        value: SupportedCountries.PL,
        label: i18n.global.t('shared.supportedCountries.pl'),
        countryFlag: iconPL,
    },
    {
        value: SupportedCountries.IN,
        label: i18n.global.t('shared.supportedCountries.in'),
        countryFlag: iconIN,
    },
    {
        value: SupportedCountries.LT,
        label: i18n.global.t('shared.supportedCountries.lt'),
        countryFlag: iconLT,
    },
];

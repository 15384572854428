export const isValueWithUnit = (value) => {
    return (typeof value === 'object' &&
        value !== null &&
        'value' in value &&
        'unit' in value);
};
export const isValueWithUnitExtended = (value) => {
    return isValueWithUnit(value);
};
export var InputSize;
(function (InputSize) {
    InputSize["Small"] = "33%";
    InputSize["Medium"] = "50%";
    InputSize["Large"] = "75%";
    InputSize["VeryLarge"] = "100%";
})(InputSize || (InputSize = {}));
export const isMultiSelectionTable = (value) => {
    return (Array.isArray(value) &&
        typeof value[0] === 'object' &&
        'value' in value[0] &&
        'label' in value[0] &&
        'disabled' in value[0] &&
        'selected' in value[0]);
};
export var Orientation;
(function (Orientation) {
    Orientation["Vertical"] = "vertical";
    Orientation["Horizontal"] = "horizontal";
})(Orientation || (Orientation = {}));
export const isValidatableSection = (test) => {
    return typeof test === 'object' && test !== null && 'isValid' in test;
};
export const areValidatableSections = (test) => {
    if (!test || !Array.isArray(test)) {
        return false;
    }
    return test.every(isValidatableSection);
};

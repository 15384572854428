export default {
    shared: {
        yes: 'Tak',
        no: 'Nie',
        signOut: 'Wyloguj',
        newChoice: 'Nowy dobór',
        yourChoices: 'Twoje dobory',
        settings: 'Ustawienia',
        administration: 'Administracja',
        platform: 'PLATFORMA',
        termsAndConditions: 'Regulamin',
        letsTalk: 'POROZMAWIAJMY',
        privacyPolicy: 'Polityka prywatności',
        phoneNumber: '+ 48 730 037 309',
        companyName: 'RetencjaPL Sp z o.o.',
        total: 'Suma',
        calculationResults: 'Wyniki obliczeń',
        showAll: 'Pokaż pozostałe',
        chosenProduct: 'Wybrany',
        productsNotFound: 'Nie znaleziono produktów spełniających kryteria',
        addToFavorites: 'Dodaj do ulubionych',
        addedToFavorites: 'Dodano do ulubionych',
        calculationInProgress: 'Trwa obliczanie',
        back: 'Powrót',
        needHelp: 'Potrzebuję pomocy',
        selectionAttemptFailed: 'Próba doboru nieudana',
        selectionAttemptFailedMessage: 'Nie udało się dobrać urządzeń dla zadanych parametrów. Spróbuj użyć innych danych wejsciowych lub skontaktuj się z nami w celu pomocy',
        selectionAttemptFailedSelection: 'Nie udało się dobrać urządzeń dla zadanych przez użytkownika parametrów. Spróbuj przeliczyć dobór modyfikując dane wejściowe, aby zweryfikować problem.',
        dataHasBeenChanged: 'Dane zostały zmienione',
        updateData: 'Zaktualizuj dane',
        choosingParameters: 'Parametry doboru',
        contactUs: 'lub skontaktuj się z nami w celu pomocy',
        calculationData: 'Dane doboru',
        id: 'Lp.',
        exportFilesDisabled: {
            title: 'Eksport plików nieaktywny',
            description: 'Eksport plików nie jest aktywny dla doborów ze starej wersji platformy. Utwórz nową kalkulację w celu skorzystania z tej funkcjonalności',
        },
        files: {
            downloadFiles: 'Pobierz pliki',
            sendFiles: 'Wyślij e-mail',
            pdf: 'Plik PDF',
            zip: 'Paczka ZIP',
            xls: 'Plik XLS',
            xlsx: 'Wycena',
        },
        agreementModal: {
            title: 'Wyślij pliki na mój e-mail',
            content: 'Proszę o przesłanie szczegółowych wyników wyliczeń oraz wstępną propozycję produktową {partner} na mój adres e-mail. <br><br> Dane osobowe będą przetwarzane przez {partner} zgodnie z ',
            privacyPolicy: 'Zasadami przetwarzania danych',
            button1: 'Wyślij pliki',
            button2: 'Wyślij e-mail',
            mailAgreement: 'Wyrażam zgodę na otrzymywanie na mój adres e-mail od {partner} informacji handlowych np. dotyczących oferowanych produktów i usług, organizowanych szkoleń i innych wydarzeń, a także akcji promocyjnych i marketingowych',
            info: {
                part1: 'Zmiany powyższych zgód możesz dokonać w dowolnym momencie w zakładce ',
                part2: 'Prywatność',
                part3: 'w ustawieniach Konta użytkownika, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.',
            },
        },
        errorMessages: {
            required: 'Pole nie może być puste.',
            min: 'Wartość musi być wyższa niż {min}.',
            max: 'Wartość musi być niższa od {max}.',
            range: 'Wartość powinna być z zakresu {range}.',
        },
        sendQuestion: 'Wyślij zapytanie',
        contactForm: {
            title: 'Zadaj pytanie do doboru',
            desc: 'Abyśmy mogli odpowiedzieć na wszelkie Twoje pytania, pomóc w dobraniu urządzeń niestandardowych, zapraszamy do kontaktu bezpośrednio z producentem firmą <span>{partner}</span> poprzez poniższy formularz kontaktowy.',
            subject: 'Temat wiadomości',
            message: 'Treść wiadomości',
            messagePlaceholder: 'Bardzo prosimy o szczegółowy opis zapytania, problemu',
            details: 'Wybrana w ramach doboru charakterystyka urządzenia zostanie automatycznie dodana do przesłanej w ramach niniejszego formularza wiadomości.',
            thanks: 'Bardzo dziękujemy za zapytanie!',
            msgSent: 'Twoja wiadomość została wysłana.',
            msgContent: 'Wiadomość została przekazana bezpośrednio do firmy <span>{partner}</span>. Dane dotyczące Twojego doboru zostały automatycznie dodane do przesłanego zapytania. Odpowiedź od przestawiciela producenta otrzymasz najszybciej jak to będzie możliwe!',
            back: 'Powrót do kalkulatora',
        },
        profileData: {
            title: 'Konto użytkownika',
            intro: 'Abyśmy mogli przygotować dla Państwa konkretną ofertę lub nawiązać kontakt – prosimy o podanie poniższych danych.',
            question: 'Nie chcesz uzupełniać danych teraz?',
            skip: 'Pomiń ten krok',
            button: 'Zapisz i rozpocznij pracę',
        },
        required: 'Pole jest wymagane',
        errorOccured: 'Wystąpił błąd',
        loading: 'Ładowanie',
        infoAboutCompany: 'Dane firmy',
        personalDetails: {
            title: 'Dane osobowe',
            name: 'Imię i nazwisko',
            email: 'Adres e-mail',
            phone: 'Telefon',
            companyName: 'Nazwa firmy',
            taxNumber: 'NIP',
            streetAndNo: 'Ulica i numer',
            postCode: 'Kod pocztowy',
            city: 'Miasto',
            country: 'Kraj',
            incorrectPostCode: 'Niepoprawny kod pocztowy',
        },
        countryList: {
            Austria: 'Austria',
            Belgium: 'Belgia',
            Bulgaria: 'Bułgaria',
            Cyprus: 'Cypr',
            CzechRepublic: 'Czechy',
            Germany: 'Niemcy',
            Denmark: 'Dania',
            Estonia: 'Estonia',
            Greece: 'Grecja',
            Spain: 'Hiszpania',
            Finland: 'Finlandia',
            France: 'Francja',
            Croatia: 'Chorwacja',
            Hungary: 'Węgry',
            Ireland: 'Irlandia',
            Italy: 'Włochy',
            Lithuania: 'Litwa',
            Latvia: 'Łotwa',
            Malta: 'Malta',
            Netherlands: 'Holandia',
            Poland: 'Polska',
            Portugal: 'Portugalia',
            Romania: 'Rumunia',
            Sweden: 'Szwecja',
            Slovenia: 'Słowenia',
            Slovakia: 'Słowacja',
            NorthernIreland: 'Irlandia Północna',
        },
        runoffCoefficientTable: {
            bibliography: 'Wartości współczynnika spływu w zależności od rodzaju i spadku powierzchni według Edel R.: Odwodnienie dróg. WKŁ, Warszawa 2007 (wartości te można także odnaleźć w podręczniku: Kotowski A.: Podstawy bezpiecznego wymiarowania odwodnień terenów, tom I Sieci kanalizacyjne. Wydawnictwo Seidel-Przywecki, Warszawa 2015.)',
            typeOfArea: 'Współczynnik spływu dla spadku powierzchni [%]',
            runoffCoefficient: 'Rodzaj powierzchni / zabudowy',
            roofs: 'Dachy',
            watertightPavements: 'Bruki szczelne',
            ordinaryPavements: 'Bruki zwykłe',
            walkingAlleys: 'Aleje spacerowe',
            parksAndGardens: 'Parki i ogrody',
            farmingLand: 'Grunty rolne',
            forests: 'Lasy',
            denseDevelopment: 'Zabudowa zwarta',
            dispersedDevelopment: 'Zabudowa luźna',
            detachedHouseDevelopment: 'Zabudowa willowa',
        },
        reducedAreaSurfaceOptions: [
            'Dachy',
            'Bruki szczelne',
            'Bruki zwykłe',
            'Aleje spacerowe',
            'Parki i ogrody',
            'Grunty rolne',
            'Lasy',
            'Zabudowa zwarta',
            'Zabudowa luźna',
            'Zabudowa willowa',
        ],
        numberOfResults: 'Liczba wyników',
        supportedCountries: {
            pl: 'Polska',
            in: 'Indie',
            lt: 'Litwa',
        },
    },
    expansionPanel: {
        title: 'Wprowadzone w ramach kroków parametry doboru',
    },
    agreementInTouch: {
        title: 'Bądźmy w kontakcie!',
        intro: 'Abyśmy mogli przygotowywać specjalnie dla Ciebie oferty dobieranych produktów oraz móc informować Cię o nowościach produktowych bardzo prosimy o zgodę na kontakt.',
        mailAgreement: 'Wyrażam zgodę na otrzymywanie na mój adres e-mail od {partner} informacji handlowych np. dotyczących oferowanych produktów i usług, organizowanych szkoleń i innych wydarzeń, a także akcji promocyjnych i marketingowych',
        phoneAgreement: 'Wyrażam zgodę na przekazanie mojego numeru telefonu firmie {partner} w celu kontaktowania się ze mną (telefonicznie i sms-owo) przez {partner} w celu przedstawienia mi jej informacji handlowych, np. dot. jej działalności, oferowanych produktów, organizowanych przez nią szkoleń oraz akcji promocyjnych i marketingowych.',
        info: {
            part1: 'Zmiany powyższych zgód możesz dokonać w dowolnym momencie w zakładce',
            part2: 'Prywatność',
            part3: 'w ustawieniach Konta użytkownika, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.',
        },
        button: 'Zapisz i rozpocznij pracę',
    },
    footer: {
        partnerOfSolution: 'Partner doboru',
    },
    stepper: {
        back: 'Wstecz',
        recalc: 'Przelicz dobór',
        archivalSelection: 'Dobór archiwalny',
        newChoice: 'Nowy dobór',
        calculate: 'Oblicz',
        next: 'Dalej',
    },
    flowCalculation: {
        buttons: {
            calculate: 'Oblicz',
            close: 'Zamknij',
        },
        table: {
            title: 'Powierzchnie zredukowane',
        },
        customErrorMessages: {
            watershedHigherThanCrossSection: 'Niespełniony warunek Wg > Wd',
            catchmentAreaBiggerThanLakesArea: 'Suma pow. zlewni jezior nie może być większa niż pow. zlewni A',
            failedCalculation: 'Nie obliczono przepływu, obliczone wartości wykraczają poza limity',
        },
        success: {
            acceptInfoValid: 'Czy chcesz użyć obliczonej wartości?',
            acceptInfoInvalid: 'Nie można użyć obliczonej wartości, ponieważ nie spełnia wymaganych warunków.',
        },
    },
    linearDrainageSystems: {
        intro: {
            title: 'Dobór odwodnień liniowych',
            desc: 'Wymiarowanie w oparciu o formułę Manninga-Stricklera oraz laboratoryjnie zmierzone wartości współczynników korygujących dla maksymalnej długości odwodnienia 100 m',
        },
        choosingParameters: 'Wybór parametrów',
        table: {
            title: 'Powierzchnie zredukowane',
            clear: 'Wyczyść',
        },
        reducedAreaTable: {
            area: 'Powierzchnia [ha]',
            areaMeters: 'Powierzchnia [m2]',
            areaType: 'Rodzaj powierzchni zabudowy',
            reducedArea: 'Powierzchnia zredukowana F2 [ha]',
            runoffCoefficient: 'Współczynnik spływu',
            addArea: 'Dodaj powierzchnię zredukowaną',
        },
    },
    flushSystems: {
        intro: {
            title: 'Dobór systemów spłukiwania',
            desc: 'zbiorników retencyjnych i kanałów, z wykorzystaniem energii<br> potencjalnej spiętrzonej wody',
        },
        choosingParameters: 'Parametry doboru',
        schemaTitle: 'Poglądowy schemat zbiornika z systemem spłukiwania',
        modalTitle: 'Poglądowy schemat systemu spłukiwania zbiornika{newline}w ramach inwestycji',
        systemControl: 'Sterowanie systemem',
        additionalParameters: 'Parametry dodatkowe',
        legend: {
            firstRow: [
                '<b>H</b> [m] - wysokość zbiornika retencyjnego',
                '<b>L</b> [m] - długość zbiornika retencyjnego',
                '<b>W</b> [m] - szerokość zbiornika retencyjnego',
                '<b>V</b> [m<sup>2</sup>] - objętość zbiornika retencyjnego',
                '<b>H<sub>c1</sub>, H<sub>c2</sub></b> [cm] - wysokość siatki oddzielającej tory spłukiwane',
                '<b>B<sub>ga</sub></b> [mm] - długość wrota komory spiętrzającej',
            ],
            secondRow: [
                '<b>L<sub>T</sub></b> [m] - długość toru spłukiwanego w zbiorniku',
                '<b>B<sub>str</sub></b> [m] - szerokość toru spłukiwanego w zbiorniku',
                '<b>L<sub>c</sub></b> [m] - długość komory spiętrzającej',
                '<b>T<sub>c</sub></b> [m] - wysokość siatki oddzielającej komorę spiętrzającą',
                '<b>V<sub>k</sub></b> [m<sup>3</sup>] - objętość komory spiętrzającej w zbiorniku',
                '<b>V<sub>o</sub></b> [m<sup>3</sup>] - objętość komory odpływowej w zbiorniku',
            ],
        },
        result: {
            title: 'Parametry zbiornika',
            contactInfo: {
                normalPart: '* W przypadku doborów niestandardowych zaleca się',
                bluePart: 'kontakt z firmą Mevapol',
            },
            seriesDetails: {
                title: 'System spłukiwania zbiornika',
                buttonText: 'Zobacz schemat systemu',
            },
            tableSeries: {
                title: 'Zestawienie dobranych systemów spłukiwania',
                head: {
                    lp: 'Lp.',
                    flushTrackLength: 'Długość toru spłukiwanego [${unit}]',
                    storageChamberCapacity: 'Objętość komory spiętrzającej i odpływowej [${unit}]',
                    storageChamberLength: 'Długość komory spiętrzającej [${unit}]',
                    storageChamberHeight: 'Wysokość komory spiętrzającej [${unit}]',
                    chosenItem: '',
                },
            },
        },
        form: {
            privacyPolicyLink: 'https://waterfolder-assets.s3.eu-north-1.amazonaws.com/polityka-prywatnosci/polityka-prywatnosci-meva-pol.pdf',
        },
    },
    sewerRenovation: {
        intro: {
            title: 'Dobór metody renowacji kanałów',
            desc: 'dla przewodów grawitacyjnych o kształcie kołowym lub jajowym, z materiałów takich jak beton, kamionka, cegła, żeliwo czy tworzywo sztuczne, również po renowacji',
        },
        choosingParameters: 'Parametry doboru',
        schemaTitle: 'Poglądowy schemat odcinka rurociągu przeznaczonego do renowacji',
        legend: {
            firstRow: [
                '<b>H<sub>dp</sub></b> [m n.p.m.] - rzędna dna pierwszej studni',
                '<b>L</b> [m] - długość odcinka między studniami',
                '<b>B</b> [mm] - szerokość kanału',
            ],
            secondRow: [
                '<b>H<sub>dk</sub></b> [m n.p.m.] - rzędna dna drugiej studni',
                '<b>H</b> [mm] - wysokość kanału',
            ],
        },
        result: {
            firstSection: 'Parametry kanału przed renowacją',
            secondSection: 'Założenie do korzyści z renowacji',
            contactInfo: '* W celu optymalizacji i precyzyjnego doboru grubości wykładziny zaleca się kontakt z firmą POliner (skorzystaj z formularza zapytania) - należy dokładnie określić głębokość posadowienia kanału, poziom wody gruntowej, obciążenie kanału od gruntu oraz ruchu kołowego. Wyniki dobranych wykładzin rękawowych mają wyłącznie charakter informacyjny i nie stanowią oferty handlowej w rozumieniu art. 66 §1 Kodeksu Cywilnego. Dobór grubości ścianek wykładziny został dokonany w oparciu o średnie wartości modułu Younga.',
            tableSeries: {
                title: 'Zestawienie proponowanych metod renowacji kanału',
                label: 'Tabela dobranych wykładzin rękawowych',
                warnings: {
                    pipesArches: 'Łuki wymagają weryfikacji',
                },
                headerWarnings: {
                    stiffnessConsultation: 'Dla przyjętej klasy sztywności przekrojów jajowych - konieczny kontakt z Producentem',
                    wallThickness: 'Nie można obliczyć grubości ścianki wg kryterium SN',
                },
            },
            seriesDetails: {
                title: 'Wybrane rozwiązanie',
            },
        },
        form: {
            agreement: 'Oświadczam, że zapoznałem się z danymi Administratora danych, którym jest firma Poliner S.A. z siedzibą w Szczecinie oraz przysługującymi mi prawami dot. przetwarzania moich danych osobowych, zawartymi w <a href="{privacyLink}"  target="_blank">Zasady Przetwarzania Danych</a>.',
            privacyPolicyLink: 'https://waterfolder-assets.s3.eu-north-1.amazonaws.com/polityka-prywatnosci/polityka-prywatnosci-poliner.pdf',
        },
        errorMessages: {
            ordinateGreater: 'Hdp musi być większe od Hdk',
            ordinateLower: 'Hdk musi być mniejsze od Hdp',
        },
    },
    communicationCulvert: {
        partnerAddress: 'Amiblu Poland Sp. z o.o. z siedzibą w Dąbrowie Górniczej',
        form: {
            agreement: 'Oświadczam, że zapoznałem się z danymi Administratora danych, którym jest firma Amiblu Poland Sp. z o.o. z siedzibą w Dąbrowie Górniczej oraz przysługującymi mi prawami dot. przetwarzania moich danych osobowych, zawartymi w',
            privacyPolicyLink: 'https://waterfolder-assets.s3.eu-north-1.amazonaws.com/polityka-prywatnosci/polityka-prywatnosci-amiblu.pdf',
        },
        tableSeries: {
            title: 'Zgodne z obliczeniami proponowane rozwiązania',
            desc: '* Wszystkie dobierane zbiorniki rurowe są bezciśnieniowe, z ciśnieniem PN = 1 bar',
        },
        calculationResult: 'Dane wejściowe',
        table: {
            title: 'Powierzchnie zredukowane',
            clear: 'Wyczyść',
        },
        intro: {
            title: 'Dobór przepustów komunikacyjnych',
            desc: 'Dobór hydrauliczny światła przepustów drogowych i kolejowych na podstawie wytycznej ministerialnej WR-M-12',
        },
        choosingParameters: 'Parametry doboru',
        schemaTitle: 'Poglądowy schemat przepustu komunikacyjnego',
        modalTitle: 'Poglądowy schemat systemu spłukiwania zbiornika{newline}w ramach inwestycji',
        systemControl: 'Sterowanie systemem',
        additionalParameters: 'Parametry dodatkowe',
        legendTitle: 'Parametry zbiornika',
        verifyingInfos: {
            selectShape: 'Wybierz kształt przepustu',
            selectDiameter: 'Wybierz średnicę przepustu',
        },
        legend: {
            firstRow: [
                '<b>Q</b> [m<sup>3</sup>/s] - przepływ',
                '<b>L<sub>p</sub></b> [m] - długość przepustu',
                '<b>p</b> [m] - uskok na końcu dna przepustu',
                '<b>i<sub>p</sub></b> [%] - spadek dna przepustu',
                '<b>b</b> [m] - szerokość koryta',
            ],
            secondRow: [
                '<b>h</b> [m] - wysokość drogi ponad dnem przepływu',
                '<b>H<sub>1</sub></b> [m] - wzniesienie zwierciadła wody przed wlotem przepustu',
                '<b>H<sub>2</sub></b> [m] - wzniesienie zwierciadła wody za wylotem przepustu',
                '<b>m</b> - współczynnik nachylenia skarp koryta',
            ],
            multipleInletsDistanceInfo: '<b>d</b> - odległość między otworami przepustów - minimum 600 mm',
        },
        result: {
            title: 'Przepust komunikacyjny',
            response: {
                notsubmerged: 'Niezatopiony',
                submerged: 'Zatopiony',
                circular: 'Kołowy',
                'non-circular': 'Niekołowy',
            },
            contactInfo: {
                firstPart: '* Uwaga! W celu precyzyjnego dobrania sztywności należy przygotować więcej danych i ',
                bluePart: 'skontaktować się z firmą Amiblu',
            },
            seriesDetails: {
                titlePart: 'Zbiornik rurowy',
                subtitle: 'Wymiary',
            },
            slider: {
                title: 'Ustaw maksymalne średnice - zewnętrzną DE</br> i wewnętrzną DI dla listy dobranych zbiorników rurowych',
            },
            tableSeries: {
                additional: {
                    on: 'na',
                    years: 'lat',
                },
                recalculate: { title: 'Dane doboru' },
                title: 'Zgodne z obliczeniami proponowane rozwiązania',
                warnings: {
                    highCoveringTank: 'Duże przykrycie zbiornika! W celu doboru klasy sztywności proszę o kontakt z producentem rur',
                    highSystemLength: 'Duże zagłebienie zbiornika! W celu doboru klasy sztywności proszę o kontakt z producentem rur',
                    buoyancyCondition: 'Sprawdź warunek wyporu!',
                },
                warning: 'Zalecane zastosowanie rur o podwyższonej odporności na ścieranie',
            },
            filters: {
                header: 'Filtruj',
                shapeFilter: {
                    label: 'Kształt przepustów',
                    items: {
                        circular: 'Kołowe',
                        'non-circular': 'Niekołowe',
                        both: 'Kołowe i niekołowe',
                    },
                },
                variantFilter: {
                    label: 'Typ przepustów',
                    items: {
                        submerged: 'Zatopione',
                        notsubmerged: 'Niezatopione',
                        both: 'Zatopione i niezatopione',
                    },
                },
                slider: {
                    header: 'Ustaw maksymalne wartości - wysokość i szerokość zewnętrznego wymiaru DE (OD) oraz wysokość wypełnienia h<sub>n</sub> dla dobranych przepustów',
                    DEheight: 'DE (OD)<br/>wys. [mm]',
                    DEwidth: 'DE (OD)<br/>szer. [mm]',
                    hW: 'h<sub>n</sub><br/>[%]',
                },
            },
        },
        dashboard: {
            title: 'Przepusty komunikacyjne',
            subtitle: '',
            description: '',
        },
        chart: {
            1: {
                title: 'Objętość odpływu wody V<sub>odp</sub> ze zbiornika dla czasu t',
                labelX: 't [min]',
                labelY: 'V<sub>odp</sub>[m<sup>3</sup>]',
                argumentsName: 'Odpływ wody',
                argumentsUnit: 'm<sup>3</sup>',
                valuesName: 'Czas trwania opadu',
                valuesUnit: 'min',
            },
            2: {
                title: 'Objętość dopływu wody V<sub>dop</sub> do zbiornika dla czasu t',
                labelX: 't [min]',
                labelY: 'V<sub>dop</sub> [m<sup>3</sup>]',
                argumentsName: 'Dopływ wody',
                argumentsUnit: 'm<sup>3</sup>',
                valuesName: 'Czas trwania opadu',
                valuesUnit: 'min',
            },
            3: {
                title: 'Objętość odpływu wody V<sub>odp</sub><br/>ze zbiornika dla czasu t',
                labelX: 't [min]',
                labelY: 'V<sub>odp</sub> [m<sup>3</sup>]',
                argumentsName: 'Odpływ wody',
                argumentsUnit: 'm<sup>3</sup>',
                valuesName: 'Czas trwania opadu',
                valuesUnit: 'min',
            },
        },
    },
    mechanicalGrids: {
        intro: {
            title: 'Kalkulator krat mechanicznych',
            desc1: 'dobór krat mechanicznych produkcji <b>PRODEKO-EŁK</b>',
        },
        choosingParameters: 'Wybór parametrów',
        oneGridFlow: 'Przepływ przez jedną kratę [m<sup>3</sup>/h]',
        chargingParameters: 'Parametry zasilania',
        sewageLevelAfterGrid: 'Poziom ścieków za kratą przy określonym przepływie przed kratą [mm]',
        canalSize: 'Wymiary kanału',
        inputData: 'Dane wejściowe',
        additionalRequirements: 'Wymogi dodatkowe',
        isGridShouldContain: 'Czy krata powinna posiadać',
        result: {
            title: 'Wyniki obliczeń',
            parametersTitle: 'Wyniki obliczeń',
            tableSeriesTitle: 'Zgodne z obliczeniami proponowane rozwiązania',
        },
        dashboard: {
            title: 'Kraty mechaniczne',
            subtitle: 'dobór krat mechanicznych produkcji PRODEKO-EŁK',
            description: 'opis',
        },
        form: {
            agreement: 'Oświadczam, że zapoznałem się z danymi Administratora danych, którym jest firma PRODEKO-EŁK Sp. z o.o. z siedzibą w Ełku oraz przysługującymi mi prawami dot. przetwarzania moich danych osobowych, zawartymi w <a href="{privacyLink}"  target="_blank">Polityce Prywatności</a>.',
            privacyPolicyLink: 'https://waterfolder-assets.s3.eu-north-1.amazonaws.com/polityka-prywatnosci/polityka-prywatnosci-esmil.pdf',
        },
        schema: {
            RKE: 'Schemat poglądowy kraty',
        },
        bluebox: {
            grid: 'Krata mechaniczna',
            canalSize: 'Wymiary kanału',
            gridParameters: 'Parametry kraty',
        },
        gridsDescription: {
            RKE: 'Najbardziej powszechne i uniwersalne rozwiązanie dla oczyszczalni ścieków. Ten typ krat nadaje się do większości zadań, gdzie nie ma ekstremalnie grubego gruzu i długich szmat.',
            RP: 'Mechanizm zgarniający znajduje się za prętami filtracyjnymi. Odporny na zaklinowanie i radzi sobie z dużymi ilościami utkanych zanieczyszczeń.',
            KR: 'Może usuwać z kanału duże przedmioty, takie jak opony i drewniane pokłady, a jednocześnie, w przypadku szczeliny mniejszej niż 20 mm, doskonale radzi sobie z dużymi ilościami utkanego gruzu. Krata jest odporna na blokowanie podczas pracy w ujemnych temperaturach (wymaga jednak okresowego czyszczenia z lodu). Nadaje się do ujęcia wody z naturalnych zbiorników wodnych.',
            RSK: 'Krata do filtracji drobnych zanieczyszczeń. Zasada działania kraty schodkowej polega na tworzeniu się na powierzchni roboczej warstwy zanieczyszczeń, wywołującej dodatkowy efekt filtracyjny, który pozwala na zatrzymanie na kracie zanieczyszczeń mniejszych od wielkości szczeliny.',
            RMKE: 'Krata do filtracji drobnych zanieczyszczeń. Pasuje do małych kanałów.',
        },
        customErrorMessages: {
            screenBuildingDepth: 'Wartość nie może być mniejsza niż głębokość kanału',
        },
    },
    openedInfiltrationTanks: {
        intro: {
            title: 'Dobór zbiorników otwartych',
            desc1: 'Wymiarowanie wg wytycznych DWA-A-117 i DWA-A-138',
            desc2: 'dla zlewni skanalizowanej =< 200 ha lub systemów o czasie przepływu =< 30 min',
        },
        choosingParameters: 'Wybór parametrów',
        inputData: 'Dane wejściowe',
        result: {
            title: 'Wyniki obliczeń',
            parametersTitle: 'Wyniki obliczeń',
            tableSeriesTitle: 'Zgodne z obliczeniami proponowane rozwiązania',
            tableSeries: {
                sizeRatioType: {
                    square: 'Kwadratowy',
                    rectangle: 'Prostokątny 2:3',
                    terrain: 'Proporcjonalny do terenu',
                },
            },
            seriesDetails: {
                subtitle: 'Powierzchnie',
                subtitle2: 'Wymiary',
            },
        },
        dashboard: {
            title: 'Zbiorniki otwarte',
            subtitle: 'retencyjne, retencyjno-infiltracyjne{newline}wg DWA-A-117 i DWA-A-138',
            description: 'opis',
        },
        table: {
            title: 'Powierzchnie zredukowane',
            clear: 'Wyczyść',
            firstAdditionalRowName: 'Szacowana powierzchnia zbiornika',
            secondAdditionalRowName: 'Tereny zielone po odjęciu zbiornika',
        },
        form: {
            privacyPolicyLink: 'https://waterfolder-assets.s3.eu-north-1.amazonaws.com/polityka-prywatnosci/polityka-prywatnosci-tormel.pdf',
            agreement: 'Oświadczam, że zapoznałem się z danymi Administratora danych, którym jest firma Tormel Sp. z o.o. z siedzibą w Poznaniu oraz przysługującymi mi prawami dot. przetwarzania moich danych osobowych, zawartymi w <a href="{privacyLink}"  target="_blank">Polityce Prywatności</a>.',
        },
        customErrorMessages: {
            slopeToAreaEdgeDistance: 'Wymagane ogrodzenie, odległość nie może być mniejsza niż 1 m',
            tankDepthCustomRule: 'Dno zbiornika znajduje się bliżej niż 1 metr do poziomu wód gruntowych, infiltracja do gruntu niemożliwa.',
            tankSizeCustomRule: 'Wartość zbyt mała dla zadanej głębokości i nachylenia skarp',
        },
        infiltrationCoefficient: {
            cleanGravels: 'Żwiry czyste',
            sandyGravels: 'Żwiry piaszczyste',
            coarseGravelSands: 'Piaski gruboziarniste żwirowate',
            coarseSands: 'Piaski gruboziarniste',
            mediumGrainedSands: 'Piaski średnioziarniste',
            fineGrainedSands: 'Piaski drobnoziarniste',
            fineGrainedSandsWithClayOrDust: 'Piaski drobnoziarniste zaglinione lub z pyłem',
            claySands: 'Piaski gliniaste',
            typeOfArea: 'Rodzaj gruntów',
            infiltrationCoefficient: 'Współczynnik infiltracji [m/s]',
            extraInfo: 'Orientacyjne wartości współczynnika filtracji wybranych gruntów naturalnych. <p>Dec T. 1975. Mechanika gruntów. Właściwości fizyczne. WAT. Warszawa Mielcarzewicz E. 1971.</p><p> Melioracje terenów miejskich i przemysłowych. Arkady. Warszawa</p>',
        },
        openTank: {
            title: 'Schemat poglądowy zbiornika otwartego',
        },
        tankType: {
            label: 'Typ zbiornika',
            value: 'Retencyjny',
        },
        variant: {
            retention: 'Zbiornik retencyjny',
            infiltrative: 'Zbiornik infiltracyjny',
        },
        chart: {
            1: {
                title: 'Minimalna objętość zbiornika V <br/>dla różnych czasów trwania deszczu t',
                labelX: 't [min]',
                labelY: 'V [m<sup>3</sup>]',
                argumentsName: 'Min objętość zbiornika',
                argumentsUnit: 'm<sup>3</sup>',
                valuesName: 'Czas trwania opadu',
                valuesUnit: 'min',
            },
            2: {
                title: 'Objętość dopływu wody V<sub>dop</sub> do zbiornika dla czasu t',
                labelX: 't [min]',
                labelY: 'V<sub>dop</sub> [m<sup>3</sup>]',
                argumentsName: 'Dopływ wody',
                argumentsUnit: 'm<sup>3</sup>',
                valuesName: 'Czas trwania opadu',
                valuesUnit: 'min',
            },
            3: {
                title: 'Objętość odpływu wody V<sub>odp</sub><br/>ze zbiornika dla czasu t',
                labelX: 't [min]',
                labelY: 'V<sub>odp</sub> [m<sup>3</sup>]',
                argumentsName: 'Odpływ wody',
                argumentsUnit: 'm<sup>3</sup>',
                valuesName: 'Czas trwania opadu',
                valuesUnit: 'min',
            },
        },
        investor: {
            company: 'Podmiot gospodarczy',
            individual: 'Klient Indywidualny',
        },
    },
    investmentData: {
        title: 'Dane inwestycji',
        description: 'Dane, o które prosimy poniżej umożliwią Ci prowadzenie listy generowanych obliczeń w naszej aplikacji jak również znajdą się na wygenerowanym w ostatnim kroku pliku PDF. Wygenerowany plik może później stanowić załącznik do dokumentacji projektowej.',
    },
};

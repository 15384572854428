import { isDefined } from '@/interfaces/shared';
export const useConfigFrontDependencies = (store) => {
    /**
     * It sets dependencies by excel json config (visibility, disability, readonly, value and options in select)
     * @param inputToSet key of the input parameter to set
     * @param inputChanged key of the input parameter which changed value
     * @param source
     * @param dontChangeValue set true, if you want to change everything except changing value of dependent input, e.g. for recalculation purpose
     */
    const setDependentInputs = (inputToSet, inputChanged, source, dontChangeValue = false) => {
        let isVisibilityChanged = false;
        let isDisabilityChanged = false;
        let isReadonlyChanged = false;
        let isItemsChanged = false;
        let inputChangeHasVisibility = false;
        //TODO: handle array of objects
        store.calculatorInitConfig.configFrontDependencies[inputToSet].map((element) => {
            let isAnyValueInvalid = false;
            if (Object.entries(element.changed).find(el => el[0] === inputChanged)) {
                Object.entries(element.changed).forEach(([key, requiredValue]) => {
                    if (store.calculationParameters.payload[key] === null) {
                        isAnyValueInvalid = true;
                        return;
                    }
                    let valueFromStore;
                    if (source) {
                        valueFromStore =
                            typeof source[key] === 'object'
                                ? source[key].value
                                : source[key];
                    }
                    else {
                        valueFromStore =
                            typeof store.calculationParameters.payload[key] === 'object'
                                ? store.calculationParameters.payload[key].value
                                : store.calculationParameters.payload[key];
                    }
                    if (valueFromStore !== requiredValue) {
                        isAnyValueInvalid = true;
                    }
                });
                if (element.set?.visibility !== undefined) {
                    inputChangeHasVisibility = true;
                }
                if (!isAnyValueInvalid) {
                    if (element.set.value !== undefined && !dontChangeValue) {
                        store.calculatorInitConfig.configFront.payload[inputToSet]
                            .defaultUnit
                            ? store.mutateCalculationParameters({
                                key: inputToSet,
                                value: {
                                    value: element.set.value,
                                    unit: store.calculatorInitConfig.configFront.payload[inputToSet].defaultUnit,
                                },
                            })
                            : store.mutateCalculationParameters({
                                key: inputToSet,
                                value: element.set.value,
                            });
                    }
                    if (element.set.itemsValues?.length &&
                        element.set.itemsValues?.length > 0) {
                        changeParameterItems(inputToSet, element.set.itemsValues);
                        isItemsChanged = true;
                    }
                    if (element.set.visibility !== undefined) {
                        changeParameterVisibility(inputToSet, element.set.visibility);
                        setValueByParamVisibility(inputToSet, element.set.visibility);
                        isVisibilityChanged = true;
                    }
                    if (element.set.disabled !== undefined) {
                        changeParameterDisability(inputToSet, element.set.disabled, source);
                        isDisabilityChanged = true;
                    }
                    if (element.set.readonly !== undefined) {
                        store.mutateParameterReadonly({
                            key: inputToSet,
                            value: element.set.readonly,
                            source,
                        });
                        isReadonlyChanged = true;
                    }
                }
            }
        });
        if (inputChangeHasVisibility && !isVisibilityChanged) {
            const initialVisibility = store.calculatorInitConfig.configFront.payload[inputToSet].visible;
            changeParameterVisibility(inputToSet, initialVisibility);
            setValueByParamVisibility(inputToSet, initialVisibility);
        }
        if (!isDisabilityChanged) {
            const initialDisability = store.calculatorInitConfig.configFront.payload[inputToSet].disable;
            changeParameterDisability(inputToSet, initialDisability, source);
        }
        if (!isReadonlyChanged) {
            const initialReadonly = store.calculatorInitConfig.configFront.payload[inputToSet].readonly;
            changeParameterReadonly(inputToSet, initialReadonly, source);
        }
        if (!isItemsChanged) {
            const initialItems = store.calculatorInitConfig.configFront.payload[inputToSet].items;
            store.calculatorCurrentConfigFrontPayload[inputToSet].items =
                initialItems;
        }
    };
    const setInitialDependencies = async () => {
        await Object.entries(store.calculatorInitConfig.configFrontDependencies).forEach(setItem => {
            setItem[1].forEach((changedItem) => {
                setDependentInputs(setItem[0], Object.entries(changedItem.changed)[0][0], undefined, true);
            });
        });
    };
    /**
     * Function change value to default or null when input isn't visible
     * @param inputToSet key of the input to set
     * @param visible input visibility
     * @returns
     */
    const setValueByParamVisibility = (inputToSet, visible) => {
        const defaultValue = store.calculatorInitConfig.configFront.payload[inputToSet].defaultValue;
        if (visible) {
            return;
        }
        if (isDefined(defaultValue)) {
            store.mutateCalculationParameters({
                key: inputToSet,
                value: defaultValue,
            });
        }
        else {
            store.mutateCalculationParameters({
                key: inputToSet,
                value: null,
            });
        }
    };
    const changeParameterVisibility = (key, value) => {
        store.mutateParameterVisibility({
            key: key,
            value: value,
        });
    };
    const changeParameterItems = (key, itemsToFilter) => {
        store.mutateParameterItems({
            key: key,
            newItemsValues: itemsToFilter,
        });
    };
    const changeParameterDisability = (key, value, source) => {
        store.mutateParameterDisability({
            key: key,
            value: value,
            source,
        });
    };
    const changeParameterReadonly = (key, value, source) => {
        store.mutateParameterReadonly({
            key: key,
            value: value,
            source,
        });
    };
    return {
        setDependentInputs,
        setInitialDependencies,
    };
};

import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/router/routes';
import { useMainStore } from '@/stores/main';
import { Cookies } from '@/shared/cookies';
import { api } from '@/axios/axios';
const createHistory = createWebHistory;
export const router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,
    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
});
router.onError(error => {
    console.log(error.message);
    if (/Loading (.)* failed./i.test(error.message)) {
        alert('Właśnie zaktualizowaliśmy naszą aplikację! Prosimy o odświeżenie strony');
    }
    // TODO: add other languages
});
router.beforeEach((to, _from) => {
    const mainStore = useMainStore();
    const languageFromUrl = ['en', 'lt', 'pl'].includes(to.params.lang)
        ? to.params.lang
        : '';
    let language = '';
    // Check for language in URL.
    if (languageFromUrl) {
        language = languageFromUrl;
    }
    // Check for language in cookies (if there was no language in URL).
    if (!languageFromUrl && Cookies.getCookie('lang-choice')) {
        language = Cookies.getCookie('lang-choice');
    }
    // If there was no country in cookies, take one basing on language.
    if (!Cookies.getCookie('country-choice')) {
        if (language) {
            mainStore.setCountry(language.toUpperCase());
        }
        else {
            // There was no language set, take country from IP.
            mainStore.fetchSettings();
        }
    }
    else {
        // Update Custom header country
        mainStore.setCountry(Cookies.getCookie('country-choice'));
    }
    if (language === '') {
        if (navigator.language === 'pl-PL' ||
            navigator.language === 'pl' ||
            navigator.language === '') {
            language = 'pl';
        }
        else {
            language = 'en';
        }
    }
    mainStore.setLang(language);
    let token = '';
    const tokenFromUrl = to.query.token?.toString();
    if (tokenFromUrl) {
        mainStore.isEmbedded = true;
    }
    if (Cookies.getCookie('adminFromWF2')) {
        mainStore.isAdminFromWF2 = true;
    }
    if (process.env.VUE_APP_MODE === 'dev-single-vue3') {
        token =
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjY5MWUzZjYwZjZkMjgwZjNhYTk5M2Y1ZmY4MjlkMmYxNDIwODkzMTUwMGY2YjU5ZmU5MDNhZTc2YzkzM2ViMTYzNTk5ZWNjNzVlMjg2OGJlIn0.eyJhdWQiOiIzIiwianRpIjoiNjkxZTNmNjBmNmQyODBmM2FhOTkzZjVmZjgyOWQyZjE0MjA4OTMxNTAwZjZiNTlmZTkwM2FlNzZjOTMzZWIxNjM1OTllY2M3NWUyODY4YmUiLCJpYXQiOjE2NjI5NzQzNTIsIm5iZiI6MTY2Mjk3NDM1MiwiZXhwIjoxNjk0NTEwMzUyLCJzdWIiOiIxNTI2Iiwic2NvcGVzIjpbXX0.tIXPOqwSM62WZGqIXqd_bPNqtdZhuAoXfG6nloPzGl54vknVhyqLSRRPJNf50K2PG4Pt_N0APYxybtFLukliAOnlZXk_s-m8pMHvIq5Dy2GMVQJSowcCubpfa3aFJCPn16Hi_ZPqzGzthSNpI1XSw83uj2tq8h-Qf8QzaVWDjJvenyKz1Bl24DrkgsTmujkn-qaIuqRzwbqABkNjXYvDScEb-myQf2qUldyv4S6HOYAqnMf7taWeoq8-NMbtSDm7stO0YbHblKxj-b9vQ7JhC-MgIhUOM0CDudA7L_-rv_aTcilFHKxIE0HjdIjn1BXtcrLx5E4_Rn0Ltmg2mxGKjTkfqqxumoO9LlnM0vZW21VjHEe4a0TrdNQCpyQvTfKtvfWxcMdUFKhfeP3PYtBiEO2JYcw4B8uErTeCTqsV1UQ4CDKSpRbkYhzUvm6-Rv5t48x8hAbFLnaDwfgsyyc0wWamxnrGk7KdWrWIvW9VDO30aiQOhFFLZi1ubq3HvyRtzx5FPYJ8YUq0zLUQaA8IJs4Us_OBrpco5Py3a45Zbrx1cGnAZrnp8saPTet9QCe5N-ay4Ul3zQa0lJUkpfvvlZpc7IXge2UAqALStaBJesn9hR4A2Oi8CQX3bs-fkJS_zuXgGJdievLKQwo0ZdpwJjhKfFvNj2gYE4EIeYgIE0U';
    }
    else {
        token = tokenFromUrl ?? Cookies.getCookie('rainroomapitoken');
    }
    const path = to.path.split('/')[languageFromUrl ? 2 : 1];
    if (path === 'app' && !token) {
        const entryUrl = to.fullPath;
        if (entryUrl === '/app') {
            router.push({ name: 'login', params: { lang: language } });
        }
        else {
            router.push({
                name: 'login',
                query: { entryUrl },
                params: { lang: language },
            });
        }
    }
    else {
        api.defaults.headers.common.Authorization = 'Bearer ' + token;
        if (!languageFromUrl) {
            return '/' + language + to.fullPath;
        }
    }
});

export * from './shared/calculators';
export * from './shared/storeModels';
export * from './shared/utilityTypes';
export * from './shared/componentVariants';
export * from './shared/slider';
export var ComponentName;
(function (ComponentName) {
    ComponentName["Input"] = "input";
    ComponentName["Select"] = "select";
    ComponentName["Checkbox"] = "checkbox";
    ComponentName["Textarea"] = "textarea";
    ComponentName["Textautocomplete"] = "textautocomplete";
    ComponentName["InputSlider"] = "input/slider";
    ComponentName["MultiSelect"] = "multiselect";
})(ComponentName || (ComponentName = {}));
export var FieldSpecialType;
(function (FieldSpecialType) {
    FieldSpecialType["ObjectArray"] = "objectarray";
    FieldSpecialType["Object"] = "object";
})(FieldSpecialType || (FieldSpecialType = {}));
export var InputSize;
(function (InputSize) {
    InputSize["Small"] = "33%";
    InputSize["Medium"] = "50%";
    InputSize["Large"] = "75%";
    InputSize["VeryLarge"] = "100%";
})(InputSize || (InputSize = {}));
export const isValueWithUnit = (value) => {
    return (typeof value === 'object' &&
        value !== null &&
        'value' in value &&
        'unit' in value);
};
export const isConfigObject = (tested) => {
    if (tested.items) {
        return !Array.isArray(tested.items);
    }
    return false;
};
export const isConfigSelectArray = (tested) => {
    if (tested.items) {
        const isArray = Array.isArray(tested.items);
        const isSelectOption = tested.items?.[0]
            ? 'value' in tested.items[0] && 'label' in tested.items[0]
            : true;
        return isArray && isSelectOption;
    }
    return false;
};

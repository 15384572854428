import { isValueWithUnit } from '@/interfaces/shared';
import { addSectionIdToPath, getSectionIdFromPath, removeSectionIdFromPath, } from '@/shared/utils';
export const useConfigFrontDependencies = (store) => {
    /**
     * It sets dependencies by excel json config (visibility, disability, value and options in select)
     * If you want these changes in section scope, inputChanged have to has section number included, but inputSet can't have to
     * @param inputToSet key of the input parameter to set
     * @param inputChanged key of the input parameter which changed value
     * @param source
     */
    const setDependentInputs = (inputToSet, inputChanged, source) => {
        const inputChangedWithoutSection = removeSectionIdFromPath(inputChanged);
        const inputChangedSectionId = getSectionIdFromPath(inputChanged);
        const inputToSetWithSection = inputChangedSectionId
            ? addSectionIdToPath(inputToSet, inputChangedSectionId)
            : inputToSet;
        let isVisibilityChanged = false;
        let isDisabilityChanged = false;
        let isItemsChanged = false;
        let inputChangeHasVisibility = false;
        const fieldInitConfig = store.getFieldInitConfig(inputToSet);
        if (!fieldInitConfig) {
            console.error('Field config not found: ', inputToSet);
            return;
        }
        //TODO: handle array of objects
        const configDependencies = store.calculatorInitConfig.configFrontDependencies[inputToSet];
        configDependencies.map((element) => {
            let isAnyValueInvalid = false;
            const changedEntries = Object.entries(element.changed);
            if (store.calculatorId === 13 ||
                changedEntries.find(el => el[0] === inputChangedWithoutSection)) {
                changedEntries.forEach(([key, requiredValue]) => {
                    key = inputChangedSectionId
                        ? addSectionIdToPath(key, inputChangedSectionId)
                        : key;
                    let valueFromStore;
                    if (source) {
                        const sourceValue = source[key];
                        valueFromStore = isValueWithUnit(sourceValue)
                            ? sourceValue.value
                            : sourceValue;
                    }
                    else {
                        const calculationParameter = store.getCalculationValue(key);
                        valueFromStore = isValueWithUnit(calculationParameter)
                            ? calculationParameter.value
                            : calculationParameter;
                    }
                    if (valueFromStore !== requiredValue) {
                        isAnyValueInvalid = true;
                    }
                });
                if (element.set?.visibility !== undefined) {
                    inputChangeHasVisibility = true;
                }
                if (!isAnyValueInvalid) {
                    if (element.set.value !== undefined) {
                        updateFieldValue(fieldInitConfig, element.set.value, inputToSetWithSection);
                    }
                    if (Array.isArray(element.set.itemsValues)
                    // element.set.itemsValues?.length
                    //  &&
                    // element.set.itemsValues?.length > 0
                    ) {
                        store.mutateParameterItems({
                            key: inputToSetWithSection,
                            newItemsValues: element.set.itemsValues,
                        });
                        isItemsChanged = true;
                    }
                    if (element.set.visibility !== undefined) {
                        store.mutateParameterVisibility({
                            key: inputToSetWithSection,
                            value: element.set.visibility,
                        });
                        setValueByParamVisibility(inputToSetWithSection);
                        isVisibilityChanged = true;
                    }
                    if (element.set.disabled !== undefined) {
                        store.mutateParameterDisability({
                            key: inputToSetWithSection,
                            value: element.set.disabled,
                            source,
                        });
                        isDisabilityChanged = true;
                    }
                }
            }
        });
        if (inputChangeHasVisibility && !isVisibilityChanged) {
            store.resetParameterVisibility(inputToSetWithSection);
            setValueByParamVisibility(inputToSetWithSection);
        }
        if (!isDisabilityChanged) {
            store.resetParameterDisability(inputToSetWithSection);
        }
        if (!isItemsChanged) {
            store.resetParameterItems(inputToSetWithSection);
        }
    };
    const setInitialDependencies = async () => {
        await Object.entries(store.calculatorInitConfig.configFrontDependencies).forEach(setItem => {
            setItem[1].forEach((changedItem) => {
                setDependentInputs(setItem[0], Object.entries(changedItem.changed)[0][0]);
            });
        });
    };
    /**
     * Function change value to default or null when input isn't visible
     * @param inputToSet key of the input to set
     * @returns
     */
    const setValueByParamVisibility = (inputToSetWithSection) => {
        const fieldConfig = store.getFieldConfig(inputToSetWithSection);
        const visible = fieldConfig.visible;
        if (visible) {
            return;
        }
        store.resetParameterValue(inputToSetWithSection);
    };
    const updateFieldValue = (fieldConfig, value, inputToSet) => {
        const defaultUnit = fieldConfig.defaultUnit;
        const newValue = defaultUnit ? { value, unit: defaultUnit } : value;
        store.mutateCalculationParameters({ key: inputToSet, value: newValue });
    };
    return {
        setDependentInputs,
        setInitialDependencies,
    };
};

import { camelToKebab, interpolate, metersToHtml } from '@/shared/utils';
import { useMainStore } from '@/stores/main';
import { getDefaultChartBase64 } from '@30/constant';
import { getCommonStore, getStoreForNewSolutionsStructure, } from '@30/stores/calculatorCommon';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
const { actions, getters, persist } = getCommonStore();
const { getters: newStructureGetters } = getStoreForNewSolutionsStructure();
export const useOpenedInfiltrationTanksStore = defineStore('opened-infiltration-tanks', {
    state: () => ({
        VERSION: '1.0',
        isValid: [false, false, false],
        calculatorInitConfig: {},
        calculatorCurrentConfigFrontPayload: {},
        calculationParameters: {
            meta: {},
            investData: {},
            payload: {},
        },
        calculationResult: {
            favorite: false,
        },
        status: {
            error: {
                value: false,
                message: '',
            },
            success: false,
            pending: false,
        },
        form: null,
        showModal: false,
        nameStore: 'openedInfiltrationTanks',
        calculatorId: 30,
        isInitialized: false,
        areAdditionalRowsAdded: false,
        chartData: { flowChart: null },
        chartBase64: getDefaultChartBase64(),
        validBase64: false,
        waterDischargeRangeDefaultRule: null,
        schema: null,
    }),
    persist,
    getters: {
        ...getters,
        ...newStructureGetters,
        getResultSolutionsHead: (state) => {
            const head = cloneDeep(state.calculationResult?.resultsView?.solutionsHead);
            if (!head)
                return;
            for (const key of Object.keys(head)) {
                let unit = state.calculationResult.payloadOut.data.selection[0].solutions[0]
                    .openTank.calculated[key]
                    ?.unit;
                unit = unit ? metersToHtml(unit) : unit;
                head[key].label = interpolate(head[key].label, { unit });
            }
            return head;
        },
        getNameStoreKebab: state => {
            return camelToKebab(state.nameStore);
        },
        hasAllBase64: state => Object.values(state.chartBase64).every(base64Value => base64Value),
    },
    actions: {
        ...actions,
        mutateReducedArea(payload) {
            this.calculationParameters.payload.reducedAreaTable[payload.rowIndex][payload.key] = payload.value;
        },
        async fetchCalculationResult() {
            const parameters = cloneDeep(this.calculationParameters);
            await this.postCalculation(parameters);
            this.chartBase64 = getDefaultChartBase64();
            this.addChartToParameters();
        },
        fetchHistoricalCalculationActionsAfter() {
            this.addChartToParameters();
        },
        addChartToParameters() {
            if (this.getSingleSelectionResult(0)) {
                this.getSingleSelectionResult(0).minSystemVolume.chart = 1;
                this.getSingleSelectionResult(0).systemInflow.chart = 2;
                this.getSingleSelectionResult(0).systemOutflow.chart = 3;
            }
        },
        setCalculatorConfigActionsAfter() {
            this.calculationParameters.payload.reducedAreaTable[0] = {
                area: null,
                areaMeter: null,
                areaType: '',
                reducedArea: 0,
                runoffCoefficient: null,
            };
            this.waterDischargeRangeDefaultRule = cloneDeep(this.calculatorInitConfig?.configFrontRules?.waterDischarge.range);
        },
        setChartData(payload) {
            this.chartData[payload.key] = payload.val;
        },
        setChartBase64(base64) {
            this.chartBase64[base64.type] = base64.base64;
            this.validBase64 = true;
        },
        async fetchChartData(payload) {
            const mainStore = useMainStore();
            try {
                const response = await mainStore.fetchChartData(this.calculatorId, payload, this.setStatus);
                this.setChartData({ key: 'flowChart', val: response });
                return response;
            }
            catch (error) {
                throw new Error(error?.message);
            }
        },
        setSchema(schema) {
            this.schema = schema;
        },
    },
});

import InfiltrationTankSchema from '@30/components/tankSchema/schemas/InfiltrationTankSchema.vue';
import OpenedTankSchema from '@30/components/tankSchema/schemas/OpenedTankSchema.vue';
import { EnvironmentalCondition } from '@30/interfaces/openedInfiltrationTanks';
const prefix = 'openedInfiltrationTanks.infiltrationCoefficient';
export const infiltrationCoefTableHeader = [
    `${prefix}.typeOfArea`,
    `${prefix}.infiltrationCoefficient`,
];
export const groundTypesList = [
    [
        [`${prefix}.cleanGravels`, '(2.32-1.16) · 10<sup>-3</sup>', 0.00116],
        [`${prefix}.sandyGravels`, '(1.74-0.87) · 10<sup>-3</sup>', 0.00087],
        [`${prefix}.coarseGravelSands`, '(1.16-0.58) · 10<sup>-3</sup>', 0.00058],
        [`${prefix}.coarseSands`, '(0.87-0.29) · 10<sup>-3</sup>', 0.00029],
        [`${prefix}.mediumGrainedSands`, '(0.29-0.12) · 10<sup>-3</sup>', 0.00012],
        [`${prefix}.fineGrainedSands`, '(0.12-0.023) · 10<sup>-4</sup>', 0.000023],
        [
            `${prefix}.fineGrainedSandsWithClayOrDust`,
            '(0.23-0.12) · 10<sup>-4</sup>',
            0.000012,
        ],
        [`${prefix}.claySands`, '(8.1-2.3) · 10<sup>-6</sup>', 0.0000023],
    ],
];
export const tankDataToSchema = [
    {
        value: EnvironmentalCondition.PermeableSoil,
        component: InfiltrationTankSchema,
    },
    {
        value: EnvironmentalCondition.ImpermeableSoil,
        component: OpenedTankSchema,
    },
    {
        value: EnvironmentalCondition.NoGroundDrainage,
        component: OpenedTankSchema,
    },
];
export const RESULT_ROUTER_PATH = 'wybor-zbiornika';
export const CHART_IDS = [1, 2, 3];
export const getDefaultChartBase64 = () => ({
    volumeChart: '',
    inflowChart: '',
    outflowChart: '',
});

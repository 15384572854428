const accountRouter = {
    path: 'konto',
    name: 'account',
    redirect: { name: 'history' },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: 'historia',
            name: "history",
            component: () => import('@/pages/EmptyPage.vue'),
        },
        {
            path: 'ustawienia',
            name: "account-data",
            redirect: { name: 'account-profile-data' },
            component: () => import('@/pages/EmptyPage.vue'),
            children: [
                {
                    path: 'dane-profilowe',
                    name: "account-profile-data",
                    component: () => import('@/pages/EmptyPage.vue'),
                },
                {
                    path: 'zmiana-hasla',
                    name: "change-password",
                    component: () => import('@/pages/EmptyPage.vue'),
                },
                {
                    path: 'prywatnosc',
                    name: "agreements",
                    component: () => import('@/pages/EmptyPage.vue'),
                },
            ]
        },
        {
            path: 'ankieta',
            name: 'questionnaire',
            component: () => import('@/pages/EmptyPage.vue'),
        },
    ],
};
export default accountRouter;

import { useFlushSystemsStore } from '@/stores/calculators/23-flush-systems';
const flushSystemsRouter = {
    path: 'systemy-splukiwania',
    name: 'flush-systems',
    redirect: { name: 'flush-systems.investment-data' },
    component: () => import('@/pages/FlushSystems/FlushSystems.vue'),
    beforeEnter: async (_to, _from, next) => {
        try {
            const flushSystemsStore = useFlushSystemsStore();
            let storeFromLocalStorage = null;
            const flushSystemsStorage = localStorage.getItem(flushSystemsStore.nameStore);
            if (flushSystemsStorage) {
                storeFromLocalStorage = JSON.parse(flushSystemsStorage);
            }
            if (storeFromLocalStorage && flushSystemsStore.VERSION === storeFromLocalStorage.VERSION) {
                flushSystemsStore.calculationParameters = storeFromLocalStorage.calculationParameters;
                flushSystemsStore.calculationResult = storeFromLocalStorage.calculationResult;
            }
            await flushSystemsStore.initModule();
            next();
        }
        catch {
            next({
                name: 'dashboard', // back to safety route //
            });
        }
    },
    children: [
        {
            path: 'dane-inwestycji',
            name: 'flush-systems.investment-data',
            components: {
                left: () => import('@/components/main/calculators/23_flushSystems/InvestmentData.vue'),
                right: () => import('@/components/main/calculators/23_flushSystems/InvestmentMap.vue'),
            },
        },
        {
            path: 'wybor-parametrow',
            name: 'flush-systems.choosing-parameters',
            components: {
                left: () => import('@/components/main/calculators/23_flushSystems/ChoosingParametersForm.vue'),
                right: () => import('@/components/main/calculators/23_flushSystems/ParametersSchema.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                const flushSystemsStore = useFlushSystemsStore();
                if (flushSystemsStore.isValid[0]) {
                    next();
                }
                else {
                    next({ name: 'flush-systems.investment-data' });
                }
            },
        },
        {
            path: 'dodatkowe-parametry',
            name: 'flush-systems.additional-parameters',
            components: {
                left: () => import('@/components/main/calculators/23_flushSystems/AdditionalParameters.vue'),
                right: () => import('@/components/main/calculators/23_flushSystems/ParametersSchema.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                const flushSystemsStore = useFlushSystemsStore();
                if (flushSystemsStore.isValid[1]) {
                    next();
                }
                else {
                    next({ name: 'flush-systems.investment-data' });
                }
            },
        },
        {
            path: 'wynik',
            name: 'flush-systems.result',
            components: {
                left: () => import('@/components/main/calculators/23_flushSystems/ResultDetails.vue'),
                right: () => import('@/components/main/calculators/23_flushSystems/ResultTable.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                const flushSystemsStore = useFlushSystemsStore();
                if (flushSystemsStore.isValid[2]) {
                    next();
                }
                else {
                    next({ name: 'flush-systems.investment-data' });
                }
            },
        },
        {
            path: 'dobor-archiwalny/:calculationKey',
            name: 'flush-systems.archive',
            components: {
                left: () => import('@/components/main/calculators/23_flushSystems/ResultDetails.vue'),
                right: () => import('@/components/main/calculators/23_flushSystems/ResultCalculationData.vue'),
            },
            beforeEnter: async (to, _from, next) => {
                const flushSystemsStore = useFlushSystemsStore();
                try {
                    await flushSystemsStore.fetchHistoricalCalculation(to.params.calculationKey);
                    next();
                }
                catch {
                    next({
                        name: 'dashboard', // back to safety route //
                    });
                }
            },
        }
    ],
};
export default flushSystemsRouter;

import { useBreakpoints } from '@vueuse/core';
const breakpoints = useBreakpoints({
    mobileXS: 450,
    mobile: 600,
    tabletS: 700,
    tablet850: 850,
    tablet: 1024,
    laptop: 1280,
    desktop: 1400,
    desktopLarge: 1600,
});
export default breakpoints;

export default {
    shared: {
        yes: 'Yes',
        no: 'No',
        signOut: 'Atsijunkite',
        newChoice: 'Naujas parinkimas',
        yourChoices: 'Jūsų parinkimai',
        settings: 'Nustatymai',
        administration: 'Administration',
        platform: 'PLATFORMA',
        termsAndConditions: 'Reglamentas',
        letsTalk: 'PASIKALBĖKIME',
        privacyPolicy: 'Privatumo politika',
        phoneNumber: '+ 48 730 037 309',
        companyName: 'RetencjaPL Sp z o.o.',
        total: 'Sum',
        calculationResults: 'Calculation results',
        showAll: 'Pokaż pozostałe',
        chosenProduct: 'Wybrany',
        productsNotFound: 'Nie znaleziono produktów spełniających kryteria',
        addToFavorites: 'Dodaj do ulubionych',
        addedToFavorites: 'Dodano do ulubionych',
        calculationInProgress: 'Vyksta skaičiavimas',
        back: 'Atgal',
        needHelp: 'Reikalinga pagalba',
        selectionAttemptFailed: 'Parinkimo bandymas nepavyko',
        selectionAttemptFailedMessage: 'Nepavyko parinkti prietaisų pagal pateiktus parametrus. Pabandykite naudoti kitus įėjimo duomenis arba susisiekite su mumis dėl pagalbos',
        dataHasBeenChanged: 'Duomenys pakeisti',
        updateData: 'Atnaujinti duomenis',
        files: {
            downloadFiles: 'Atsisiųsti failus',
            sendFiles: 'Siųsti laišką',
            pdf: 'PDF failas',
            zip: 'ZIP failas',
            xls: 'XLS failas',
            xlsx: 'xlsx',
        },
        agreementModal: {
            title: 'Siųskite failus į mano el. paštą',
            content: 'Siųskite mano elektroninio pašto adresu išsamius skaičiavimų rezultatus ir pradinį „{partner}“ produkto pasiūlymą. <br><br> Asmens duomenis „{partner}“ tvarkys vadovaudamasi ',
            privacyPolicy: 'duomenų tvarkymo principais',
            button1: 'Siųsti failus',
            button2: 'Siųsti laišką',
            mailAgreement: 'Sutinku gauti š „{partner}“ į mano elektroninio pašto adresą komercinę informaciją, pvz., apie siūlomus produktus ir paslaugas, mokymo kursus ir kitus renginius, taip pat reklamines ir rinkodaros kampanijas',
            info: {
                part1: 'Anksčiau pateiktus sutikimus galite bet kada pakeisti Vartotojo paskyros nustatymų skirtuke',
                part2: 'Privatumas, ',
                part3: 'nepažeisdami sutikimo pagrindu atlikto duomenų teisėtumo prieš jo atšaukimą.\n'
            },
        },
        errorMessages: {
            required: 'Pole nie może być puste.',
            min: 'Wartość musi być wyższa niż {min}.',
            max: 'Wartość musi być niższa od {max}.',
            range: 'Wartość powinna być z zakresu {range}.',
        },
        sendQuestion: 'Siųsti užklausą',
        contactForm: {
            title: 'Užduoti klausimą dėl parinkimo',
            desc: 'Kad galėtume atsakyti į visus jūsų klausimus, padėti parinkti nestandartinius įrenginius, kviečiame susisiekti su gamintoju tiesiogiai - su „{partner}“ įmone, naudojant žemiau esančią kontaktinę formą.',
            subject: 'Žinutės tema',
            message: 'Žinutės turinys',
            messagePlaceholder: 'Prašome pateikti išsamų savo užklausos ar problemos aprašymą',
            details: 'Parinkimo metu pasirinktos įrenginio charakteristikos bus automatiškai pridėtos prie šios formos pranešimo.',
            thanks: 'Labai ačiū už jūsų užklausą!',
            msgSent: 'Jūsų žinutė buvo išsiųsta.',
            msgContent: 'Pranešimas buvo perduotas tiesiogiai <span>{partner}</span>. Jūsų pasirinkimo duomenys buvo automatiškai pridėti prie pateiktos užklausos. Kuo greičiau gausite gamintojo atstovo atsakymą!',
            back: 'Grįžkite į skaičiuoklę',
        },
        profileData: {
            title: 'Vartotojo paskyra',
            intro: 'Kad galėtume jums parengti konkretų pasiūlymą arba susisiekti - pateikite šiuos duomenis.',
            question: 'Nenorite dabar pildyti savo duomenų?',
            skip: 'Praleiskite šį žingsnį',
            button: 'Išsaugokite ir pradėkite dirbti',
        },
        required: 'Grįžkite į skaičiuoklę',
        errorOccured: 'Wystąpił błąd',
        loading: 'Loading',
        infoAboutCompany: 'Įmonės rekvizitai',
        personalDetails: {
            title: 'Asmens duomenys',
            name: 'Vardas ir pavardė',
            email: 'El. pašto adresas',
            phone: 'Telefonas',
            companyName: 'Įmonės pavadinimas',
            taxNumber: 'PVM MK',
            streetAndNo: 'Gatvė ir numeris',
            postCode: 'Pašto kodas',
            city: 'Miestas',
            country: 'Šalis',
            incorrectPostCode: 'Neteisingas pašto kodas',
        },
        countryList: {
            Austria: 'Austrija',
            Belgium: 'Belgia',
            Bulgaria: 'Bulgarija',
            Cyprus: 'Kipras',
            CzechRepublic: 'Čekijos Respublikaechy',
            Germany: 'Vokietija',
            Denmark: 'Danija',
            Estonia: 'Estija',
            Greece: 'Graikija',
            Spain: 'Ispanija',
            Finland: 'Suomija',
            France: 'Prancūzija',
            Croatia: 'Kroatija',
            Hungary: 'Vengrija',
            Ireland: 'Airija',
            Italy: 'Italija',
            Lithuania: 'Lietuva',
            Latvia: 'Latvija',
            Malta: 'Malta',
            Netherlands: 'Olandija',
            Poland: 'Lenkija',
            Portugal: 'Portugalija',
            Romania: 'Rumunija',
            Sweden: 'Švedija',
            Slovenia: 'Slovėnija',
            Slovakia: 'Slovakija',
            NorthernIreland: 'Šiaurės Airija',
        }
    },
    expansionPanel: {
        title: 'Žingsniuose įvesti parinkimo parametrai',
    },
    agreementInTouch: {
        title: 'Palaikykime ryšį!',
        intro: 'Kad galėtume parengti produktų pasiūlymus specialiai Jums ir galėtume Jus informuoti apie naujus produktus, prašome duoti sutikimą susisiekti su mumis.',
        mailAgreement: 'Sutinku gauti š „{partner}“ į mano elektroninio pašto adresą komercinę informaciją, pvz., apie siūlomus produktus ir paslaugas, mokymo kursus ir kitus renginius, taip pat reklamines ir rinkodaros kampanijas',
        phoneAgreement: 'Sutinku perduoti savo telefono numerį „{partner}“, kad įmonė susisiektų su manimi (telefonu ir teksto pranešimu) siekiant pateikti man savo komercinę informaciją, pvz., apie savo veiklą, siūlomus produktus, organizuotus mokymo kursus, taip pat reklamines ir rinkodaros kampanijas',
        info: {
            part1: 'Anksčiau pateiktus sutikimus galite bet kada pakeisti Vartotojo paskyros nustatymų skirtuke',
            part2: 'Privatumas, ',
            part3: 'nepažeisdami sutikimo pagrindu atlikto duomenų teisėtumo prieš jo atšaukimą.'
        },
        button: 'Išsaugokite ir pradėkite dirbti',
    },
    footer: {
        partnerOfSolution: 'Parinkimo partneris',
    },
    stepper: {
        back: 'Atgal',
        recalc: 'Perskaičiuoti parinkimą',
        archivalSelection: 'Archyvinis parinkimas',
        newChoice: 'Naujas parinkimas',
        calculate: 'Skaičiuoti',
        next: 'Toliau',
    },
    linearDrainageSystems: {
        intro: {
            title: 'Title',
            desc: 'Desc',
        },
        choosingParameters: 'choosingParameters',
        table: {
            title: 'Reduced area',
            clear: 'Clear',
        },
        reducedAreaTable: {
            area: 'area',
            areaType: 'type',
            reducedArea: 'reducedArea',
            runoffCoefficient: 'runoffCoefficient',
        },
    },
    flushSystems: {
        intro: {
            title: 'Title',
            desc: 'Desc',
        },
        choosingParameters: 'choosingParameters',
        schemaTitle: 'schemaTitle',
        modalTitle: 'modalTitle',
        systemControl: '',
        additionalParameters: '',
        legend: {
            firstRow: [
                '<b>H</b> [m] - wysokość zbiornika retencyjnego',
                '<b>L</b> [m] - długość zbiornika retencyjnego',
                '<b>W</b> [m] - szerokość zbiornika retencyjnego',
                '<b>V</b> [m<sup>2</sup>] - objętość zbiornika retencyjnego',
                '<b>H<sub>c1</sub>, H<sub>c2</sub></b> [cm] - wysokość siatki oddzielającej tory spłukiwane',
                '<b>B<sub>ga</sub></b> [mm] - długość wrota komry spiętrzającej',
            ],
            secondRow: [
                '<b>L<sub>T</sub></b> [m] - długość toru spłukiwanego w zbiorniku',
                '<b>B<sub>str</sub></b> [m] - szerokość toru spłukiwanego w zbiorniku',
                '<b>L<sub>c</sub></b> [m] - długość komory spiętrzającej',
                '<b>T<sub>c</sub></b> [m] - wysokość siatki odzielającej komorę spiętrzającą',
                '<b>V<sub>k</sub></b> [m<sup>3</sup>] - objętość komory spiętrzającej w zbiorniku',
                '<b>V<sub>o</sub></b> [m<sup>3</sup>] - objętość komory odpływowej w zbiorniku',
            ],
        },
        result: {
            title: 'Parametry zbiornika',
            contactInfo: {
                normalPart: '* W przypadku doborów niestandardowych zaleca się',
                bluePart: 'kontakt z firmą Mevapol',
            },
            seriesDetails: {
                title: 'System spłukiwania zbiornika',
                buttonText: 'Zobacz schemat systemu',
            },
            tableSeries: {
                title: 'Zestawienie dobranych systemów spłukiwania',
                head: {
                    lp: 'Lp.',
                    flushTrackLength: 'Długość toru spłukiwanego [${unit}]',
                    storageChamberCapacity: 'Objętość komory spiętrzającej i odpływowej [${unit}]',
                    storageChamberLength: 'Długość komory spiętrzającej [${unit}]',
                    storageChamberHeight: 'Wysokość komory spiętrzającej [${unit}]',
                    chosenItem: 'https://waterfolder-assets.s3.eu-north-1.amazonaws.com/polityka-prywatnosci/polityka-prywatnosci-meva-pol.pdf',
                },
            },
        },
        form: {
            privacyPolicyLink: 'https://waterfolder-assets.s3.eu-north-1.amazonaws.com/polityka-prywatnosci/polityka-prywatnosci-meva-pol.pdf'
        }
    },
    investmentData: {
        title: 'investmentdata title in lt',
        description: 'investmentdata description in lt',
    },
};

const exploitationRouter = {
    path: "eksploatacja",
    name: "exploitation",
    redirect: { name: "exploitation.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            meta: { partner: "veolia" },
            name: "exploitation.investment-data",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "system",
            name: "exploitation.system",
            meta: { partner: "veolia" },
            component: () => import('@/pages/EmptyPage.vue'),
        },
        {
            path: "standard",
            name: "exploitation.standard",
            meta: { partner: "veolia" },
            component: () => import('@/pages/EmptyPage.vue'),
        },
        {
            path: "wyniki",
            name: "exploitation.results",
            meta: { partner: "veolia" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            name: "exploitation.archive",
            meta: { partner: "veolia" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default exploitationRouter;

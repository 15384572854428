const concreteReservoirExtVerRouter = {
    path: "zbiornik-betonowy-z-wyposazeniem",
    name: "concrete-reservoir-extended-version",
    redirect: { name: "concrete-reservoir-extended-version.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            meta: { partner: "ecol_unicon" },
            name: "concrete-reservoir-extended-version.investment-data",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            name: "concrete-reservoir-extended-version.choosing-parameters",
            meta: { partner: "ecol_unicon" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dodatkowe-wyposazenie",
            name: "concrete-reservoir-extended-version.extra-equipment",
            meta: { partner: "ecol_unicon" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-zbiornika",
            name: "concrete-reservoir-extended-version.selecting-products",
            meta: { partner: "ecol_unicon" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            name: "concrete-reservoir-extended-version.archive",
            meta: { partner: "ecol_unicon" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default concreteReservoirExtVerRouter;

import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { camelToKebab, interpolate, metersToHtml } from '@/shared/utils';
import { getCommonStore, getStoreForNewSolutionsStructure, } from '@32/stores/calculatorCommon';
const { actions, getters, persist } = getCommonStore();
const { getters: newStructureGetters } = getStoreForNewSolutionsStructure();
export const useMechanicalGridsStore = defineStore('mechanical-grids', {
    state: () => ({
        VERSION: '1.0',
        isValid: [false, false, false, false],
        calculatorInitConfig: {},
        calculatorCurrentConfigFrontPayload: {},
        calculationParameters: {
            meta: {},
            investData: {},
            payload: {},
        },
        calculationResult: {
            favorite: false,
        },
        status: {
            error: {
                value: false,
                message: '',
            },
            success: false,
            pending: false,
        },
        form: null,
        showModal: false,
        nameStore: 'mechanicalGrids',
        calculatorId: 32,
        isInitialized: false,
        schema: null,
    }),
    persist,
    getters: {
        ...getters,
        ...newStructureGetters,
        getResultSolutionsHead: (state) => {
            const head = cloneDeep(state.calculationResult?.resultsView?.solutionsHead);
            if (!head)
                return;
            for (const key of Object.keys(head)) {
                let unit = state.calculationResult.payloadOut?.data?.selection[0].solutions[0]
                    .mechanicalBarScreen.calculated[key]?.unit;
                unit = unit ? metersToHtml(unit) : unit;
                head[key].label = interpolate(head[key].label, { unit });
            }
            return head;
        },
        getNameStoreKebab: state => {
            return camelToKebab(state.nameStore);
        },
    },
    actions: {
        ...actions,
        async fetchCalculationResult() {
            const parameters = cloneDeep(this.calculationParameters);
            await this.postCalculation(parameters);
        },
        setSchema(schema) {
            this.schema = schema;
        },
    },
});

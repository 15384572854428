const flatRoofsDrainageRouter = {
    path: "odwodnienia-dachow-plaskich",
    name: "flat-roofs-drainage",
    redirect: { name: "flat-roofs-drainage.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            meta: { partner: "wavin-flat-roofs-drainage" },
            name: "flat-roofs-drainage.investment-data",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            name: "flat-roofs-drainage.choosing-parameters",
            meta: { partner: "wavin-flat-roofs-drainage" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-zbiornika",
            name: "flat-roofs-drainage.selecting-products",
            meta: { partner: "wavin-flat-roofs-drainage" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            name: "flat-roofs-drainage.archive",
            meta: { partner: "wavin-flat-roofs-drainage" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default flatRoofsDrainageRouter;

export const dimensionValueSplitter = (dimensionValue) => {
    if (!dimensionValue) {
        return [0, 0];
    }
    const splitted = dimensionValue.split('-');
    if (splitted.length > 1) {
        return splitted.map(parseFloat);
    }
    const parsedValue = parseFloat(dimensionValue);
    return [parsedValue, parsedValue];
};

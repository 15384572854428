import { cloneDeep, get, set } from 'lodash';
import { defineStore } from 'pinia';
import mockData from '@/json/get_config_flow.json';
import { FieldSpecialType, isConfigObject, isConfigSelectArray, isValueWithUnit, } from '@/interfaces/shared';
import { removeSectionIdFromPath } from '@/shared/utils';
import { useConfigFrontDependencies } from '@25/composables/useConfigFrontDependencies';
import { api } from '@/axios/axios';
import i18n from '@/i18n';
export const useFlowCalculationStore = defineStore('flow-calculation', {
    state: () => ({
        nameStore: 'flowCalculation',
        calculatorInitConfig: {},
        calculatorCurrentConfigFrontPayload: {},
        calculationParameters: {
            investData: {},
            payload: {},
        },
    }),
    persist: {
        storage: sessionStorage,
    },
    getters: {
        /**
         * Mapping dependencies to flat form: [dependant, trigger[]][]
         * @param state - store state
         * @returns array of entries, where first element is dependant field
         * and second is array of it's triggers (fields which may change something in dependant field)
         */
        getDependenciesEntries(state) {
            const configDependencies = state.calculatorInitConfig.configFrontDependencies;
            if (!configDependencies) {
                return [];
            }
            const getDependencyTriggerInputs = (rules) => {
                return rules.flatMap(rule => Object.keys(rule.changed));
            };
            return Object.entries(configDependencies).map(([inputToSet, rules]) => {
                return [inputToSet, getDependencyTriggerInputs(rules)];
            });
        },
        /**
         * Inverse dependencies - return function which returns all dependant fields
         */
        getDependenciesForTrigger() {
            return (possibleTriggerInput) => {
                const connectedDependencies = this.getDependenciesEntries.filter(([, triggerInputs]) => triggerInputs.includes(possibleTriggerInput));
                return connectedDependencies.map(([inputToSet]) => inputToSet);
            };
        },
        method: state => state.calculationParameters.payload?.method,
    },
    actions: {
        mutateCalculationParameters(data) {
            set(this.calculationParameters.payload, data.key, data.value);
        },
        getCalculationValue(key) {
            return get(this.calculationParameters?.payload, key);
        },
        initModule() {
            this.calculatorInitConfig = mockData.data;
            const payload = cloneDeep(mockData.data.configFront.payload);
            const setInitPayload = (payload) => {
                const payloadEntries = Object.entries(payload);
                for (const [key, config] of payloadEntries) {
                    if (config.defaultValue === undefined) {
                        if (config.type === 'objectarray') {
                            payload[key] = [payload[key].items];
                            setInitPayload(payload[key][0]);
                        }
                        else if (payload[key].type === 'object') {
                            payload[key] = payload[key].items;
                            setInitPayload(payload[key]);
                        }
                    }
                    else {
                        payload[key] = this.getProperDefaultValue(payload[key]);
                    }
                }
            };
            setInitPayload(payload);
            this.calculationParameters.payload = {
                ...this.calculationParameters.payload,
                ...payload,
            };
        },
        // TODO:
        // most actions below are copied from calculatorCommon (sometimes with some simplifications)
        // need refactor once the way of dealing with subcalculators will be standardized
        getProperDefaultValue(initConfig) {
            const { type, defaultValue, defaultUnit } = initConfig;
            // check type to be consistent with FormComponent
            if (type === 'number' && defaultUnit) {
                return {
                    value: defaultValue,
                    unit: defaultUnit,
                };
            }
            return defaultValue ?? null;
        },
        setAllFieldsCurrentConfig() {
            const entries = Object.entries(this.calculatorInitConfig?.configFront.payload);
            entries.forEach(([key, config]) => {
                this.calculatorCurrentConfigFrontPayload[key] = cloneDeep(config);
            });
        },
        getFieldInitConfig(fieldKey) {
            const fieldKeyWithoutSection = removeSectionIdFromPath(fieldKey);
            const configKey = this.updateFieldKeyForConfig(fieldKeyWithoutSection);
            return get(this.calculatorInitConfig?.configFront.payload, configKey);
        },
        getFieldConfig(fieldKey) {
            const configKey = this.updateFieldKeyForConfig(fieldKey);
            this.checkCurrentConfig(configKey);
            return get(this.calculatorCurrentConfigFrontPayload, configKey);
        },
        updateFieldKeyForConfig(key) {
            const segments = key.split('.');
            let result = segments.shift();
            if (!result) {
                return key;
            }
            segments.forEach((segment, i) => {
                if (segment.match(/^\d+$/)) {
                    result += `.items.${segment}`;
                }
                else {
                    const isInSection = i > 0 && segments[i - 1].match(/^\d+$/);
                    if (isInSection)
                        result += `.${segment}`;
                    else
                        result += `.items.${segment}`;
                }
            });
            return result;
        },
        checkCurrentConfig(fieldKey) {
            const isNotDefinedCurrentConfig = !get(this.calculatorCurrentConfigFrontPayload, fieldKey);
            if (isNotDefinedCurrentConfig &&
                this.calculatorCurrentConfigFrontPayload) {
                const fieldRootKey = fieldKey.split('.')[0];
                const rootInitConfig = this.getFieldInitConfig(fieldRootKey);
                const notHandledObjectArrays = [
                    'reducedAreaTable',
                    'charts',
                    'sewageWellPipeArray',
                ];
                if (rootInitConfig?.type !== FieldSpecialType.ObjectArray ||
                    notHandledObjectArrays.includes(fieldRootKey)) {
                    const copyFromInit = cloneDeep(get(this.calculatorInitConfig?.configFront.payload, fieldKey));
                    set(this.calculatorCurrentConfigFrontPayload, fieldKey, copyFromInit);
                }
            }
        },
        refreshDependencies(payload) {
            const fieldName = removeSectionIdFromPath(payload.fieldPath);
            const { setDependentInputs } = useConfigFrontDependencies(this);
            const dependencies = this.getDependenciesForTrigger(fieldName);
            if (dependencies) {
                dependencies.forEach(inputToSet => {
                    payload.dontChangeValue
                        ? setDependentInputs(inputToSet, payload.fieldPath, undefined, true)
                        : setDependentInputs(inputToSet, payload.fieldPath);
                });
            }
        },
        mutateParameterVisibility(data) {
            const fieldConfig = this.getFieldConfig(data.key);
            fieldConfig.visible = data.value;
        },
        mutateParameterReadonly(data) {
            const fieldConfig = this.getFieldConfig(data.key);
            fieldConfig.readonly = data.value;
            if (data.value && isConfigObject(fieldConfig)) {
                this.resetChildrenValue(data.key, fieldConfig);
            }
        },
        mutateParameterItems(data) {
            const initItems = this.getFieldInitConfig(data.key).items;
            if (Array.isArray(initItems)) {
                const fieldConfig = this.getFieldConfig(data.key);
                const newItems = initItems.filter((item) => {
                    return data.newItemsValues.includes(item.value);
                });
                fieldConfig.items = newItems;
            }
            this.setDefaultIfNotInItems(data.key);
        },
        setDefaultIfNotInItems(fieldKey) {
            const fieldConfig = this.getFieldConfig(fieldKey);
            const fieldValue = this.getCalculationValue(fieldKey);
            // setting default item only for fields with array of items
            if (!fieldConfig || !isConfigSelectArray(fieldConfig)) {
                return;
            }
            const isValueInItems = fieldConfig.items?.some(item => {
                if (isValueWithUnit(fieldValue)) {
                    return item.value === fieldValue.value;
                }
                else {
                    return item.value === fieldValue;
                }
            });
            if (!isValueInItems) {
                this.resetParameterValue(fieldKey);
            }
        },
        resetParameterValue(fieldKey) {
            const fieldInitConfig = this.getFieldInitConfig(fieldKey);
            const newValue = this.getProperDefaultValue(fieldInitConfig);
            this.mutateCalculationParameters({
                key: fieldKey,
                value: newValue,
            });
        },
        mutateParameterDisability(data) {
            const fieldConfig = this.getFieldConfig(data.key);
            fieldConfig.disable = data.value;
            if (data.value && isConfigObject(fieldConfig)) {
                this.resetChildrenValue(data.key, fieldConfig);
            }
        },
        resetChildrenValue(parentKey, config) {
            Object.entries(config.items).forEach(([childKey, childConfig]) => {
                const childComposedKey = [parentKey, childKey].join('.');
                this.mutateCalculationParameters({
                    key: childComposedKey,
                    value: this.getProperDefaultValue(childConfig),
                });
            });
        },
        mutateReducedArea(payload) {
            this.calculationParameters.payload.reducedAreaTable[payload.rowIndex][payload.key] = payload.value;
        },
        resetReducedAreaTable() {
            this.calculationParameters.payload.reducedAreaTable.splice(0);
            this.calculationParameters.payload.reducedAreaTable.push(this.getReducedAreaDefaultRow());
        },
        getReducedAreaDefaultRow() {
            const reducedAreaTableConfig = this.calculatorInitConfig.configFront.payload?.reducedAreaTable;
            const reducedAreaItemsEntries = Object.entries(reducedAreaTableConfig.items);
            const reducedAreaNewRowEntries = reducedAreaItemsEntries.map(([key, config]) => {
                return [key, this.getProperDefaultValue(config)];
            });
            return Object.fromEntries(reducedAreaNewRowEntries);
        },
        setInvestData(investData) {
            this.calculationParameters.investData = cloneDeep(investData);
        },
        async fetchResults() {
            const parameters = this.changeInvisibleToNull(cloneDeep(this.calculationParameters));
            try {
                const { data } = await api.post('helper/reliableFlow', parameters);
                if (data.status) {
                    return data;
                }
            }
            catch (error) {
                console.error(error);
            }
            const error = {
                message: i18n.global.t('flowCalculation.customErrorMessages.failedCalculation'),
            };
            return {
                data: null,
                status: false,
                error,
            };
        },
        changeInvisibleToNull(parameters) {
            if (!parameters) {
                return;
            }
            const { payload } = parameters;
            for (const [key] of Object.entries(payload)) {
                const currentKeyConfig = this.getFieldConfig(key);
                if (currentKeyConfig && !currentKeyConfig.visible) {
                    if (currentKeyConfig.defaultUnit) {
                        payload[key] = {
                            value: null,
                            unit: currentKeyConfig.defaultUnit,
                        };
                    }
                    else {
                        payload[key] = null;
                    }
                }
            }
            return parameters;
        },
    },
});

const gravityDrainageRouter = {
    path: 'systemy-grawitacyjne',
    name: 'gravity-drainage',
    redirect: { name: 'gravity-drainage.investment-data' },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: 'dane-inwestycji',
            meta: { partner: 'wavin-gravity-drainage' },
            name: 'gravity-drainage.investment-data',
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: 'wybor-parametrow',
            name: 'gravity-drainage.choosing-parameters',
            meta: { partner: 'wavin-gravity-drainage' },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: 'wybor-zbiornika',
            name: 'gravity-drainage.selecting-products',
            meta: { partner: 'wavin-gravity-drainage' },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: 'dobor-archiwalny/:calculationKey',
            name: 'gravity-drainage.archive',
            meta: { partner: 'wavin-gravity-drainage' },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default gravityDrainageRouter;

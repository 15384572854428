export var InvestmentType;
(function (InvestmentType) {
    InvestmentType["PublicProcurementLaw"] = "publicProcurementLaw";
    InvestmentType["PrivateInvestment"] = "privateInvestment";
})(InvestmentType || (InvestmentType = {}));
export var Investor;
(function (Investor) {
    Investor["Company"] = "company";
    Investor["Individual"] = "individual";
})(Investor || (Investor = {}));
export var EnvironmentalCondition;
(function (EnvironmentalCondition) {
    EnvironmentalCondition["NoGroundDrainage"] = "noGroundDrainage";
    EnvironmentalCondition["ImpermeableSoil"] = "impermeableSoil";
    EnvironmentalCondition["PermeableSoil"] = "permeableSoil";
})(EnvironmentalCondition || (EnvironmentalCondition = {}));
export var TankPlacement;
(function (TankPlacement) {
    TankPlacement["InsideCatchmentArea"] = "insideCatchmentArea";
    TankPlacement["OutsideCatchmentArea"] = "outsideCatchmentArea";
})(TankPlacement || (TankPlacement = {}));
export var RiskCoefficient;
(function (RiskCoefficient) {
    RiskCoefficient[RiskCoefficient["Low"] = 1.1] = "Low";
    RiskCoefficient[RiskCoefficient["Medium"] = 1.15] = "Medium";
    RiskCoefficient[RiskCoefficient["High"] = 1.2] = "High";
})(RiskCoefficient || (RiskCoefficient = {}));
export var SizeRatioType;
(function (SizeRatioType) {
    SizeRatioType["Terrain"] = "terrain";
    SizeRatioType["Square"] = "square";
    SizeRatioType["Rectangle"] = "rectangle";
})(SizeRatioType || (SizeRatioType = {}));

export var CommunicationCulvertSelectionType;
(function (CommunicationCulvertSelectionType) {
    CommunicationCulvertSelectionType["Full"] = "full";
    CommunicationCulvertSelectionType["Verifying"] = "verifying";
})(CommunicationCulvertSelectionType || (CommunicationCulvertSelectionType = {}));
export var CommunicationCulvertInstallationMethod;
(function (CommunicationCulvertInstallationMethod) {
    CommunicationCulvertInstallationMethod["OpenTrench"] = "open_trench";
    CommunicationCulvertInstallationMethod["Trenchless"] = "trenchless";
    CommunicationCulvertInstallationMethod["Renovation"] = "renovation";
})(CommunicationCulvertInstallationMethod || (CommunicationCulvertInstallationMethod = {}));
export var CommunicationCulvertShelves;
(function (CommunicationCulvertShelves) {
    CommunicationCulvertShelves["Unspecified"] = "unspecified";
    CommunicationCulvertShelves["OneSide"] = "oneSide";
    CommunicationCulvertShelves["BothSides"] = "bothSides";
    CommunicationCulvertShelves["FullWidth"] = "fullWidth";
})(CommunicationCulvertShelves || (CommunicationCulvertShelves = {}));
export var CommunicationCulvertGravel;
(function (CommunicationCulvertGravel) {
    CommunicationCulvertGravel["Unspecified"] = "unspecified";
    CommunicationCulvertGravel["H100"] = "h100";
    CommunicationCulvertGravel["H200"] = "h200";
    CommunicationCulvertGravel["H300"] = "h300";
})(CommunicationCulvertGravel || (CommunicationCulvertGravel = {}));
/**
 * Enum value - index of dimension in non-circular shape
 */
export var CommunicationCulvertDimensionType;
(function (CommunicationCulvertDimensionType) {
    CommunicationCulvertDimensionType[CommunicationCulvertDimensionType["Width"] = 0] = "Width";
    CommunicationCulvertDimensionType[CommunicationCulvertDimensionType["Height"] = 1] = "Height";
})(CommunicationCulvertDimensionType || (CommunicationCulvertDimensionType = {}));
export var CommunicationCulvertShape;
(function (CommunicationCulvertShape) {
    CommunicationCulvertShape["Circular"] = "circular";
    CommunicationCulvertShape["NonCircular"] = "non-circular";
})(CommunicationCulvertShape || (CommunicationCulvertShape = {}));
export var CommunicationCulvertVariant;
(function (CommunicationCulvertVariant) {
    CommunicationCulvertVariant["Notsubmerged"] = "notsubmerged";
    CommunicationCulvertVariant["Submerged"] = "submerged";
})(CommunicationCulvertVariant || (CommunicationCulvertVariant = {}));
export var InputSize;
(function (InputSize) {
    InputSize["Small"] = "small";
    InputSize["MediumSmall"] = "mediumSmall";
    InputSize["Medium"] = "medium";
    InputSize["Large"] = "large";
})(InputSize || (InputSize = {}));

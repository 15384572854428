const pumpsRouter = {
    path: "pompy",
    name: "pump",
    redirect: { name: "pump.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            meta: { partner: "grundfos" },
            name: "pump.investment-data",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            name: "pump.choosing-parameters",
            meta: { partner: "grundfos" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "przewody-tloczne",
            name: "pump.discharge-lines",
            meta: { partner: "grundfos" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-zbiornika",
            name: "pump.selecting-products",
            meta: { partner: "grundfos" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            meta: { partner: "grundfos" },
            name: "pump.archive",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default pumpsRouter;

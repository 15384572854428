import i18n from '@/i18n';
import { ComponentName } from '@/interfaces/shared';
import breakpoints from '@/shared/breakpoints';
import { CommunicationCulvertShape, CommunicationCulvertVariant, } from '@25/interfaces/communicationCulverts';
export const FILTER_ITEM_BOTH = 'both';
const SHAPE_FILTER_ITEMS_VALUES = [
    FILTER_ITEM_BOTH,
    ...Object.values(CommunicationCulvertShape),
];
const VARIANT_FILTER_ITEMS_VALUES = [
    FILTER_ITEM_BOTH,
    ...Object.values(CommunicationCulvertVariant),
];
export const getDefaultFiltersValues = () => ({
    shape: FILTER_ITEM_BOTH,
    variant: FILTER_ITEM_BOTH,
    DEHeight: 0,
    DEWidth: 0,
    // filters hidden, so better to set value which accept all solutions
    fillDepthPct: breakpoints.isSmallerOrEqual('mobileXS') ? 100 : 75,
});
export const SHAPE_FILTER_CONFIG = {
    type: 'string',
    componentName: ComponentName.Select,
    label: i18n.global.t('communicationCulvert.result.filters.shapeFilter.label'),
    tooltip: {
        title: null,
        description: null,
    },
    tooltipVisible: false,
    placeholder: null,
    items: SHAPE_FILTER_ITEMS_VALUES.map(value => {
        return {
            label: i18n.global.t(`communicationCulvert.result.filters.shapeFilter.items.${value}`),
            value,
        };
    }),
    itemsMultiple: false,
    visible: true,
    disable: false,
    readonly: false,
    defaultValue: null,
    defaultUnit: null,
    unitItems: null,
};
export const VARIANT_FILTER_CONFIG = {
    type: 'string',
    componentName: ComponentName.Select,
    label: i18n.global.t('communicationCulvert.result.filters.variantFilter.label'),
    tooltip: {
        title: null,
        description: null,
    },
    tooltipVisible: false,
    placeholder: null,
    items: VARIANT_FILTER_ITEMS_VALUES.map(value => {
        return {
            label: i18n.global.t(`communicationCulvert.result.filters.variantFilter.items.${value}`),
            value,
        };
    }),
    itemsMultiple: false,
    visible: true,
    disable: false,
    readonly: false,
    defaultValue: null,
    defaultUnit: null,
    unitItems: null,
};
export const FILTER_SCHEMA_SIZE = 180;
export const FILTER_SCHEMA_WHITE_MASK_SIZE = FILTER_SCHEMA_SIZE - 30;
export const NON_CIRCULAR_STIFFNESS_VALUE = '-';

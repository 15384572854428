const gutterSystemssRouter = {
    path: "systemy-rynnowe",
    name: "gutter-systems",
    redirect: { name: "gutter-systems.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            name: "gutter-systems.investment-data",
            meta: { partner: "wavin-gutter-systems" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            name: "gutter-systems.choosing-parameters",
            meta: { partner: "wavin-gutter-systems" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-zbiornika",
            name: "gutter-systems.selecting-products",
            meta: { partner: "wavin-gutter-systems" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            meta: { partner: "wavin-gutter-systems" },
            name: "gutter-systems.archive",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default gutterSystemssRouter;

import flushSystemsRouter from '@/router/calculators/flushSystemsRouter';
import sewerRenovationRouter from '@/components/main/calculators/28_sewerRenovation/router/sewerRenovationRouter';
import communicationCulvertsRouter from '@/components/main/calculators/25_communicationCulverts/router/communicationCulvertsRouter';
import mechanicalGridsRouter from '@/components/main/calculators/32_mechanicalGrids/router/mechanicalGridsRouter';
import openedInfiltrationTanksRouter from '@30/router/openedInfiltrationTanksRouter';
import linearDrainageSystemsRouter from '@/router/calculators/linearDrainageSystemsRouter';
import accountRouter from '@/router/account';
import adminRouter from '@/router/admin';
import concreteReservoirRouter from '@/router/calculators/concreteReservoirRouter';
import infiltrationDevicesRouter from '@/router/calculators/infiltrationDevicesRouter';
import pipesSystemsRouter from '@/router/calculators/pipesSystemsRouter';
import pumpingStationsRouter from '@/router/calculators/pumpingStationsRouter';
import concreteReservoirExtVerRouter from '@/router/calculators/concreteReservoirExtVerRouter';
import homeReservoirRouter from '@/router/calculators/cities/homeReservoirRouter';
import openReservoirRouter from '@/router/calculators/openReservoirRouter';
import retentionReservoirRouter from '@/router/calculators/cities/retentionReservoirRouter';
import citiesOpenReservoirRouter from '@/router/calculators/cities/openReservoirRouter';
import citiesInfiltrationDevicesRouter from '@/router/calculators/cities/infiltrationDevicesRouter';
import gravityCablesRouter from '@/router/calculators/gravityCablesRouter';
import greenRoofsRouter from '@/router/calculators/greenRoofsRouter';
import pumpsRouter from '@/router/calculators/pumpsRouter';
import pretreatmentSystemsRouter from '@/router/calculators/pretreatmentSystemsRouter';
import exploitationRouter from '@/router/calculators/exploitationRouter';
import regulatorsRouter from '@/router/calculators/regulatorsRouter';
import pipesSystemsPEHDRouter from '@/router/calculators/pipesSystemsPEHDRouter';
import flatRoofsDrainageRouter from '@/router/calculators/flatRoofsDrainageRouter';
import gutterSystemsRouter from '@/router/calculators/gutterSystemsRouter';
import homeReservoirInSituRouter from '@/router/calculators/homeReservoirInSituRouter';
import gravityDrainageRouter from '@/router/calculators/gravityDrainageRouter';
const routes = [
    {
        path: '/:lang?/auth',
        name: 'auth',
        redirect: { name: 'login' },
        children: [
            {
                path: 'login',
                name: 'login',
                component: import('@/pages/EmptyPage.vue'),
            },
            {
                path: 'register',
                name: 'register',
                component: import('@/pages/EmptyPage.vue'),
            },
            {
                path: 'lostpassword',
                name: 'lostpassword',
                component: import('@/pages/EmptyPage.vue'),
            },
            {
                path: 'changepassword',
                name: 'changepassword',
                component: import('@/pages/EmptyPage.vue'),
            },
            {
                path: 'remove',
                name: 'removeAccount',
                component: import('@/pages/EmptyPage.vue'),
            },
        ],
    },
    {
        path: '/:lang?',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            { path: '', name: 'main-page', redirect: { name: 'dashboard' } },
            {
                path: 'polityka-prywatnosci',
                name: 'privacy-policy',
                component: import('@/pages/EmptyPage.vue'),
            },
            {
                path: 'regulamin',
                name: 'regulations',
                component: import('@/pages/EmptyPage.vue'),
            },
            {
                path: 'nowy-regulamin',
                name: 'new-regulations',
                component: import('@/pages/EmptyPage.vue'),
            },
            {
                path: 'kalkulatory',
                name: 'calculators',
                component: import('@/pages/EmptyPage.vue'),
            },
        ],
    },
    {
        path: '/:lang?/app',
        name: 'app',
        component: () => import('@/layouts/MainLayout.vue'),
        redirect: { name: 'dashboard' },
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/pages/EmptyPage.vue'),
            },
            {
                path: 'raport',
                name: 'report',
                component: import('@/pages/EmptyPage.vue'),
            },
            accountRouter,
            adminRouter,
            concreteReservoirRouter,
            linearDrainageSystemsRouter,
            infiltrationDevicesRouter,
            pipesSystemsRouter,
            pumpingStationsRouter,
            concreteReservoirExtVerRouter,
            homeReservoirRouter,
            openReservoirRouter,
            retentionReservoirRouter,
            citiesOpenReservoirRouter,
            citiesInfiltrationDevicesRouter,
            gravityCablesRouter,
            greenRoofsRouter,
            pumpsRouter,
            pretreatmentSystemsRouter,
            exploitationRouter,
            regulatorsRouter,
            pipesSystemsPEHDRouter,
            flatRoofsDrainageRouter,
            gutterSystemsRouter,
            homeReservoirInSituRouter,
            gravityDrainageRouter,
            flushSystemsRouter,
            sewerRenovationRouter,
            communicationCulvertsRouter,
            mechanicalGridsRouter,
            openedInfiltrationTanksRouter,
        ],
    },
    // Always leave at last
    { path: '/:pathMatch(.*)*', redirect: { name: '/:lang?/app/dashboard' } },
];
export default routes;

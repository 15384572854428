import messages from '@/i18n/translations';
import { createI18n } from 'vue-i18n';
const i18n = createI18n({
    locale: 'pl',
    globalInjection: true,
    fallbackLocale: 'pl',
    messages,
    silentTranslationWarn: true,
    warnHtmlMessage: false,
    legacy: false,
});
export default i18n;

const citiesInfiltrationDevicesRouter = {
    path: "zbiorniki-rozsaczajace-miasto",
    name: "cities-infiltration-devices",
    redirect: { name: "cities-infiltration-devices.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            meta: { partner: "wavin" },
            name: "cities-infiltration-devices.investment-data",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            meta: { partner: "wavin" },
            name: "cities-infiltration-devices.choosing-parameters",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-zbiornika",
            meta: { partner: "wavin" },
            name: "cities-infiltration-devices.selecting-products",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            meta: { partner: "wavin" },
            name: "cities-infiltration-devices.archive",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default citiesInfiltrationDevicesRouter;

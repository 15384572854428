import { useCommunicationCulvertsStore } from '@25/store/25_communication-culverts';
import { getCalculatorBeforeEnterGuard } from '@/shared/routerUtils';
import { useFlowCalculationStore } from '@/components/shared/flowCalculation/store/flowCalculationStore';
const communicationCulvertsRouter = {
    path: 'przepusty-komunikacyjne',
    name: 'communication-culverts',
    redirect: { name: 'communication-culverts.investment-data' },
    component: () => import('@/components/main/calculators/25_communicationCulverts/pages/communicationCulverts/communicationCulverts.vue'),
    beforeEnter: getCalculatorBeforeEnterGuard(useCommunicationCulvertsStore),
    children: [
        {
            path: 'dane-inwestycji',
            name: 'communication-culverts.investment-data',
            beforeEnter: () => {
                const communicationCulvertsStore = useCommunicationCulvertsStore();
                communicationCulvertsStore.checkSeriesItems();
            },
            components: {
                left: () => import('@/components/main/calculators/25_communicationCulverts/investmentData/InvestmentData.vue'),
                right: () => import('@/components/main/calculators/25_communicationCulverts/investmentData/InvestmentMap.vue'),
            },
        },
        {
            path: 'wybor-parametrow',
            name: 'communication-culverts.choosing-parameters',
            components: {
                left: () => import('@/components/main/calculators/25_communicationCulverts/choosingParameters/ChoosingParametersForm.vue'),
                right: () => import('@/components/main/calculators/25_communicationCulverts/choosingParameters/ChoosingParametersSchema.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                const communicationCulvertsStore = useCommunicationCulvertsStore();
                if (communicationCulvertsStore.isValid[0]) {
                    const flowCalculationsStore = useFlowCalculationStore();
                    flowCalculationsStore.setInvestData(communicationCulvertsStore.calculationParameters.investData);
                    next();
                }
                else {
                    next({ name: 'communication-culverts.investment-data' });
                }
            },
        },
        {
            path: 'wynik',
            name: 'communication-culverts.result',
            components: {
                left: () => import('@/components/main/calculators/25_communicationCulverts/selectingProducts/ResultDetails.vue'),
                right: () => import('@/components/main/calculators/25_communicationCulverts/selectingProducts/ResultTable.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                const communicationCulvertsStore = useCommunicationCulvertsStore();
                if (communicationCulvertsStore.isValid[0]) {
                    next();
                }
                else {
                    next({ name: 'communication-culverts.investment-data' });
                }
                next();
            },
        },
        {
            path: 'dobor-archiwalny/:calculationKey',
            name: 'communication-culverts.archive',
            components: {
                left: () => import('@/components/main/calculators/25_communicationCulverts/archive/ResultDetails.vue'),
                right: () => import('@/components/main/calculators/25_communicationCulverts/archive/ResultCalculationData.vue'),
            },
            beforeEnter: async (to, _from, next) => {
                const communicationCulvertsStore = useCommunicationCulvertsStore();
                try {
                    await communicationCulvertsStore.fetchHistoricalCalculation(to.params.calculationKey);
                    next();
                }
                catch {
                    next({
                        name: 'dashboard', // back to safety route //
                    });
                }
            },
        },
    ],
};
export default communicationCulvertsRouter;

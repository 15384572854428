import { formatValueWithUnit } from '@/shared/utils';
import { useOpenedInfiltrationTanksStore } from '@30/stores/30_openedInfiltrationTanks';
import { computed } from 'vue';
export const useResultSchema = (isResultView) => {
    const calculatorStore = useOpenedInfiltrationTanksStore();
    const slopeToAreaEdgeDistance = computed(() => {
        const valueWithUnit = calculatorStore.getCalculationValue('slopeToAreaEdgeDistance');
        return isResultView.value ? formatValueWithUnit(valueWithUnit) : 'o';
    });
    const tankSlope = computed(() => {
        return isResultView.value
            ? `1:${calculatorStore.getCalculationValue('tankSlope')}`
            : 'S';
    });
    const tankDepth = computed(() => {
        const valueWithUnit = calculatorStore.getCalculationValue('tankDepth');
        return isResultView.value ? formatValueWithUnit(valueWithUnit) : 'H';
    });
    const freeboard = computed(() => {
        const valueWithUnit = calculatorStore.getCalculationValue('freeboard');
        return isResultView.value ? formatValueWithUnit(valueWithUnit) : 'b';
    });
    const groundWaterElevation = computed(() => {
        const valueWithUnit = calculatorStore.getCalculationValue('groundWaterElevation');
        if (isResultView.value) {
            return valueWithUnit.value ? formatValueWithUnit(valueWithUnit) : '';
        }
        return 'w.g.';
    });
    return {
        slopeToAreaEdgeDistance,
        tankSlope,
        tankDepth,
        freeboard,
        groundWaterElevation,
    };
};

import { api } from '@/axios/axios';
import axios from 'axios';
export const postAsync = async (params) => {
    params.setStatus({ key: 'pending', value: true });
    try {
        const { data } = await api.post(params.url, params.payload, params.options);
        handlePostSuccess(data, params.setStatus);
        params.setStatus({ key: 'pending', value: false });
        return data.data;
    }
    catch (e) {
        if (axios.isCancel(e)) {
            return undefined;
        }
        handlePostError(params.setStatus);
        params.setStatus({ key: 'pending', value: false });
        return e.response?.data;
    }
};
const isResponseWithError = (responseData) => {
    return !!responseData?.error;
};
const handlePostSuccess = (data, setStatus) => {
    if (isResponseWithError(data)) {
        handlePostResponseWithError(data.error, setStatus);
    }
    else {
        setStatus({ key: 'error', value: { value: false, message: '' } });
        setStatus({ key: 'success', value: true });
    }
};
const handlePostResponseWithError = (errorData, setStatus) => {
    const isDev = process.env.VUE_APP_MODE === 'development';
    // empty string for default message in modal
    const message = isDev || errorData.errorNumber === 2 ? errorData.message : '';
    setStatus({
        key: 'error',
        value: { value: true, message },
    });
};
const handlePostError = (setStatus) => {
    setStatus({
        key: 'error',
        value: { value: true, message: '' },
    });
};
export const getAsync = async (params) => {
    params.setStatus({ key: 'pending', value: true });
    try {
        const response = await api.get(params.url);
        params.setStatus({ key: 'pending', value: false });
        params.setStatus({ key: 'error', value: { value: false, message: '' } });
        params.setStatus({ key: 'success', value: true });
        return response.data.data;
    }
    catch (e) {
        params.setStatus({ key: 'pending', value: false });
        if (e.response) {
            // handle router
            // if (e.response.status === 401) {
            //     const entryUrl = (router as any).history.current.query.entryUrl;
            //     router.push({
            //         path: '/login',
            //         query: {entryUrl},
            //     });
            // }
            if (e.response.data.error) {
                if (e.response.data.error.hasOwnProperty('errors') &&
                    e.response.data.error.errors) {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.errors },
                    });
                }
                else {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.message },
                    });
                }
            }
            else if (e.response.data.message) {
                params.setStatus({
                    key: 'error',
                    value: { value: true, message: e.response.data.message },
                });
            }
            else if (e.response.data.data.message) {
                params.setStatus({
                    key: 'error',
                    value: { value: true, message: e.response.data.data.message },
                });
            }
        }
        return e.response?.data;
    }
};
export const putAsync = async (params) => {
    params.setStatus({ key: 'pending', value: true });
    try {
        const response = await api.put(params.url, params.payload);
        params.setStatus({ key: 'pending', value: false });
        params.setStatus({ key: 'error', value: { value: false, message: '' } });
        params.setStatus({ key: 'success', value: true });
        return response.data.data;
    }
    catch (e) {
        params.setStatus({ key: 'pending', value: false });
        if (e.response) {
            // handle router
            // if (e.response.status === 401) {
            //     const entryUrl = (router as any).history.current.query.entryUrl;
            //     router.push({
            //         path: '/login',
            //         query: {entryUrl},
            //     });
            // }
            if (e.response.data.error) {
                if (e.response.data.error.hasOwnProperty('errors') &&
                    e.response.data.error.errors) {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.errors },
                    });
                }
                else {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.message },
                    });
                }
            }
            else if (e.response.data.data.message) {
                params.setStatus({
                    key: 'error',
                    value: { value: true, message: e.response.data.data.message },
                });
            }
        }
        return e.response.data;
    }
};
export const deleteAsync = async (params) => {
    params.setStatus({ key: 'pending', value: true });
    try {
        const response = await api.delete(params.url, {
            data: { ...params.payload },
        });
        params.setStatus({ key: 'pending', value: false });
        params.setStatus({ key: 'error', value: { value: false, message: '' } });
        params.setStatus({ key: 'success', value: true });
        return response.data.data;
    }
    catch (e) {
        params.setStatus({ key: 'pending', value: false });
        if (e.response) {
            // handle router
            // if (e.response.status === 401) {
            //     const entryUrl = (router as any).history.current.query.entryUrl;
            //     router.push({
            //         path: '/login',
            //         query: {entryUrl},
            //     });
            // }
            if (e.response.data.error) {
                if (e.response.data.error.hasOwnProperty('errors') &&
                    e.response.data.error.errors) {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.errors },
                    });
                }
                else {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.message },
                    });
                }
            }
            else if (e.response.data.data.message) {
                params.setStatus({
                    key: 'error',
                    value: { value: true, message: e.response.data.data.message },
                });
            }
        }
        return e.response.data;
    }
};

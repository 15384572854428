const adminRouter = {
    path: 'admin',
    name: 'admin',
    redirect: { name: 'admin.calcs' },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: '',
            name: 'admin.main',
            component: () => import('@/pages/EmptyPage.vue'),
            children: [
                {
                    path: 'dobory',
                    name: "admin.calcs",
                    component: () => import('@/pages/EmptyPage.vue'),
                },
                {
                    path: 'uzytkownicy',
                    name: "admin.users",
                    component: () => import('@/pages/EmptyPage.vue'),
                },
            ]
        },
        {
            path: 'uzytkownik/:id',
            name: "admin.user",
            component: () => import('@/pages/EmptyPage.vue'),
        },
    ],
};
export default adminRouter;

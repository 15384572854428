import { useSewerRenovationStore } from '@/components/main/calculators/28_sewerRenovation/store/28-sewer-renovation';
const sewerRenovationRouter = {
    path: 'renowacja-kanalow',
    name: 'sewer-renovation',
    redirect: { name: 'sewer-renovation.investment-data' },
    component: () => import('@/components/main/calculators/28_sewerRenovation/pages/sewerRenovation/sewerRenovation.vue'),
    beforeEnter: async (_to, _from, next) => {
        try {
            const sewerRenovationStore = useSewerRenovationStore();
            let storeFromLocalStorage = null;
            const sewerRenovationStorage = localStorage.getItem(sewerRenovationStore.nameStore);
            if (sewerRenovationStorage) {
                storeFromLocalStorage = JSON.parse(sewerRenovationStorage);
            }
            if (storeFromLocalStorage &&
                sewerRenovationStore.VERSION === storeFromLocalStorage.VERSION) {
                sewerRenovationStore.calculationParameters =
                    storeFromLocalStorage.calculationParameters;
                sewerRenovationStore.calculationResult =
                    storeFromLocalStorage.calculationResult;
            }
            await sewerRenovationStore.initModule();
            next();
        }
        catch (e) {
            next({
                name: 'dashboard', // back to safety route //
            });
        }
    },
    children: [
        {
            path: 'dane-inwestycji',
            name: 'sewer-renovation.investment-data',
            components: {
                left: () => import('@/components/main/calculators/28_sewerRenovation/investmentData/InvestmentData.vue'),
                right: () => import('@/components/main/calculators/28_sewerRenovation/investmentData/InvestmentMap.vue'),
            },
        },
        {
            path: 'wybor-parametrow',
            name: 'sewer-renovation.choosing-parameters',
            components: {
                left: () => import('@/components/main/calculators/28_sewerRenovation/choosingParameters/ChoosingParametersForm.vue'),
                right: () => import('@/components/main/calculators/28_sewerRenovation/choosingParameters/ChoosingParametersSchema.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                const sewerRenovationStore = useSewerRenovationStore();
                if (sewerRenovationStore.isValid[0]) {
                    next();
                }
                else {
                    next({ name: 'sewer-renovation.investment-data' });
                }
            },
        },
        {
            path: 'wynik',
            name: 'sewer-renovation.result',
            components: {
                left: () => import('@/components/main/calculators/28_sewerRenovation/selectingProducts/ResultDetails.vue'),
                right: () => import('@/components/main/calculators/28_sewerRenovation/selectingProducts/ResultTable.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                const sewerRenovationStore = useSewerRenovationStore();
                if (sewerRenovationStore.isValid[1]) {
                    next();
                }
                else {
                    next({ name: 'sewer-renovation.investment-data' });
                }
                next();
            },
        },
        {
            path: 'dobor-archiwalny/:calculationKey',
            name: 'sewer-renovation.archive',
            components: {
                left: () => import('@/components/main/calculators/28_sewerRenovation/archive/ResultDetails.vue'),
                right: () => import('@/components/main/calculators/28_sewerRenovation/archive/ResultCalculationData.vue'),
            },
            beforeEnter: async (to, _from, next) => {
                const sewerRenovationStore = useSewerRenovationStore();
                try {
                    await sewerRenovationStore.fetchHistoricalCalculation(to.params.calculationKey);
                    next();
                }
                catch {
                    next({
                        name: 'dashboard', // back to safety route //
                    });
                }
            },
        },
    ],
};
export default sewerRenovationRouter;

import { defineStore } from 'pinia';
import { getAsync, postAsync } from '@/shared/asyncAxiosUtil';
import { interpolate, metersToHtml } from '@/shared/utils';
import _ from 'lodash';
export const useFlushSystemsStore = defineStore('flush-systems', {
    state: () => ({
        VERSION: 1.0,
        isValid: [false, false, false, false],
        isBogdanowiczRegion: false,
        calculatorInitConfig: {},
        calculationParameters: {
            meta: {},
            investData: {},
            payload: {},
        },
        calculationResult: {
            favorite: false,
        },
        status: {
            error: {
                value: false,
                message: '',
            },
            success: false,
            pending: false,
        },
        form: {},
        showModal: false,
        schema: {},
        nameStore: 'flushSystemsStore',
    }),
    getters: {
        getResultParameters: state => {
            return (neededValues) => {
                const parameters = neededValues.map(neededValue => {
                    const payloadIn = _.cloneDeep(state.calculationResult.payloadIn?.payload);
                    const payloadOut = _.cloneDeep(state.calculationResult.payloadOut?.data);
                    const data = _.merge(payloadIn, payloadOut);
                    if (typeof data[neededValue.value] !== 'object' &&
                        data[neededValue.value] !== null &&
                        data[neededValue.value] !== undefined) {
                        return {
                            translation: state.calculationResult.resultsView[neededValue.value],
                            value: data[neededValue.value],
                            unit: '',
                            margin: neededValue.margin,
                        };
                    }
                    else if (data[neededValue.value]?.value !== null &&
                        data[neededValue.value]?.value !== undefined) {
                        return {
                            translation: state.calculationResult.resultsView[neededValue.value],
                            value: data[neededValue.value]?.value,
                            unit: metersToHtml(data[neededValue.value]?.unit),
                            margin: neededValue.margin,
                        };
                    }
                    else {
                        return null;
                    }
                });
                return parameters;
            };
        },
        getResultSelectionHead: state => {
            const head = _.cloneDeep(state.calculationResult.resultsView?.selectionHead);
            if (!head)
                return;
            for (const key of Object.keys(head)) {
                let unit = state.calculationResult.payloadOut.data.selection[0][key]?.unit;
                unit = unit ? metersToHtml(unit) : unit;
                head[key] = interpolate(head[key], { unit });
            }
            return head;
        },
        getIsValid: state => {
            return !state.isValid.find(validationStep => validationStep === false);
        },
        getResultSchemaParameters: state => {
            return Object.fromEntries(Object.entries({
                ...state.calculationResult.payloadIn.payload,
                ...state.calculationResult.payloadOut.data,
                ...state.calculationResult.payloadOut.data.proposedSolutions,
            }).map(prop => {
                return [
                    prop[0],
                    typeof prop[1] === 'object' ? prop[1]?.value : prop[1],
                ];
            }));
        },
    },
    actions: {
        setCalculatorInitConfig(config) {
            this.calculatorInitConfig = config;
            this.calculationParameters.meta.calculatorId =
                config.configCalculator.meta.calculatorId;
            this.calculationParameters.meta.calculationKey =
                config.configCalculator.meta.calculationKey;
            this.calculationParameters.meta.version =
                config.configCalculator.meta.version;
            Object.assign(this.calculationParameters.investData, Object.fromEntries(Object.entries(config.configFront.investData)
                .filter(item => item[0] !== 'coordinates' && item[0] !== 'investmentPlace')
                .map(item => {
                return [
                    item[0],
                    item[1].defaultUnit
                        ? { value: item[1].defaultValue, unit: item[1].defaultUnit }
                        : item[1].defaultValue,
                ];
            })));
            Object.assign(this.calculationParameters.payload, Object.fromEntries(Object.entries(config.configFront.payload).map(item => {
                return [
                    item[0],
                    item[1].defaultUnit
                        ? {
                            value: item[1].type === 'number' && item[1].defaultValue !== null
                                ? Number(item[1].defaultValue)
                                : item[1].defaultValue,
                            unit: item[1].defaultUnit,
                        }
                        : item[1].defaultValue,
                ];
            })));
        },
        mutateInvestData(payload) {
            this.calculationParameters.investData[payload.key] = payload.value;
        },
        mutateCalculationParameters(data) {
            this.calculationParameters.payload[data.key] = data.value;
        },
        setCalculationResult(data) {
            this.calculationResult = data;
        },
        mutateStatusValidation(payload) {
            this.isValid.splice(payload.index, 1, payload.val);
        },
        setStatus(newStatus) {
            this.status[newStatus.key] = newStatus.value;
        },
        setSchema(schema) {
            this.schema = schema;
        },
        setModalVisibility(isVisible) {
            this.showModal = isVisible;
        },
        setForm(form) {
            this.form = form;
        },
        setSolution(index) {
            this.calculationResult.payloadOut.data.proposedSolutions =
                this.calculationResult.payloadOut.data.selection[index];
        },
        async initModule() {
            const config = await getAsync({
                url: 'module/23',
                setStatus: this.setStatus,
            });
            this.setCalculatorInitConfig(config);
            return config;
        },
        async fetchCalculationResult() {
            const parameters = _.cloneDeep(this.calculationParameters);
            if (parameters.payload.tankKind === 'open') {
                parameters.payload.tankLocation = null;
                parameters.payload.supportingPolesRows = null;
                parameters.payload.polesRowsNum = null;
                parameters.payload.polesArrangement = null;
            }
            else if (!parameters.payload.supportingPolesRows) {
                parameters.payload.polesRowsNum = null;
                parameters.payload.polesArrangement = null;
            }
            if (!parameters.payload.levelSensors) {
                parameters.payload.levelSensorsType = null;
            }
            const result = await postAsync({
                url: 'module/23',
                payload: { ...parameters },
                setStatus: this.setStatus,
            });
            this.setCalculationResult(result);
        },
        async fetchHistoricalCalculation(calculationKey) {
            const result = await getAsync({
                url: `user/calculation/${calculationKey}`,
                setStatus: this.setStatus,
            });
            this.setCalculationResult(result);
        },
        async recalculateCalculation(data) {
            this.$reset;
            await this.router.push({ name: 'flush-systems.investment-data', replace: true });
            this.calculationParameters.investData = data.investData;
            this.calculationParameters.payload = data.payload;
        },
    },
});

const greenRoofsRouter = {
    path: "zielone-dachy",
    name: "green-roofs",
    redirect: { name: "green-roofs.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            name: "green-roofs.investment-data",
            meta: { partner: "delta" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            name: "green-roofs.choosing-parameters",
            meta: { partner: "delta" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-zbiornika",
            name: "green-roofs.selecting-products",
            meta: { partner: "delta" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            meta: { partner: "delta" },
            name: "green-roofs.archive",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default greenRoofsRouter;

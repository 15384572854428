import i18n from '@/i18n';
import { isValueWithUnitExtended, } from '@/interfaces/shared';
import { metersToHtml } from '@/shared/utils';
export const copyStoreOptions = (options) => {
    return { ...options };
};
export const unwrap = (wrapped) => {
    return wrapped;
};
export const getPlainParameter = (key, rawValue, resultViewValue) => {
    const baseReturnedResult = getBaseReturnedResult(key, resultViewValue);
    if (isValueWithUnitExtended(rawValue)) {
        return {
            ...baseReturnedResult,
            value: rawValue.value,
            unit: rawValue.unit ? metersToHtml(rawValue.unit) : '',
            chart: rawValue?.chart,
        };
    }
    else {
        let returnedValue = '';
        if (resultViewValue?.item) {
            if (Array.isArray(rawValue)) {
                const translatedValue = rawValue.map(rawValueItem => (rawValueItem = resultViewValue.item?.find(possibleItems => rawValueItem === possibleItems.value).label));
                returnedValue = translatedValue.join(', ');
            }
            else {
                const itemValue = resultViewValue.item?.find(possibleItems => rawValue === possibleItems.value);
                returnedValue = itemValue ? itemValue.label : rawValue;
            }
        }
        else if (typeof rawValue === 'boolean') {
            const translationKey = rawValue ? 'shared.yes' : 'shared.no';
            returnedValue = i18n.global.t(translationKey);
        }
        else {
            returnedValue = rawValue ? rawValue : '';
        }
        return {
            ...baseReturnedResult,
            value: returnedValue,
            unit: resultViewValue?.defaultUnit
                ? metersToHtml(resultViewValue.defaultUnit)
                : '',
        };
    }
};
export const getBaseReturnedResult = (key, resultViewValue) => {
    return {
        key,
        translation: resultViewValue?.label,
        img: resultViewValue?.img,
        tooltip: resultViewValue?.tooltip,
    };
};

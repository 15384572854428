;
export const interpolate = (str, obj) => str.replace(/\${([^}]+)}/g, (_, prop) => obj[prop]);
export const metersToHtml = (text) => {
    //regex for example: m1/3/s
    const match = text.match(/(m)((?:1\/)?[23])/);
    // regex for example: m^(-1/3) -> m<sup>-1/3</sup>)
    const matchInParenthesis = text.match(/(m)(?:\^\(([^)]+)\))/);
    const replaceTextFragmentToHtml = (match) => {
        return text.replace(match[0], () => {
            return match[1] + '<sup>' + match[2] + '</sup>';
        });
    };
    if (matchInParenthesis) {
        return replaceTextFragmentToHtml(matchInParenthesis);
    }
    else if (match) {
        return replaceTextFragmentToHtml(match);
    }
    return text;
};
export const replaceComma = (value, sign) => value.replace(/,/g, `${sign}`);
export const roundTo = (value, precision) => {
    return parseFloat(value.toFixed(precision));
};
export const camelToKebab = (camelText) => camelText.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
export const replaceTextPart = (value, textPart, replacemtent = '') => {
    const reg = new RegExp(textPart, 'g');
    return value.replace(reg, replacemtent);
};
export const getClosestValue = (value, pool) => {
    return pool.reduce((previous, current) => {
        const currentDistance = Math.abs(current - value);
        const previousDistance = Math.abs(previous - value);
        return currentDistance < previousDistance ? current : previous;
    });
};
export const getValueByRange = (search, list, defaultValue = 0) => {
    const foundObj = list.find(obj => search >= obj.min && search <= obj.max);
    return foundObj?.value ?? defaultValue;
};
export const parseToFloat = (value) => {
    if (value === '' || value.charAt(0) === ',' || value.charAt(0) === '.') {
        return 0;
    }
    else if (value.replace) {
        const floatValue = parseFloat(value.replace(/,/g, '.'));
        return !isNaN(floatValue) ? floatValue : value;
    }
    else {
        const floatValue = parseFloat(value);
        return !isNaN(floatValue) ? floatValue : value;
    }
};
export const parseOrdinate = (value, decPlaces = 2) => {
    if (value !== null && value !== '') {
        const parsedValue = parseToFloat(value);
        if (typeof parsedValue === 'number') {
            return parsedValue.toFixed(decPlaces);
        }
    }
    return null;
};
/**
 * Formating object for display, add html elements if unit needs it
 * @param valueToFormat object to format
 */
export const formatValueWithUnit = (valueToFormat) => {
    const unit = metersToHtml(valueToFormat.unit);
    return `${valueToFormat.value} ${unit}`;
};
/**
 * Formating object for display, if unit is defined then added after space
 * @param param object to format
 */
export const getFormattedValue = (param) => {
    const unitSufix = param.unit ? ` ${param.unit}` : '';
    return `${param.value}${unitSufix}`;
};
export const getSectionIdFromPath = (path) => {
    return path.match(/\.(\d+)\./)?.[1];
};
export const removeSectionIdFromPath = (path) => {
    const sectionId = getSectionIdFromPath(path);
    return path.replace(`.${sectionId}`, '');
};
export const addSectionIdToPath = (path, sectionId) => {
    return path.replace('.', `.${sectionId}.`);
};
/**
 * Add mime type necessary to display svg image
 * @param source raw base64
 */
export const prepareBase64SvgImageSource = (source) => {
    const svgBaseMimeType = 'data:image/svg+xml;base64,';
    return `${svgBaseMimeType}${source}`;
};
/**
 * Validation is reset only for empty inputs to not remove error messages for inputs with invalid values
 * @param form - form with components to reset
 */
export const resetValidationOnNonEmptyComponents = (form) => {
    form.getValidationComponents().forEach(formComponent => {
        if (formComponent.modelValue === null ||
            formComponent.modelValue === undefined) {
            formComponent.resetValidation();
        }
    });
};
/**
 * Transform seconds to hours, minutes and seconds
 * @param seconds time in seconds
 */
export const secondsToHoursMinutesSeconds = (seconds) => {
    const hours = Math.floor(seconds / SECONDS_IN_HOUR);
    const minutes = Math.floor((seconds - hours * SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);
    const restSeconds = seconds - hours * SECONDS_IN_HOUR - minutes * SECONDS_IN_MINUTE;
    return {
        hours,
        minutes,
        seconds: restSeconds,
    };
};
/**
 * Simple wrapper to simulate delay.
 * Useful when mock data from api and want to show some loaders.
 * @param miliseconds how long to wait before the promise is resolved
 */
export const sleep = (miliseconds) => {
    return new Promise(resolve => setTimeout(resolve, miliseconds));
};
/**
 * Validation for type number, need parsed input text. This function still may return string
 * if input text is not valid number.
 * @param value input text to parse
 */
export const getParsedNumberForValidation = (value) => {
    if (typeof value === 'number') {
        return value;
    }
    return parseToFloat(value);
};
export const getNestedElement = (object, nesting
// eslint-disable-next-line @typescript-eslint/no-explicit-any
) => {
    return nesting.split('.').reduce((acc, curr) => {
        return acc !== null &&
            curr !== null &&
            typeof acc === 'object' &&
            Object.prototype.hasOwnProperty.call(acc, curr)
            ? acc[curr]
            : undefined;
    }, object);
};
export const calculateWaterAnimation = (element) => {
    const y = Number(element.getAttribute('y'));
    const height = Number(element.getAttribute('height'));
    return {
        firstAnimateFrom: 0,
        firstAnimateTo: height,
        secondAnimateFrom: y + height,
        secondAnimateTo: y,
    };
};
export const separateThousandBySpace = (value) => {
    return typeof value === 'number'
        ? String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        : value;
};
/**
 * Round value to decimalPlaces, if decimalPlaces not specified, round to 6
 * @param value - number to be rounded
 * @param decimalPlaces - number of decimal places for rounding value
 */
export const parseErrorMessageValue = (value, decimalPlaces = 6) => {
    const roundingValue = Math.pow(10, decimalPlaces);
    return Math.round(value * roundingValue) / roundingValue;
};

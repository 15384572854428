const pretreatmentSystemsRouter = {
    path: "uklady-podczyszczajace",
    name: "pretreatment-systems",
    redirect: { name: "pretreatment-systems.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            meta: { partner: "ecol_unicon-separatory" },
            name: "pretreatment-systems.investment-data",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            name: "pretreatment-systems.choosing-parameters",
            meta: { partner: "ecol_unicon-separatory" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-zbiornika",
            name: "pretreatment-systems.selecting-products",
            meta: { partner: "ecol_unicon-separatory" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            name: "pretreatment-systems.archive",
            meta: { partner: "ecol_unicon-separatory" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default pretreatmentSystemsRouter;

import { defineStore } from 'pinia';
import { CommunicationCulvertVariant, CommunicationCulvertShape, CommunicationCulvertInstallationMethod, CommunicationCulvertSelectionType, } from '@25/interfaces/communicationCulverts';
import { cloneDeep, groupBy, merge, orderBy, partition } from 'lodash';
import { useConfigFrontDependencies } from '@/components/main/calculators/25_communicationCulverts/composables/useConfigFrontDependencies';
import { isDefined, } from '@/interfaces/shared';
import { getNestedElement, interpolate, metersToHtml, separateThousandBySpace, } from '@/shared/utils';
import { api } from '@/axios/axios';
import i18n from '@/i18n';
import { getAsync, postAsync, } from '@/components/main/calculators/25_communicationCulverts/utils/asyncAxiosUtil';
import { FILTER_ITEM_BOTH, getDefaultFiltersValues, } from '@/components/main/calculators/25_communicationCulverts/constant';
import { dimensionValueSplitter } from '@25/utils/filterUtils';
import { isValueWithUnit } from '@/interfaces/shared/calculators';
import { useFlowCalculationStore } from '@/components/shared/flowCalculation/store/flowCalculationStore';
export const useCommunicationCulvertsStore = defineStore('communication-culverts', {
    state: () => ({
        VERSION: '1.0.0',
        isValid: [false, false, false],
        calculatorInitConfig: {},
        calculatorCurrentConfigFrontPayload: {},
        calculationParameters: {
            meta: {},
            investData: {},
            payload: {},
        },
        calculationResult: {
            favorite: false,
        },
        status: {
            error: {
                value: false,
                message: '',
            },
            success: false,
            pending: false,
        },
        form: {},
        showModal: false,
        eggSchema: {},
        pipelineSchema: {},
        nameStore: 'communicationCulverts',
        calculatorId: 25,
        resultFilters: getDefaultFiltersValues(),
        isInitialized: false,
        seriesItems: [],
        isFlowFromCalculation: false,
    }),
    persist: {
        storage: sessionStorage,
        afterRestore: ctx => {
            ctx.store.$state.showModal = false;
            ctx.store.$state.form = null;
        },
    },
    getters: {
        getDependenciesEntries(state) {
            const configDependencies = state.calculatorInitConfig.configFrontDependencies;
            if (!configDependencies) {
                return [];
            }
            const getDependencyTriggerInputs = (rules) => {
                return rules.flatMap(rule => Object.keys(rule.changed));
            };
            return Object.entries(configDependencies).map(([inputToSet, rules]) => {
                return [inputToSet, getDependencyTriggerInputs(rules)];
            });
        },
        getDependenciesForTrigger() {
            return (possibleTriggerInput) => {
                const connectedDependencies = this.getDependenciesEntries.filter(([, triggerInputs]) => triggerInputs.includes(possibleTriggerInput));
                return connectedDependencies.map(([inputToSet]) => inputToSet);
            };
        },
        getSharedResults: state => state.calculationResult?.payloadOut?.data?.sharedResults,
        getResultSharedParam: state => {
            return (key) => {
                const payloadIn = cloneDeep(state.calculationResult.payloadIn?.payload);
                const sharedResults = cloneDeep(state.calculationResult.payloadOut?.data.sharedResults);
                const data = merge(payloadIn, sharedResults);
                const resultsView = state.calculationResult.resultsView;
                const rawValue = data[key];
                if (!isDefined(rawValue) ||
                    (isValueWithUnit(rawValue) && !isDefined(rawValue.value))) {
                    return null;
                }
                if (typeof data[key] === 'object' &&
                    Object.prototype.hasOwnProperty.call(data[key], 'value') &&
                    Object.prototype.hasOwnProperty.call(data[key], 'unit')) {
                    return {
                        translation: resultsView[key]?.label,
                        value: separateThousandBySpace(data[key]?.value),
                        unit: metersToHtml(data[key]?.unit),
                        chart: data[key]?.chart,
                        img: resultsView[key]?.img,
                        tooltip: resultsView[key]?.tooltip,
                    };
                }
                else {
                    let returnedValue = '';
                    switch (typeof data[key]) {
                        case 'boolean':
                            data[key]
                                ? (returnedValue = i18n.global.t('shared.yes'))
                                : (returnedValue = i18n.global.t('shared.no'));
                            break;
                        case 'string':
                            {
                                const itemValue = resultsView[key]?.item?.find(possibleItems => data[key] === possibleItems.value);
                                returnedValue = itemValue ? itemValue.label : data[key];
                            }
                            break;
                        default:
                            returnedValue = data[key]
                                ? separateThousandBySpace(data[key])
                                : '';
                            break;
                    }
                    return {
                        translation: resultsView[key]?.label,
                        value: returnedValue,
                        unit: resultsView[key]?.defaultUnit
                            ? metersToHtml(resultsView[key]?.defaultUnit)
                            : '',
                        img: resultsView[key]?.img,
                        tooltip: resultsView[key]?.tooltip,
                    };
                }
            };
        },
        getResultSolutionSeriesParam: state => {
            return (key, productName) => {
                const series = cloneDeep(state.calculationResult.payloadOut?.data.selection[0]
                    .proposedSolution[productName]?.characteristic);
                const resultsView = state.calculationResult.resultsView;
                if (series[key] === null || series[key] === undefined) {
                    return null;
                }
                if (typeof series[key] === 'object' &&
                    Object.prototype.hasOwnProperty.call(series[key], 'value') &&
                    Object.prototype.hasOwnProperty.call(series[key], 'unit')) {
                    return {
                        translation: resultsView?.[key]?.label ||
                            resultsView?.solutionsHead?.[key]?.label,
                        value: series[key]?.value,
                        unit: metersToHtml(series[key]?.unit),
                    };
                }
                else {
                    return {
                        translation: resultsView?.[key]?.label ||
                            resultsView?.solutionsHead?.[key]?.label,
                        value: series[key],
                        unit: '',
                    };
                }
            };
        },
        getResultSolutionCalculatedParam: state => {
            return (keyChain, productName) => {
                const calculated = cloneDeep(state.calculationResult.payloadOut?.data.selection[0]
                    .proposedSolution[productName]?.calculated);
                const resultsView = state.calculationResult.resultsView;
                const nestedElement = getNestedElement(calculated, keyChain);
                if (!nestedElement) {
                    return null;
                }
                if (typeof nestedElement === 'object' &&
                    Object.prototype.hasOwnProperty.call(nestedElement, 'value') &&
                    Object.prototype.hasOwnProperty.call(nestedElement, 'unit')) {
                    return {
                        translation: getNestedElement(resultsView, keyChain)?.label,
                        value: separateThousandBySpace(nestedElement.value),
                        unit: metersToHtml(nestedElement.unit),
                        tooltip: resultsView[keyChain]?.tooltip,
                    };
                }
                else {
                    return {
                        translation: getNestedElement(resultsView, keyChain)?.label,
                        value: separateThousandBySpace(nestedElement),
                        unit: '',
                        tooltip: resultsView[keyChain]?.tooltip,
                    };
                }
            };
        },
        getResultParameters() {
            return (neededValues) => {
                return neededValues.map(neededValue => {
                    if (neededValue.productName) {
                        return (this.getResultSolutionCalculatedParam(neededValue.value, neededValue.productName) ||
                            this.getResultSolutionSeriesParam(neededValue.value, neededValue.productName));
                    }
                    else {
                        return this.getResultSharedParam(neededValue.value);
                    }
                });
            };
        },
        getParametersForCalculationData() {
            return (neededValues) => {
                const parameters = this.getResultParameters(neededValues);
                parameters.forEach((parameter, index) => {
                    if (parameter) {
                        parameter.marginTop = neededValues[index].marginTop;
                    }
                });
                return parameters;
            };
        },
        allSolutions: state => {
            const solutions = state.calculationResult.payloadOut?.data.selection[0].solutions;
            return solutions?.map(solution => {
                const [dimensionExternalWidth, dimensionExternalHeight] = dimensionValueSplitter(solution.culvert.characteristic.dimensionExternal.value);
                const [dimensionInternalWidth, dimensionInternalHeight] = dimensionValueSplitter(solution.culvert.characteristic.dimensionInternal.value);
                return {
                    ...solution,
                    dimensionExternalWidth,
                    dimensionExternalHeight,
                    dimensionInternalWidth,
                    dimensionInternalHeight,
                };
            });
        },
        filteredSolutions() {
            if (!this.allSolutions) {
                return [];
            }
            return this.allSolutions.filter(solution => {
                if (this.resultFilters.shape !== FILTER_ITEM_BOTH &&
                    solution.culvert.characteristic.shape !== this.resultFilters.shape) {
                    return false;
                }
                if (this.resultFilters.variant !== FILTER_ITEM_BOTH &&
                    solution.culvert.calculated.variant !== this.resultFilters.variant) {
                    return false;
                }
                if (solution.dimensionExternalHeight > this.resultFilters.DEHeight) {
                    return false;
                }
                if (solution.dimensionExternalWidth > this.resultFilters.DEWidth) {
                    return false;
                }
                if ((solution.culvert.calculated.fillDepthPct.value ?? 0) >
                    this.resultFilters.fillDepthPct) {
                    return false;
                }
                return true;
            });
        },
        /**
         * *depth - transformed to mm - same unit as DI
         * *with reserve - depth <= 0.75 * internal height
         * 1. Promoted - notsubmerged, nonCircular, with reserve, first 3 - internal width ascending
         * 2. rest of notsubmerged with reserve - internal width ascending
         * 3. rest of notsubmerged - internal width descending
         * 4. submerged - internal width descending
         */
        sortedSolutions() {
            if (!this.filteredSolutions) {
                return [];
            }
            const solutionsByVariant = groupBy(this.filteredSolutions, solution => solution.culvert.calculated.variant);
            const { [CommunicationCulvertVariant.Notsubmerged]: notsubmerged, [CommunicationCulvertVariant.Submerged]: submerged, } = solutionsByVariant;
            const [notsubmergedWithReserve, notsubmergedWithoutReserve] = partition(notsubmerged ?? [], solution => {
                const { fillDepth } = solution.culvert.calculated;
                const milimentersDepth = fillDepth.unit === 'm'
                    ? (fillDepth.value ?? 0) * 1000
                    : fillDepth.value;
                return ((milimentersDepth ?? 0) <= solution.dimensionInternalHeight * 0.75);
            });
            const diameterSort = (solution) => solution.dimensionInternalWidth;
            const notsubmergedWithReserveSorted = orderBy(notsubmergedWithReserve ?? [], diameterSort, 'asc');
            const notsubmergedWithReserveNonCircular = notsubmergedWithReserveSorted.filter(solution => solution.culvert.characteristic.shape ===
                CommunicationCulvertShape.NonCircular);
            const promoted = (notsubmergedWithReserveNonCircular ?? []).slice(0, 3);
            const promotedUUIDs = promoted.map(solution => solution.solutionUUID);
            const notsubmergedWithReserveRearranged = [
                ...promoted,
                ...notsubmergedWithReserveSorted.filter(solution => !promotedUUIDs.includes(solution.solutionUUID)),
            ];
            const notsubmergedWithoutReserveSorted = orderBy(notsubmergedWithoutReserve ?? [], diameterSort, 'desc');
            const submergedSorted = orderBy(submerged ?? [], diameterSort, 'desc');
            return [
                ...notsubmergedWithReserveRearranged,
                ...notsubmergedWithoutReserveSorted,
                ...submergedSorted,
            ];
        },
        getSolutions() {
            return (productName) => {
                if (!this.sortedSolutions) {
                    return [];
                }
                return this.sortedSolutions.map(product => ({
                    ...product[productName].characteristic,
                    ...product[productName].calculated,
                    solutionUUID: product.solutionUUID,
                }));
            };
        },
        getResultSolutionsHead: (state) => {
            const head = cloneDeep(state.calculationResult.resultsView?.solutionsHead);
            if (!head)
                return;
            for (const key of Object.keys(head)) {
                let unit = state.calculationResult.payloadOut?.data.selection[0].solutions[0][key]?.unit;
                unit = unit ? metersToHtml(unit) : unit;
                head[key].label = interpolate(head[key].label, { unit });
            }
            return head;
        },
        getIsValid: state => {
            return !state.isValid.find(validationStep => validationStep === false);
        },
        getProperConfig: state => {
            return (key) => {
                if (state.calculatorCurrentConfigFrontPayload[key]) {
                    return state.calculatorCurrentConfigFrontPayload[key];
                }
                else {
                    return state.calculatorInitConfig.configFront.payload[key];
                }
            };
        },
        selectionType: state => state.calculationParameters.payload?.selectionType,
        isFullSelectionType() {
            return this.selectionType === CommunicationCulvertSelectionType.Full;
        },
    },
    actions: {
        mutateInvestData(payload) {
            this.calculationParameters.investData[payload.key] = payload.value;
        },
        mutateCalculationParameters(data) {
            this.calculationParameters.payload[data.key] = data.value;
        },
        mutateStatusValidation(payload) {
            this.isValid.splice(payload.index, 1, payload.val);
        },
        checkCurrentConfig(key) {
            if (!this.calculatorCurrentConfigFrontPayload[key]) {
                this.setCurrentConfig(key);
            }
        },
        mutateParameterVisibility(data) {
            this.checkCurrentConfig(data.key);
            this.calculatorCurrentConfigFrontPayload[data.key].visible = data.value;
        },
        mutateParameterDisability(data) {
            this.checkCurrentConfig(data.key);
            this.calculatorCurrentConfigFrontPayload[data.key].disable = data.value;
        },
        mutateParameterReadonly(data) {
            this.checkCurrentConfig(data.key);
            this.calculatorCurrentConfigFrontPayload[data.key].readonly =
                data.value;
        },
        mutateParameterItems(data) {
            this.checkCurrentConfig(data.key);
            this.calculatorCurrentConfigFrontPayload[data.key].items =
                this.calculatorInitConfig.configFront.payload[data.key].items.filter((item) => data.newItemsValues?.includes(item.value));
            this.setDefaultIfNotInItems(data.key);
        },
        // --- copied from wf2 with small changes
        setDefaultIfNotInItems(fieldKey) {
            const fieldConfig = this.calculatorCurrentConfigFrontPayload[fieldKey];
            const fieldValue = this.calculationParameters.payload[fieldKey];
            // setting default item only for fields with array of items
            if (!fieldConfig || !Array.isArray(fieldConfig.items)) {
                return;
            }
            const isValueInItems = fieldConfig.items.some(item => {
                if (isValueWithUnit(fieldValue)) {
                    return item.value === fieldValue.value;
                }
                else {
                    return item.value === fieldValue;
                }
            });
            if (!isValueInItems) {
                this.resetParameterValue(fieldKey);
            }
        },
        resetParameterValue(fieldKey) {
            const fieldInitConfig = this.calculatorInitConfig.configFront.payload[fieldKey];
            const newValue = this.getProperDefaultValue(fieldInitConfig);
            this.mutateCalculationParameters({
                key: fieldKey,
                value: newValue,
            });
        },
        getProperDefaultValue(initConfig) {
            const { type, defaultValue, defaultUnit } = initConfig;
            // check type to be consistent with FormComponent
            if (type === 'number' && defaultUnit) {
                return {
                    value: defaultValue,
                    unit: defaultUnit,
                };
            }
            return defaultValue ?? null;
        },
        // ---
        setCurrentConfig(key) {
            this.calculatorCurrentConfigFrontPayload[key] = cloneDeep(this.calculatorInitConfig.configFront.payload[key]);
        },
        setCalculatorConfigs(config) {
            this.calculatorInitConfig = config;
            this.calculatorCurrentConfigFrontPayload = cloneDeep(config.configFront.payload);
            this.calculationParameters.meta.calculatorId =
                config.configCalculator.meta.calculatorId;
            this.calculationParameters.meta.calculationKey =
                config.configCalculator.meta.calculationKey;
            this.calculationParameters.meta.version =
                config.configCalculator.meta.version;
            const investData = JSON.parse(JSON.stringify(config.configFront.investData));
            delete investData.coordinates;
            delete investData.investmentPlace;
            for (const [key, value] of Object.entries(investData)) {
                investData[key] = value.defaultValue;
            }
            this.calculationParameters.investData = {
                ...this.calculationParameters.investData,
                ...investData,
            };
            const payload = JSON.parse(JSON.stringify(config.configFront.payload));
            const setInitPayload = (payload) => {
                for (const [prop, value] of Object.entries(payload)) {
                    const key = prop;
                    if (payload[key].defaultValue === undefined) {
                        if (payload[key].type === 'objectarray') {
                            payload[key] = [payload[key].items];
                            setInitPayload(payload[key][0]);
                        }
                        else if (payload[key].type === 'object') {
                            payload[key] = payload[key].items;
                            setInitPayload(payload[key]);
                        }
                    }
                    else {
                        payload[key] =
                            value.defaultUnit && value.componentName !== 'select'
                                ? { value: value.defaultValue, unit: value.defaultUnit }
                                : value.defaultValue;
                    }
                }
            };
            setInitPayload(payload);
            const setInitConfig = (payload) => {
                for (const [prop, value] of Object.entries(payload)) {
                    payload[prop] = null;
                }
            };
            setInitConfig(this.calculatorCurrentConfigFrontPayload);
            this.calculationParameters.payload = {
                ...this.calculationParameters.payload,
                ...payload,
            };
            this.calculationParameters.payload.flowCalculationData = null;
            // after value initialization - set temporary initial value
            this.calculationParameters.payload.installationMethod =
                CommunicationCulvertInstallationMethod.OpenTrench;
        },
        setCalculationResult(data) {
            this.calculationResult = data;
        },
        setStatus(newStatus) {
            this.status[newStatus.key] = newStatus.value;
        },
        setModalVisibility(isVisible) {
            this.showModal = isVisible;
        },
        setForm(form) {
            this.form = form;
        },
        setSolution(newSolution) {
            api.post('updateProposedSolution', {
                calculationKey: this.calculationParameters.meta.calculationKey,
                selectionIndexToUpdate: 0,
                solutionUuidToUpdate: newSolution.solutionUUID,
            });
            this.calculationResult.payloadOut.data.selection[0].proposedSolution =
                newSolution;
        },
        async initModule() {
            const config = await getAsync({
                url: `module/${this.calculatorId}`,
                setStatus: this.setStatus,
            });
            this.setCalculatorConfigs(config);
            this.isInitialized = true;
            const flowCalculationsStore = useFlowCalculationStore();
            flowCalculationsStore.initModule();
            return config;
        },
        async fetchCalculationResult() {
            const parameters = this.calculationParameters;
            this.changeInvisibleToNull();
            var result = await postAsync({
                url: `module/${this.calculatorId}`,
                payload: { ...parameters },
                setStatus: this.setStatus,
            });
            this.setCalculationResult(result);
        },
        async fetchHistoricalCalculation(calculationKey) {
            const result = await getAsync({
                url: `user/calculation/${calculationKey}`,
                setStatus: this.setStatus,
            });
            this.setCalculationResult(result);
        },
        async recalculateCalculation(data) {
            const { setInitialDependencies } = useConfigFrontDependencies(this);
            this.$reset;
            await this.router.push({
                name: `communication-culverts.investment-data`,
                replace: true,
            });
            // merge for historical calculations which doesn't have some parameters and shouldn't override them
            merge(this.calculationParameters, {
                investData: data.investData,
                payload: data.payload,
            });
            setInitialDependencies();
            const flowCalculationsStore = useFlowCalculationStore();
            merge(flowCalculationsStore.calculationParameters, {
                investData: data.investData,
                payload: data.payload.flowCalculationData,
            });
        },
        setPipelineSchema(schema) {
            this.pipelineSchema = schema;
        },
        setEggSchema(schema) {
            this.eggSchema = schema;
        },
        changeInvisibleToNull() {
            const parameters = this.calculationParameters.payload;
            for (let [key, _param] of Object.entries(parameters)) {
                const currentKeyConfig = this.getProperConfig(key);
                if (currentKeyConfig && !currentKeyConfig.visible) {
                    parameters[key] = null;
                }
            }
        },
        async fetchSeriesItems() {
            try {
                const response = await api.get(`calculator/${this.calculatorId}/series`);
                this.seriesItems = response.data.data.series;
            }
            catch (error) {
                console.error(error);
            }
        },
        async checkSeriesItems() {
            if (!this.seriesItems.length) {
                await this.fetchSeriesItems();
            }
        },
        updateFlowCalculationData() {
            const flowCalculationsStore = useFlowCalculationStore();
            this.calculationParameters.payload.flowCalculationData = cloneDeep(flowCalculationsStore.calculationParameters.payload);
            this.isFlowFromCalculation = true;
        },
        resetFlowCalculationData() {
            this.calculationParameters.payload.flowCalculationData = null;
            this.isFlowFromCalculation = false;
        },
        resetConnectedStores() {
            const flowCalculationsStore = useFlowCalculationStore();
            flowCalculationsStore.$reset();
        },
    },
});

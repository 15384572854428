export default {
    shared: {
        yes: 'Yes',
        no: 'No',
        signOut: 'Sign out',
        newChoice: 'New calculation',
        yourChoices: 'Your choices',
        settings: 'Settings',
        administration: 'Administration',
        platform: 'PLATFORM',
        termsAndConditions: 'Terms and Conditions',
        letsTalk: "LET'S TALK",
        privacyPolicy: 'Privacy Policy',
        phoneNumber: '+ 48 730 037 309',
        companyName: 'RetencjaPL Sp z o.o.',
        total: 'Sum',
        calculationResults: 'Calculation results',
        showAll: 'Pokaż pozostałe',
        chosenProduct: 'Wybrany',
        productsNotFound: 'Nie znaleziono produktów spełniających kryteria',
        addToFavorites: 'Dodaj do ulubionych',
        addedToFavorites: 'Dodano do ulubionych',
        calculationInProgress: 'Calculation in progress',
        back: 'Back',
        needHelp: 'I need help',
        selectionAttemptFailed: 'Selection attempt failed',
        selectionAttemptFailedMessage: 'It was not possible to select devices for the given parameters. Please try to use other inputs or contact us for help',
        dataHasBeenChanged: 'Data has been changed',
        updateData: 'Update data',
        exportFilesDisabled: {
            title: 'File export disabled',
            description: 'File export is no longer active, use the new version of the Waterfolder platform',
        },
        files: {
            downloadFiles: 'Download files',
            sendFiles: 'Send files',
            pdf: 'PDF file',
            zip: 'ZIP batch',
            xls: 'XLS file',
            xlsx: 'xlsx',
        },
        agreementModal: {
            title: 'Send files to my e-mail',
            content: 'Please send the detailed results of your calculations and {partner} initial product proposal to my email address. <br><br> Personal data will be processed by {partner} in accordance with the ',
            privacyPolicy: 'Data processing Policy',
            button1: 'Send files',
            button2: 'Send e-mail',
            mailAgreement: 'I agree to receive at my e-mail address from {partner} commercial information such as products and services offered, training and other events, as well as promotional and marketing actions',
            info: {
                part1: 'You can change the above consent at any time in the',
                part2: 'Privacy tab',
                part3: 'in your Account settings, without affecting the legality of the processing, which was performed on the basis of consent before its withdrawal.',
            },
        },
        errorMessages: {
            required: 'Pole nie może być puste.',
            min: 'Wartość musi być wyższa niż {min}.',
            max: 'Wartość musi być niższa od {max}.',
            range: 'Wartość powinna być z zakresu {range}.',
        },
        sendQuestion: 'Send a question',
        contactForm: {
            title: 'Ask a question about your choice',
            desc: 'When choosing customised devices please contact the manufacturer using the form below before contacting us for answers to your questions.',
            subject: 'Message subject',
            message: 'Message content',
            messagePlaceholder: 'Please include a detailed question, problem description',
            details: 'The characteristics of a chosen device will be automatically added to the message sent using this form.',
            thanks: 'Thank you very much for your inquiry!',
            msgSent: 'Your message has been sent.',
            msgContent: "Your message has been forwarded directly to<span>{partner}</span>. Your selection data has been automatically added to your inquiry. You will receive an answer from the manufacturer's representative as soon as possible!",
            back: 'Back to calculator',
        },
        profileData: {
            title: 'User account',
            intro: 'Please enter your details below so that we can prepare a specific offer for you or contact you.',
            question: "Don't want to fill in your data now?",
            skip: 'Skip this step',
            button: 'Save and start working',
        },
        required: 'The field is required',
        errorOccured: 'Wystąpił błąd',
        loading: 'Loading',
        infoAboutCompany: 'Information about the company',
        personalDetails: {
            title: 'Personal details',
            name: 'Name nad surname',
            email: 'E-mail address',
            phone: 'Phone',
            companyName: 'Company name',
            taxNumber: 'Tax identification number',
            streetAndNo: 'Street and number',
            postCode: 'Post code',
            city: 'City',
            country: 'Country',
            incorrectPostCode: 'Incorrect post code',
        },
        countryList: {
            Austria: 'Austria',
            Belgium: 'Belgium',
            Bulgaria: 'Bulgaria',
            Cyprus: 'Cyprus',
            CzechRepublic: 'Czech Republic',
            Germany: 'Germany',
            Denmark: 'Denmark',
            Estonia: 'Estonia',
            Greece: 'Greece',
            Spain: 'Spain',
            Finland: 'Finland',
            France: 'France',
            Croatia: 'Croatia',
            Hungary: 'Hungary',
            Ireland: 'Ireland',
            Italy: 'Italy',
            Lithuania: 'Lithuania',
            Latvia: 'Latvia',
            Malta: 'Malta',
            Netherlands: 'The Netherlands',
            Poland: 'Poland',
            Portugal: 'Portugal',
            Romania: 'Romania',
            Sweden: 'Sweden',
            Slovenia: 'Slovenia',
            Slovakia: 'Slovakia',
            NorthernIreland: 'Northern Ireland',
        },
    },
    expansionPanel: {
        title: 'Calculations parameters entered',
    },
    agreementInTouch: {
        title: "Let's be in touch!",
        intro: 'In order to be able to prepare offers of selected products especially for you and to be able to inform you about new products, we ask for your consent to contact you.',
        mailAgreement: 'I agree to receive at my e-mail address from {partner} commercial information such as products and services offered, training and other events, as well as promotional and marketing actions',
        phoneAgreement: 'I agree to give my phone number to {partner} in order for {partner} to contact me (by phone and text message) to provide me with commercial information, e.g. regarding its activities, offered products, trainings and promotional and marketing actions organized by it.',
        info: {
            part1: 'You can change the above consent at any time in the',
            part2: 'Privacy tab',
            part3: 'in your Account settings, without affecting the legality of the processing, which was performed on the basis of consent before its withdrawal.',
        },
        button: 'Save and start working',
    },
    footer: {
        partnerOfSolution: 'Partner of the solution',
    },
    stepper: {
        back: 'Back',
        recalc: 'Recalc',
        archivalSelection: 'Archival selection',
        newChoice: 'New calculation',
        calculate: 'Calculate',
        next: 'Next',
    },
    linearDrainageSystems: {
        intro: {
            title: 'Title',
            desc: 'Desc',
        },
        choosingParameters: 'choosingParameters',
        table: {
            title: 'Reduced area',
            clear: 'Clear',
        },
        reducedAreaTable: {
            area: 'area',
            areaType: 'type',
            reducedArea: 'reducedArea',
            runoffCoefficient: 'runoffCoefficient',
        },
    },
    flushSystems: {
        intro: {
            title: 'Title',
            desc: 'Desc',
        },
        choosingParameters: 'choosingParameters',
        schemaTitle: 'schemaTitle',
        modalTitle: 'modalTitle',
        systemControl: '',
        additionalParameters: '',
        legend: {
            firstRow: [
                '<b>H</b> [m] - wysokość zbiornika retencyjnego',
                '<b>L</b> [m] - długość zbiornika retencyjnego',
                '<b>W</b> [m] - szerokość zbiornika retencyjnego',
                '<b>V</b> [m<sup>2</sup>] - objętość zbiornika retencyjnego',
                '<b>H<sub>c1</sub>, H<sub>c2</sub></b> [cm] - wysokość siatki oddzielającej tory spłukiwane',
                '<b>B<sub>ga</sub></b> [mm] - długość wrota komry spiętrzającej',
            ],
            secondRow: [
                '<b>L<sub>T</sub></b> [m] - długość toru spłukiwanego w zbiorniku',
                '<b>B<sub>str</sub></b> [m] - szerokość toru spłukiwanego w zbiorniku',
                '<b>L<sub>c</sub></b> [m] - długość komory spiętrzającej',
                '<b>T<sub>c</sub></b> [m] - wysokość siatki odzielającej komorę spiętrzającą',
                '<b>V<sub>k</sub></b> [m<sup>3</sup>] - objętość komory spiętrzającej w zbiorniku',
                '<b>V<sub>o</sub></b> [m<sup>3</sup>] - objętość komory odpływowej w zbiorniku',
            ],
        },
        result: {
            title: 'Parametry zbiornika',
            contactInfo: {
                normalPart: '* W przypadku doborów niestandardowych zaleca się',
                bluePart: 'kontakt z firmą Mevapol',
            },
            seriesDetails: {
                title: 'System spłukiwania zbiornika',
                buttonText: 'Zobacz schemat systemu',
            },
            tableSeries: {
                title: 'Zestawienie dobranych systemów spłukiwania',
                head: {
                    lp: 'Lp.',
                    flushTrackLength: 'Długość toru spłukiwanego [${unit}]',
                    storageChamberCapacity: 'Objętość komory spiętrzającej i odpływowej [${unit}]',
                    storageChamberLength: 'Długość komory spiętrzającej [${unit}]',
                    storageChamberHeight: 'Wysokość komory spiętrzającej [${unit}]',
                    chosenItem: 'https://waterfolder-assets.s3.eu-north-1.amazonaws.com/polityka-prywatnosci/polityka-prywatnosci-meva-pol.pdf',
                },
            },
        },
        form: {
            privacyPolicyLink: 'https://waterfolder-assets.s3.eu-north-1.amazonaws.com/polityka-prywatnosci/polityka-prywatnosci-meva-pol.pdf',
        },
    },
    investmentData: {
        title: 'investmentdata title in eng',
        description: 'investmentdata description in eng',
    },
};

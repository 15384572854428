// import { useRouter } from 'vue-router';
// import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
// import { useLinearDrainageSystemsStore } from '@/stores/calculators/3-linear-drainage-systems';
// const linearDrainageSystems = {
//   path: 'linear-drainage-systems',
//   name: 'linear-drainage-systems',
//   redirect: { name: 'linear-drainage-systems.investment-data' },
//   component: () =>
//     import('@/pages/linearDrainageSystems/LinearDrainageSystems.vue'),
//   //     beforeEnter: async(_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) =>{
//   //         next();
//   //       },
//   beforeEnter: async (
//     _to: RouteLocationNormalized,
//     _from: RouteLocationNormalized,
//     next: NavigationGuardNext
//   ) => {
//     const linearDrainageSystems = useLinearDrainageSystemsStore();
//     try {
//       //     if(typeof store.state['LinearDrainageSystemModule'] === 'undefined') {
//       //         store.registerModule('LinearDrainageSystemModule', LinearDrainageSystemModule);
//       //     }
//       //     await store.dispatch('LinearDrainageSystemModule/initialiseLinearDrainageSystemsStoreFromStorage');
//       //     const response = await store.dispatch("LinearDrainageSystemModule/initModule");
//       //     response.data ? next({name: "dashboard"}) : next();
//       const response = await linearDrainageSystems.initModule();
//       response ? next() : next({ name: 'dashboard' });
//     } catch (e) {
//       next({
//         name: 'dashboard', // back to safety route //
//       });
//     }
//   },
//   children: [
//     {
//       path: 'investment-data',
//       name: 'linear-drainage-systems.investment-data',
//       meta: { partner: 'hauraton' },
//       beforeEnter(
//         _to: RouteLocationNormalized,
//         _from: RouteLocationNormalized,
//         next: NavigationGuardNext
//       ) {
//         next();
//       },
//       components: {
//         left: () =>
//           import(
//             '@/components/main/calculators/3_linear_drainage_systems/investment-data/InvestmentData.vue'
//           ),
//         right: () =>
//           import(
//             '@/components/main/calculators/3_linear_drainage_systems/investment-data/InvestmentMap.vue'
//           ),
//       },
//     },
//     {
//       path: 'choosing-parameters',
//       name: 'linear-drainage-systems.choosing-parameters',
//       meta: { partner: 'hauraton' },
//       components: {
//         left: () =>
//           import(
//             '@/components/main/calculators/3_linear_drainage_systems/choosing-parameters/ChoosingParametersForm.vue'
//           ),
//         right: () =>
//           import(
//             '@/components/main/calculators/3_linear_drainage_systems/choosing-parameters/ChoosingParametersTable.vue'
//           ),
//       },
//       beforeEnter: (
//         _to: RouteLocationNormalized,
//         _from: RouteLocationNormalized,
//         next: NavigationGuardNext
//       ) => {
//         const linearDrainageSystems = useLinearDrainageSystemsStore();
//         const router = useRouter();
//         if (linearDrainageSystems.isValid[0]) {
//           next();
//         } else {
//           router.push({ name: 'linear-drainage-systems.investment-data' });
//         }
//       },
//     },
//     {
//       path: 'wybor-zbiornika',
//       name: 'linear-drainage-systems.selecting-products',
//       meta: { partner: 'hauraton' },
//       components: {
//         // left: () =>
//         //   import(
//         //     '@/components/main/calculators/3_linear_drainage_systems/selecting-products/CalcResult.vue'
//         //   ),
//         // right: () =>
//         //   import(
//         //     '@/components/main/calculators/3_linear_drainage_systems/selecting-products/SelectingProducts.vue'
//         //   ),
//       },
//       beforeEnter: (
//         _to: RouteLocationNormalized,
//         _from: RouteLocationNormalized,
//         next: NavigationGuardNext
//       ) => {
//         const linearDrainageSystems = useLinearDrainageSystemsStore();
//         const router = useRouter();
//         if (_from.name === 'history') {
//           next();
//         } else {
//           if (
//             linearDrainageSystems.isValid[0] &&
//             linearDrainageSystems.isValid[1]
//           ) {
//             next();
//           } else {
//             router.push({ name: 'linear-drainage-systems.investment-data' });
//           }
//         }
//       },
//     },
//     // {
//     //     path: 'dobor-archiwalny/:calculationKey',
//     //     name: "linear-drainage-systems.archive",
//     //     meta: { partner: 'hauraton' },
//     //     components: {
//     //         left: () => import("@/app/pages/linear-drainage-systems/pages/archive/results.vue"),
//     //         right: () => import("@/app/pages/linear-drainage-systems/pages/archive/parameters.vue"),
//     //     },
//     // }
//   ],
// };
// export default linearDrainageSystems;
const linearDrainageSystemsRouter = {
    path: "odwodnienia-liniowe",
    name: "linear-drainage-systems",
    redirect: { name: "linear-drainage-systems.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            name: "linear-drainage-systems.investment-data",
            meta: { partner: "hauraton" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            name: "linear-drainage-systems.choosing-parameters",
            meta: { partner: "hauraton" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-zbiornika",
            name: "linear-drainage-systems.selecting-products",
            meta: { partner: "hauraton" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            name: "linear-drainage-systems.archive",
            meta: { partner: "hauraton" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default linearDrainageSystemsRouter;

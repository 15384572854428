export const getCalculatorBeforeEnterGuard = (storeGetter) => {
    return async (_to, _from, next) => {
        const calculatorStore = storeGetter();
        if (calculatorStore.isInitialized) {
            next();
            return;
        }
        try {
            await calculatorStore.initModule();
            next();
        }
        catch (e) {
            console.error(e);
            next({
                name: 'dashboard', // back to safety route //
            });
        }
    };
};

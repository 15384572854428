import { isDefined, isValueWithUnit, isValueWithUnitExtended, } from '@/interfaces/shared';
import { metersToHtml, removeSectionIdFromPath } from '@/shared/utils';
import { copyStoreOptions, getBaseReturnedResult, getPlainParameter, unwrap, } from '@30/stores/calculatorCommon/utils';
import { cloneDeep, get, merge } from 'lodash';
export const getStoreForNewSolutionsStructure = () => {
    const copied = copyStoreOptions({
        id: 'newSolutionsStructureStore',
        state: () => ({
        //
        }),
        getters: {
            getAllDataAndResults: state => {
                const payloadIn = state.calculationResult?.payloadIn?.payload;
                const sharedResults = state.calculationResult?.payloadOut?.data.sharedResults;
                const proposedSolution = state.calculationResult?.payloadOut?.data.selection[0]
                    .proposedSolution;
                const data = merge({}, payloadIn, sharedResults, proposedSolution);
                const resultsView = state.calculationResult?.resultsView;
                return { data, resultsView };
            },
            /**
             * Internal function used only in getResultParameters
             * @returns information about parameter from result sharedResults and calculationParameters scopes
             */
            getResultSharedParam() {
                return (key) => {
                    const { data, resultsView } = this.getAllDataAndResults;
                    const rawValue = get(data, key);
                    if (rawValue === null ||
                        rawValue === undefined ||
                        (isValueWithUnit(rawValue) && rawValue.value === null)) {
                        return null;
                    }
                    const resultViewValue = get(resultsView, removeSectionIdFromPath(key));
                    return getPlainParameter(key, rawValue, resultViewValue);
                };
            },
            getResultSharedParamFromArray() {
                return (key) => {
                    const { data, resultsView } = this.getAllDataAndResults;
                    const rawValue = get(data, key);
                    if (rawValue === null || rawValue === undefined) {
                        return null;
                    }
                    const resultViewValue = get(resultsView, key);
                    const rowKeys = Object.keys(rawValue[0]);
                    const resultValues = Object.fromEntries(rowKeys.map(rowKey => [rowKey, resultViewValue[rowKey]]));
                    return rawValue.map(row => {
                        return Object.fromEntries(rowKeys.map(key => [
                            key,
                            getPlainParameter(key, row[key], resultValues[key]),
                        ]));
                    });
                };
            },
            /**
             * Internal function used only in getResultParameters
             * @param productName name of product for params
             * @returns information about product characteristic from result proposedSolution
             */
            getResultSolutionSeriesParam: state => {
                return (
                // TODO: better typing for series
                key, productName, currentSectionsIndex = 0) => {
                    const series = cloneDeep(state.calculationResult.payloadOut?.data.selection[currentSectionsIndex]?.proposedSolution[productName]?.characteristic);
                    if (series?.[key] === null || series?.[key] === undefined) {
                        return null;
                    }
                    const allResultsView = unwrap(state.calculationResult?.resultsView);
                    const resultsView = allResultsView?.series?.[productName] || allResultsView.solutions;
                    const resultsViewElement = resultsView[key];
                    const baseReturnedResult = getBaseReturnedResult(key, resultsViewElement);
                    if (isValueWithUnitExtended(series[key])) {
                        return {
                            ...baseReturnedResult,
                            value: series[key]?.value,
                            unit: metersToHtml(series[key]?.unit),
                        };
                    }
                    else {
                        return {
                            ...baseReturnedResult,
                            value: series[key],
                            unit: '',
                        };
                    }
                };
            },
            /**
             * Internal function used only in getResultParameters
             * @param productName name of product for params
             * @returns information about parameter calculated by Lambda (specific for product) from result proposedSolution
             */
            getResultSolutionCalculatedParam: state => {
                return (keyChain, productName, currentSectionsIndex) => {
                    const calculated = cloneDeep(state.calculationResult.payloadOut?.data.selection[currentSectionsIndex]?.proposedSolution[productName]?.calculated);
                    const resultsView = state.calculationResult?.resultsView;
                    const nestedElement = get(calculated, keyChain);
                    if (!isDefined(nestedElement)) {
                        return null;
                    }
                    const resultsViewElement = get(resultsView, removeSectionIdFromPath(keyChain));
                    return getPlainParameter(keyChain, nestedElement, resultsViewElement);
                };
            },
            /**
             * Internal function used only in getResultParameters
             * @param productName name of product for params
             * @returns information about parameter calculated by Lambda (specific for product) from result proposedSolution
             */
            getSingleSelectionResultParam: state => {
                return (keyChain, currentSectionsIndex) => {
                    const singleSelectionResult = cloneDeep(state.calculationResult.payloadOut?.data.selection[currentSectionsIndex]?.singleSelectionResults);
                    const resultsView = state.calculationResult?.resultsView;
                    const nestedElement = get(singleSelectionResult, keyChain);
                    if (!isDefined(nestedElement)) {
                        return null;
                    }
                    const resultsViewElement = get(resultsView, removeSectionIdFromPath(keyChain));
                    const baseReturnedResult = getBaseReturnedResult(keyChain, resultsViewElement);
                    if (isValueWithUnitExtended(nestedElement)) {
                        return {
                            ...baseReturnedResult,
                            value: nestedElement.value,
                            unit: nestedElement.unit
                                ? metersToHtml(nestedElement.unit)
                                : nestedElement.unit,
                            chart: nestedElement.chart,
                        };
                    }
                    else {
                        return {
                            ...baseReturnedResult,
                            value: nestedElement,
                            unit: '',
                            chart: nestedElement.chart,
                        };
                    }
                };
            },
            /**
             * It search parameter in different scopes using getResultSharedParam, getResultSolutionSeriesParam and getResultSolutionCalculatedParam.
             * It's important to check if parameter names that contains different values are not repeated in some scopes (shared, product from series and calculated for series)
             * @param neededValues array of searched values contains values as key of one of the scoped parameters and optional productName
             * @returns information about parameter calculated by Lambda (specific for product) from result proposedSolution
             */
            getResultParameters() {
                return (
                // TODO: add typing for series
                neededValues, currentSectionsIndex = 0) => {
                    return neededValues.map(neededValue => {
                        if (neededValue.productName) {
                            return (this.getResultSolutionCalculatedParam(neededValue.value, neededValue.productName, currentSectionsIndex) ||
                                this.getResultSolutionSeriesParam(neededValue.value, neededValue.productName, currentSectionsIndex));
                        }
                        else {
                            return (this.getResultSharedParam(neededValue.value) ||
                                this.getSingleSelectionResultParam(neededValue.value, currentSectionsIndex));
                        }
                    });
                };
            },
            /**
             * It search parameter in getResultSharedParam scope, used in collapse in historical calculation
             * @param neededValues array of searched values contains values as key of one of the scoped parameters and marginTop to divade params blocks
             * @returns information about parameters from result sharedResults and calculationParameters scopes
             */
            getParametersForCalculationData() {
                return (neededValues) => {
                    const parameters = this.getResultParameters(neededValues);
                    parameters.forEach((parameter, index) => {
                        if (parameter) {
                            parameter.marginTop = neededValues[index].marginTop;
                        }
                    });
                    return parameters;
                };
            },
        },
        actions: {
        //
        },
    });
    return copied;
};

import { api } from '@/axios/axios';
import { useConfigFrontDependencies } from '@30/composables/useConfigFrontDependencies';
import { FieldSpecialType, isConfigObject, isConfigSelectArray, isValueWithUnit, } from '@/interfaces/shared';
import { getAsync, postAsync } from '@30/shared/asyncAxiosUtil';
import { removeSectionIdFromPath } from '@/shared/utils';
import { calculatorIdsForValueUnit, currentConfigNestedFieldPathLength, initConfigNestedFieldPathLength, doubleNestedCalculators, } from '@30/stores/calculatorCommon/constant';
import { copyStoreOptions } from '@30/stores/calculatorCommon/utils';
import { cloneDeep, get, merge, set } from 'lodash';
;
//TODO: eliminate default void type - all stores should have defined those parameters
// or parameters should be changed
export const getCommonStore = () => {
    const copied = copyStoreOptions({
        id: 'commonCalculatorStore',
        state: () => ({
            status: {
                error: {
                    value: false,
                    message: '',
                },
                success: false,
                pending: false,
            },
        }),
        persist: {
            storage: sessionStorage,
            afterRestore: ctx => {
                ctx.store.$state.showModal = false;
                ctx.store.$state.form = null;
            },
        },
        getters: {
            getProperConfig: state => {
                const initConfigPayload = state.calculatorInitConfig.configFront?.payload;
                return (key) => state.calculatorCurrentConfigFrontPayload?.[key] ??
                    initConfigPayload?.[key];
            },
            /**
             * Mapping dependencies to flat form: [dependant, trigger[]][]
             * @param state - store state
             * @returns array of entries, where first element is dependant field
             * and second is array of it's triggers (fields which may change something in dependant field)
             */
            getDependenciesEntries(state) {
                const configDependencies = state.calculatorInitConfig.configFrontDependencies;
                if (!configDependencies) {
                    return [];
                }
                const getDependencyTriggerInputs = (rules) => {
                    return rules.flatMap(rule => Object.keys(rule.changed));
                };
                return Object.entries(configDependencies).map(([inputToSet, rules]) => {
                    return [inputToSet, getDependencyTriggerInputs(rules)];
                });
            },
            /**
             * Inverse dependencies - return function which returns all dependant fields
             */
            getDependenciesForTrigger() {
                return (possibleTriggerInput) => {
                    const connectedDependencies = this.getDependenciesEntries.filter(([, triggerInputs]) => triggerInputs.includes(possibleTriggerInput));
                    return connectedDependencies.map(([inputToSet]) => inputToSet);
                };
            },
            getSingleSelectionResult(state) {
                return (sectionIndex = 0) => {
                    return state.calculationResult?.payloadOut?.data.selection[sectionIndex].singleSelectionResults;
                };
            },
            getResultCalculated(state) {
                return (product, sectionIndex = 0) => {
                    return state.calculationResult?.payloadOut.data.selection[sectionIndex].proposedSolution?.[product].calculated;
                };
            },
            getResultCharacteristic(state) {
                return (product, sectionIndex = 0) => {
                    return state.calculationResult?.payloadOut.data.selection[sectionIndex].proposedSolution?.[product].characteristic;
                };
            },
            getPayloadOut(state) {
                return state.calculationResult.payloadOut;
            },
            getValidationStepIndex(state) {
                return state.isValid.length - 1;
            },
            getIsValid(state) {
                return state.isValid
                    .filter((_validationState, index) => index < this.getValidationStepIndex)
                    .every(validationState => validationState);
            },
        },
        actions: {
            setStatus(newStatus) {
                this.status[newStatus.key] = newStatus.value;
            },
            //TODO: better typings but no typeof this.calculationResult
            // cause it crash TS for other actions
            setCalculationResult(data) {
                this.calculationResult = data;
            },
            async postCalculation(parameters, options) {
                //exception for 26, 27, 31, transforming data from null to object
                if (this.calculatorId === 27 ||
                    this.calculatorId === 26 ||
                    calculatorIdsForValueUnit.includes(this.calculatorId)) {
                    parameters = this.changeInvisibleToNull(parameters);
                }
                const result = await postAsync({
                    url: `module/${this.calculatorId}`,
                    payload: { ...parameters },
                    setStatus: this.setStatus,
                    options,
                });
                if (result) {
                    // result will be undefined if post is cancelled
                    this.setCalculationResult(result);
                }
            },
            /**
             * @param parameters parameters for calculation (for now as argument,
             * because in some calculators there are changes in parameters before post)
             * @returns parameters with null or { value: null, unit } in invisible inputs
             */
            changeInvisibleToNull(parameters) {
                if (!parameters)
                    return;
                const { payload } = parameters;
                for (const [key] of Object.entries(payload)) {
                    const currentKeyConfig = this.getFieldConfig(key);
                    if (currentKeyConfig && !currentKeyConfig.visible) {
                        if (currentKeyConfig.defaultUnit &&
                            key !== 'rainfallDuration' &&
                            key !== 'canalFlowTime') {
                            payload[key] = {
                                value: null,
                                unit: currentKeyConfig.defaultUnit,
                            };
                        }
                        else {
                            payload[key] = null;
                        }
                    }
                }
                return parameters;
            },
            updateFieldKeyForConfig(key) {
                const segments = key.split('.');
                let result = segments.shift();
                if (!result) {
                    return key;
                }
                segments.forEach((segment, i) => {
                    if (segment.match(/^\d+$/)) {
                        result += `.items.${segment}`;
                    }
                    else {
                        const isInSection = i > 0 && segments[i - 1].match(/^\d+$/);
                        if (isInSection)
                            result += `.${segment}`;
                        else
                            result += `.items.${segment}`;
                    }
                });
                return result;
            },
            getFieldInitConfig(fieldKey) {
                const fieldKeyWithoutSection = removeSectionIdFromPath(fieldKey);
                // Nested array only available for pumping station calculator
                const isNestedField = fieldKey.split('.').length === initConfigNestedFieldPathLength;
                if (isNestedField &&
                    doubleNestedCalculators.includes(this.calculatorId)) {
                    const configKey = this.updateFieldKeyForConfig(removeSectionIdFromPath(fieldKeyWithoutSection));
                    return get(this.calculatorInitConfig?.configFront.payload, configKey);
                }
                const configKey = this.updateFieldKeyForConfig(fieldKeyWithoutSection);
                return get(this.calculatorInitConfig?.configFront.payload, configKey);
            },
            getFieldConfig(fieldKey) {
                const configKey = this.updateFieldKeyForConfig(fieldKey);
                this.checkCurrentConfig(configKey);
                return get(this.calculatorCurrentConfigFrontPayload, configKey);
            },
            /**
             * If field has default unit then compose default value with default unit.
             * Otherwise return default value.
             * @param initConfig - initial config for field
             */
            getProperDefaultValue(initConfig) {
                const { defaultValue, defaultUnit } = initConfig;
                //for new calculators add exception and don't remove defaultValue !== null
                // in future we want to remove it to get { value: null, unit } for all calculator
                if (defaultUnit &&
                    (defaultValue !== null ||
                        calculatorIdsForValueUnit.includes(this.calculatorId))) {
                    return {
                        value: defaultValue,
                        unit: defaultUnit,
                    };
                }
                return defaultValue ?? null;
            },
            checkCurrentConfig(fieldKey) {
                const isNotDefinedCurrentConfig = !get(this.calculatorCurrentConfigFrontPayload, fieldKey);
                if (isNotDefinedCurrentConfig &&
                    this.calculatorCurrentConfigFrontPayload) {
                    const fieldRootKey = fieldKey.split('.')[0];
                    const rootInitConfig = this.getFieldInitConfig(fieldRootKey);
                    const notHandledObjectArrays = [
                        'reducedAreaTable',
                        'charts',
                        'sewageWellPipeArray',
                    ];
                    // TODO: handle reduced area as objectarray, for now a lot of work to check in other calculators
                    if (rootInitConfig?.type !== FieldSpecialType.ObjectArray ||
                        notHandledObjectArrays.includes(fieldRootKey)) {
                        const copyFromInit = cloneDeep(get(this.calculatorInitConfig?.configFront.payload, fieldKey));
                        set(this.calculatorCurrentConfigFrontPayload, fieldKey, copyFromInit);
                    }
                    else {
                        const initSectionConfigs = cloneDeep(rootInitConfig.items);
                        const fieldKeySegments = fieldKey.split('.');
                        const isNestedField = fieldKeySegments.length === currentConfigNestedFieldPathLength;
                        // Get root element index (e.g. section)
                        const rootElementIndex = fieldKeySegments[2];
                        const fieldNestedKey = isNestedField ? fieldKeySegments[3] : null;
                        // Nested array only available for pumping station calculator
                        if (isNestedField &&
                            fieldNestedKey &&
                            doubleNestedCalculators.includes(this.calculatorId)) {
                            const initSubSectionConfigs = get(this.calculatorInitConfig?.configFront.payload, `${fieldRootKey}.items.${fieldNestedKey}`);
                            this.calculatorCurrentConfigFrontPayload[fieldRootKey]?.items[rootElementIndex][fieldNestedKey]?.items.push(initSubSectionConfigs.items);
                            return;
                        }
                        // Nested array only available for pumping station calculator
                        if (doubleNestedCalculators.includes(this.calculatorId)) {
                            Object.entries(initSectionConfigs).forEach(([key, config]) => {
                                if (config.type === FieldSpecialType.ObjectArray) {
                                    const nestedConfig = cloneDeep(config.items);
                                    initSectionConfigs[key].items = [nestedConfig];
                                }
                            });
                        }
                        this.calculatorCurrentConfigFrontPayload[fieldRootKey]?.items.push(initSectionConfigs);
                    }
                }
            },
            mutateParameterVisibility(data) {
                const fieldConfig = this.getFieldConfig(data.key);
                fieldConfig.visible = data.value;
            },
            resetParameterVisibility(fieldKey) {
                const fieldInitConfig = this.getFieldInitConfig(fieldKey);
                this.mutateParameterVisibility({
                    key: fieldKey,
                    value: fieldInitConfig.visible,
                });
            },
            mutateParameterDisability(data) {
                const fieldConfig = this.getFieldConfig(data.key);
                fieldConfig.disable = data.value;
                if (data.value && isConfigObject(fieldConfig)) {
                    this.resetChildrenValue(data.key, fieldConfig);
                }
            },
            resetParameterDisability(fieldKey) {
                const fieldInitConfig = this.getFieldInitConfig(fieldKey);
                this.mutateParameterDisability({
                    key: fieldKey,
                    value: fieldInitConfig.disable,
                });
            },
            resetParameterValue(fieldKey) {
                const fieldInitConfig = this.getFieldInitConfig(fieldKey);
                const newValue = this.getProperDefaultValue(fieldInitConfig);
                this.mutateCalculationParameters({
                    key: fieldKey,
                    value: newValue,
                });
            },
            resetChildrenValue(parentKey, config) {
                Object.entries(config.items).forEach(([childKey, childConfig]) => {
                    const childComposedKey = [parentKey, childKey].join('.');
                    this.mutateCalculationParameters({
                        key: childComposedKey,
                        value: this.getProperDefaultValue(childConfig),
                    });
                });
            },
            mutateParameterItems(data) {
                const initItems = this.getFieldInitConfig(data.key).items;
                if (Array.isArray(initItems)) {
                    const fieldConfig = this.getFieldConfig(data.key);
                    const newItems = initItems.filter((item) => {
                        return data.newItemsValues.includes(item.value);
                    });
                    fieldConfig.items = newItems;
                }
                this.setDefaultIfNotInItems(data.key);
            },
            resetParameterItems(fieldKey) {
                const fieldInitConfig = this.getFieldInitConfig(fieldKey);
                const fieldConfig = this.getFieldConfig(fieldKey);
                fieldConfig.items = fieldInitConfig.items;
                this.setDefaultIfNotInItems(fieldKey);
            },
            mutateInvestData(payload) {
                if (this.calculationParameters) {
                    this.calculationParameters.investData[payload.key] = payload.value;
                }
            },
            mutateCalculationParameters(data) {
                if (this.calculationParameters?.payload) {
                    set(this.calculationParameters.payload, data.key, data.value);
                }
            },
            getCalculationValue(key) {
                return get(this.calculationParameters?.payload, key);
            },
            setAllFieldsCurrentConfig() {
                const entries = Object.entries(this.calculatorInitConfig?.configFront.payload);
                entries.forEach(([key, config]) => {
                    this.calculatorCurrentConfigFrontPayload[key] = cloneDeep(config);
                    if (config?.type === FieldSpecialType.ObjectArray) {
                        const subConfigs = cloneDeep(config.items);
                        // Nested array only available for pumping station calculator
                        if (doubleNestedCalculators.includes(this.calculatorId)) {
                            Object.entries(subConfigs).forEach(([subKey, subConfig]) => {
                                if (subConfig?.type === FieldSpecialType.ObjectArray) {
                                    const subSubConfigs = cloneDeep(subConfig.items);
                                    subConfigs[subKey].items = [subSubConfigs];
                                }
                            });
                        }
                        this.calculatorCurrentConfigFrontPayload[key].items = [subConfigs];
                    }
                });
            },
            mutateStatusValidation(payload) {
                this.isValid.splice(payload.index, 1, payload.val);
            },
            refreshDependencies(fieldPath) {
                const fieldName = removeSectionIdFromPath(fieldPath);
                const { setDependentInputs } = useConfigFrontDependencies(this);
                const dependencies = this.getDependenciesForTrigger(fieldName);
                if (dependencies) {
                    dependencies.forEach(inputToSet => {
                        setDependentInputs(inputToSet, fieldPath);
                    });
                }
            },
            setDefaultIfNotInItems(fieldKey) {
                const fieldConfig = this.getFieldConfig(fieldKey);
                const fieldValue = this.getCalculationValue(fieldKey);
                // setting default item only for fields with array of items
                if (!fieldConfig || !isConfigSelectArray(fieldConfig)) {
                    return;
                }
                const isValueInItems = fieldConfig.items.some(item => {
                    if (isValueWithUnit(fieldValue)) {
                        return item.value === fieldValue.value;
                    }
                    else {
                        return item.value === fieldValue;
                    }
                });
                if (!isValueInItems) {
                    this.resetParameterValue(fieldKey);
                }
            },
            setCalculatorConfigs(config) {
                if (!this.calculationParameters || !config.configCalculator)
                    return;
                this.setCalculatorConfigActionsBefore(config);
                const calculationParametersMeta = this.calculationParameters.meta;
                const configCalculatorMeta = config.configCalculator.meta;
                this.calculatorInitConfig = config;
                calculationParametersMeta.calculatorId =
                    configCalculatorMeta.calculatorId;
                calculationParametersMeta.calculationKey =
                    configCalculatorMeta.calculationKey;
                calculationParametersMeta.version = configCalculatorMeta.version;
                const investData = cloneDeep(config.configFront.investData);
                delete investData.coordinates;
                delete investData.investmentPlace;
                for (const [key, config] of Object.entries(investData)) {
                    investData[key] = config.defaultValue;
                }
                this.calculationParameters.investData = {
                    ...this.calculationParameters.investData,
                    ...investData,
                };
                const payload = cloneDeep(config.configFront.payload);
                if (!payload)
                    return;
                const setInitPayload = (payload) => {
                    const payloadEntries = Object.entries(payload);
                    for (const [key, config] of payloadEntries) {
                        if (config.defaultValue === undefined) {
                            if (config.type === 'objectarray') {
                                payload[key] = [payload[key].items];
                                setInitPayload(payload[key][0]);
                            }
                            else if (payload[key].type === 'object') {
                                payload[key] = payload[key].items;
                                setInitPayload(payload[key]);
                            }
                        }
                        else {
                            payload[key] = this.getProperDefaultValue(payload[key]);
                        }
                    }
                };
                setInitPayload(payload);
                this.calculationParameters.payload = {
                    ...this.calculationParameters.payload,
                    ...payload,
                };
                this.setAllFieldsCurrentConfig();
                this.setCalculatorConfigActionsAfter(config);
            },
            // overwrite if you want add additionall actions in setCalculatorConfigs action
            setCalculatorConfigActionsBefore(config) {
                return;
            },
            setCalculatorConfigActionsAfter(config) {
                return;
            },
            setForm(form) {
                this.form = form;
            },
            /*
             * overwritten in 12, 15 stores
             */
            setSolution(newSolution) {
                // comment from 6 store: problems with proposedSolution
                api.post('updateProposedSolution', {
                    calculationKey: this.calculationResult?.payloadIn.meta.calculationKey,
                    selectionIndexToUpdate: 0,
                    solutionUuidToUpdate: newSolution.solutionUUID,
                });
                this.calculationResult.payloadOut.data.selection[0].proposedSolution =
                    newSolution;
            },
            async initModule() {
                const config = await getAsync({
                    url: `module/${this.calculatorId}`,
                    setStatus: this.setStatus,
                });
                await this.setCalculatorConfigs(config);
                this.isInitialized = true;
                return config;
            },
            async fetchHistoricalCalculation(calculationKey) {
                const result = await getAsync({
                    url: `user/calculation/${calculationKey}`,
                    setStatus: this.setStatus,
                });
                if (!result.payloadOut?.status) {
                    result.payloadOut = null;
                }
                this.setCalculationResult(result);
                this.fetchHistoricalCalculationActionsAfter(calculationKey);
            },
            fetchHistoricalCalculationActionsAfter(calculationKey) {
                return;
            },
            /**
             * Overwritten in 12, 29 store
             */
            async recalculateCalculation(data) {
                const { setInitialDependencies } = useConfigFrontDependencies(this);
                await this.router.push({
                    name: `${this.$id}.investment-data`,
                    replace: true,
                });
                if (!this.calculationParameters)
                    return;
                // merge for historical calculations which doesn't have some parameters and shouldn't override them
                merge(this.calculationParameters, {
                    investData: data.investData,
                    payload: data.payload,
                });
                setInitialDependencies();
                this.recalculateCalculationAfter();
            },
            recalculateCalculationAfter() {
                return;
            },
        },
    });
    return copied;
};

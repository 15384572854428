const homeReservoirInSituRouter = {
    path: "wykorzystanie-deszczowki",
    name: "home-reservoir-in-situ",
    redirect: { name: "home-reservoir-in-situ.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            meta: { partner: "zb-przydomowe" },
            name: "home-reservoir-in-situ.investment-data",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            meta: { partner: "zb-przydomowe" },
            name: "home-reservoir-in-situ.choosing-parameters",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wyniki",
            meta: { partner: "zb-przydomowe" },
            name: "home-reservoir-in-situ.results",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            meta: { partner: "zb-przydomowe" },
            name: "home-reservoir-in-situ.archive",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default homeReservoirInSituRouter;

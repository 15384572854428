import { isValueWithUnit } from '@/interfaces/shared/calculators';
/**
 * Check if value is not null and not undefined.
 * Useful for Array.filter typing to remove nullable values
 * @param value tested value
 */
export const isDefined = (value) => {
    return value !== null && value !== undefined;
};
export const isNotNullValueWithUnit = (value) => {
    return isValueWithUnit(value) ? value.value !== null : true;
};

import { useMechanicalGridsStore } from '@32/stores/32_mechanicalGrids';
import { getCalculatorBeforeEnterGuard } from '@/shared/routerUtils';
import { ADDITIONAL_PARAMS_ROUTER_PATH, RESULT_ROUTER_PATH, } from '@32/constant';
const mechanicalGridsRouter = {
    path: 'kraty-mechaniczne',
    name: 'mechanical-grids',
    redirect: { name: 'mechanical-grids.investment-data' },
    meta: { partner: 'tormel' },
    component: () => import('@32/pages/MechanicalGrids.vue'),
    beforeEnter: getCalculatorBeforeEnterGuard(useMechanicalGridsStore),
    children: [
        {
            path: 'dane-inwestycji',
            name: 'mechanical-grids.investment-data',
            components: {
                left: () => import('@32/investmentData/InvestmentData.vue'),
                right: () => import('@32/investmentData/InvestmentMap.vue'),
            },
        },
        {
            path: 'wybor-parametrow',
            name: 'mechanical-grids.choosing-parameters',
            components: {
                left: () => import('@32/choosingParameters/ChoosingParametersForm.vue'),
                right: () => import('@32/choosingParameters/ChoosingParametersSchema.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                const calculatorStore = useMechanicalGridsStore();
                if (calculatorStore.isValid[0]) {
                    next();
                }
                else {
                    next({ name: 'mechanical-grids.investment-data' });
                }
            },
        },
        {
            path: ADDITIONAL_PARAMS_ROUTER_PATH,
            name: 'mechanical-grids.additional-requirements',
            components: {
                left: () => import('@32/additionalRequirements/AdditionalRequirementsForm.vue'),
                right: () => import('@32/additionalRequirements/AdditionalRequirementsSchema.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                const calculatorStore = useMechanicalGridsStore();
                if (calculatorStore.isValid[1]) {
                    next();
                }
                else {
                    next({ name: 'mechanical-grids.investment-data' });
                }
            },
        },
        {
            path: RESULT_ROUTER_PATH,
            name: 'mechanical-grids.selecting-products',
            components: {
                left: () => import('@32/resultCalculation/CalcResult.vue'),
                right: () => import('@32/resultCalculation/SelectingProducts.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                const calculatorStore = useMechanicalGridsStore();
                if (calculatorStore.isValid[2]) {
                    next();
                }
                else {
                    next({ name: 'mechanical-grids.investment-data' });
                }
            },
        },
        {
            path: 'dobor-archiwalny/:calculationKey',
            name: 'mechanical-grids.archive',
            meta: { isArchive: true },
            components: {
                left: () => import('@32/archive/CalcResult.vue'),
                right: () => import('@32/archive/ResultCalculationData.vue'),
            },
            beforeEnter: async (to, _from, next) => {
                const calculatorStore = useMechanicalGridsStore();
                try {
                    await calculatorStore.fetchHistoricalCalculation(to.params.calculationKey);
                    next();
                }
                catch {
                    next({
                        name: 'dashboard', // back to safety route //
                    });
                }
            },
        },
    ],
};
export default mechanicalGridsRouter;

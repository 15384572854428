import { useOpenedInfiltrationTanksStore } from '@30/stores/30_openedInfiltrationTanks';
import { getCalculatorBeforeEnterGuard } from '@/shared/routerUtils';
import { RESULT_ROUTER_PATH } from '@30/constant';
const openedInfiltrationTanksRouter = {
    path: 'zbiorniki-infiltracyjne-otwarte',
    name: 'opened-infiltration-tanks',
    redirect: { name: 'opened-infiltration-tanks.investment-data' },
    meta: { partner: 'tormel' },
    component: () => import('@30/pages/OpenedInfiltrationTanks.vue'),
    beforeEnter: getCalculatorBeforeEnterGuard(useOpenedInfiltrationTanksStore),
    children: [
        {
            path: 'dane-inwestycji',
            name: 'opened-infiltration-tanks.investment-data',
            components: {
                left: () => import('@30/investmentData/InvestmentData.vue'),
                right: () => import('@30/investmentData/InvestmentMap.vue'),
            },
        },
        {
            path: 'wybor-parametrow',
            name: 'opened-infiltration-tanks.choosing-parameters',
            components: {
                left: () => import('@30/choosingParameters/ChoosingParametersForm.vue'),
                right: () => import('@30/choosingParameters/ChoosingParametersSchema.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                const calculatorStore = useOpenedInfiltrationTanksStore();
                if (calculatorStore.isValid[0]) {
                    next();
                }
                else {
                    next({ name: 'opened-infiltration-tanks.investmentData' });
                }
            },
        },
        {
            path: RESULT_ROUTER_PATH,
            name: 'opened-infiltration-tanks.selecting-products',
            components: {
                left: () => import('@30/resultCalculation/CalcResult.vue'),
                right: () => import('@30/resultCalculation/SelectingProducts.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                const calculatorStore = useOpenedInfiltrationTanksStore();
                if (calculatorStore.isValid[0] && calculatorStore.isValid[1]) {
                    next();
                }
                else {
                    next({ name: 'opened-infiltration-tanks.investment-data' });
                }
            },
        },
        {
            path: 'dobor-archiwalny/:calculationKey',
            name: 'opened-infiltration-tanks.archive',
            meta: { isArchive: true },
            components: {
                left: () => import('@30/archive/CalcResult.vue'),
                right: () => import('@30/archive/ResultCalculationData.vue'),
            },
            beforeEnter: async (to, _from, next) => {
                const calculatorStore = useOpenedInfiltrationTanksStore();
                try {
                    await calculatorStore.fetchHistoricalCalculation(to.params.calculationKey);
                    next();
                }
                catch {
                    next({
                        name: 'dashboard', // back to safety route //
                    });
                }
            },
        },
    ],
};
export default openedInfiltrationTanksRouter;

import { copyStoreOptions } from '@30/stores/calculatorCommon/utils';
import { cloneDeep } from 'lodash';
export const getSectionsStore = () => {
    const copied = copyStoreOptions({
        id: 'sectionsStore',
        state: () => ({
        //
        }),
        getters: {
        //
        },
        actions: {
            /**
             * addSection, removeActiveSection, setActiveSection - actions to operate objectArray & SectionList component
             * @param sectionKey key of the objectArray
             */
            addSection(sectionKey) {
                this.calculationParameters.payload[sectionKey].push(cloneDeep(this.defaultSection));
                this.setActiveSection(sectionKey);
            },
            removeActiveSection(sectionKey) {
                const activeSectionIndex = this.getActiveSectionIndex(sectionKey);
                this.calculationParameters.payload[sectionKey].splice(activeSectionIndex, 1);
                this.calculatorCurrentConfigFrontPayload[sectionKey]?.items.splice(activeSectionIndex, 1);
                this.setActiveSection(sectionKey);
            },
            setActiveSection(sectionKey, index) {
                const sections = this.calculationParameters.payload[sectionKey];
                const activeIndex = index ?? sections.length - 1;
                sections.forEach((section, sectionIndex) => {
                    section.isActive = activeIndex === sectionIndex;
                });
            },
            getActiveSectionIndex(sectionKey) {
                return this.calculationParameters.payload[sectionKey]?.findIndex(section => section.isActive);
            },
        },
    });
    return copied;
};

export const calculatorIdsForValueUnit = [
    13, 15, 17, 18, 19, 21, 23, 30, 31, 32, 4,
];
export const doubleNestedCalculators = [7];
/**
 * For arrays only!
 *
 * Normal array: mainFieldKey.items.fieldKey (= 3)
 * E.g. section3.items.condition
 * Nested array: mainFieldKey.items.subFieldKey.items.fieldKey (= 5)
 * E.g. section3.items.localLosses.items.coefficient
 */
export const initConfigNestedFieldPathLength = 5;
/**
 * Normal array: sectionName.items.sectionIndex.fieldKey (= 5)
 * E.g. section3.items.1.condition points to condition of the second section
 * Nested array: sectionName.items.sectionIndex.subSectionName.items.subSectionIndex.fieldKey (= 7)
 * E.g. section3.items.1.localLosses.items.2.quantity points to quantity of the third LocalLoss (second section)
 */
export const currentConfigNestedFieldPathLength = 7;

const gravityCablesRouter = {
    path: "przewody-grawitacyjne",
    name: "gravity-cables",
    redirect: { name: "gravity-cables.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            name: "gravity-cables.investment-data",
            meta: { partner: "uponor" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            name: "gravity-cables.choosing-parameters",
            meta: { partner: "uponor" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-zbiornika",
            name: "gravity-cables.selecting-products",
            meta: { partner: "uponor" },
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            meta: { partner: "uponor" },
            name: "gravity-cables.archive",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default gravityCablesRouter;

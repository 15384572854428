import { createApp, h, markRaw } from 'vue';
import App from './App.vue';
import singleSpaVue from 'single-spa-vue';
import Quasar from 'quasar/src/vue-plugin.js';
;
import quasarUserOptions from './quasar-user-options';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-icons-round/material-icons-round.css';
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css';
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css';
import '@quasar/extras/material-symbols-rounded/material-symbols-rounded.css';
import '@quasar/extras/material-symbols-sharp/material-symbols-sharp.css';
// import '@quasar/extras/mdi-v6/mdi-v6.css'
import 'quasar/src/css/index.sass';
import '@/scss/app.scss';
import i18n from '@/i18n';
import { createPinia } from 'pinia';
import { router } from '@/router/index';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(({ store }) => {
    store.router = markRaw(router);
});
app.use(Quasar, quasarUserOptions);
app.use(i18n);
app.use(router);
app.use(pinia);
if (process.env.VUE_APP_MODE === 'dev-single-vue3') {
    app.mount('#app-vue3');
}
const vueLifecycles = singleSpaVue({
    createApp,
    appOptions: {
        render() {
            return h(App, {
                props: {
                    // single-spa props are available on the "this" object. Forward them to your component as needed.
                    // https://single-spa.js.org/docs/building-applications#lifecyle-props
                    name: this.name,
                },
            });
        },
        el: '#app-vue3',
    },
    handleInstance: app => {
        app.use(Quasar, quasarUserOptions);
        app.use(i18n);
        app.use(router);
        app.use(pinia);
    },
});
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
// export function mount() {
//   // do something when mount
//   return vueLifecycles.mount;
// }
// export function unmount() {
//   // do something when unmount
//   console.log('VUE3 UNMOUNT')
//   return vueLifecycles.unmount;
// }

export var AlertType;
(function (AlertType) {
    AlertType["Primary"] = "primary";
    AlertType["Success"] = "success";
    AlertType["Warning"] = "warning";
    AlertType["Danger"] = "danger";
})(AlertType || (AlertType = {}));
export var ButtonType;
(function (ButtonType) {
    ButtonType["Primary"] = "primary";
    ButtonType["Secondary"] = "secondary";
    ButtonType["Success"] = "success";
    ButtonType["Danger"] = "danger";
    ButtonType["Warning"] = "warning";
    ButtonType["Violet"] = "violet";
    ButtonType["White"] = "white";
})(ButtonType || (ButtonType = {}));
export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["LG"] = "lg";
    ButtonSize["MD"] = "md";
    ButtonSize["SM"] = "sm";
})(ButtonSize || (ButtonSize = {}));

const citiesOpenReservoirRouter = {
    path: "zbiornik-otwarty",
    name: "cities-open-reservoir",
    redirect: { name: "cities-open-reservoir.investment-data" },
    component: () => import('@/pages/EmptyPage.vue'),
    children: [
        {
            path: "dane-inwestycji",
            meta: { partner: "zb-otwarte" },
            name: "cities-open-reservoir.investment-data",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-parametrow",
            meta: { partner: "zb-otwarte" },
            name: "cities-open-reservoir.choosing-parameters",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "wybor-zbiornika",
            meta: { partner: "zb-otwarte" },
            name: "cities-open-reservoir.selecting-products",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
        {
            path: "dobor-archiwalny/:calculationKey",
            meta: { partner: "zb-otwarte" },
            name: "cities-open-reservoir.archive",
            components: {
                left: () => import('@/pages/EmptyPage.vue'),
                right: () => import('@/pages/EmptyPage.vue'),
            },
        },
    ],
};
export default citiesOpenReservoirRouter;

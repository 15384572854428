import i18n from '@/i18n';
export const gridsDescription = {
    RKE: i18n.global.t('mechanicalGrids.gridsDescription.RKE'),
    RP: i18n.global.t('mechanicalGrids.gridsDescription.RP'),
    KR: i18n.global.t('mechanicalGrids.gridsDescription.KR'),
    RSK: i18n.global.t('mechanicalGrids.gridsDescription.RSK'),
    RMKE: i18n.global.t('mechanicalGrids.gridsDescription.RMKE'),
};
export const RESULT_ROUTER_PATH = 'wybor-krat';
export const ADDITIONAL_PARAMS_ROUTER_PATH = 'wymogi-dodatkowe';

import { api } from '@/axios/axios';
export const postAsync = async (params) => {
    params.setStatus({ key: 'pending', value: true });
    try {
        const response = await api.post(params.url, params.payload);
        if (response.data.status) {
            params.setStatus({ key: 'pending', value: false });
            params.setStatus({ key: 'error', value: { value: false, message: '' } });
            params.setStatus({ key: 'success', value: true });
        }
        else {
            params.setStatus({
                key: 'error',
                value: { value: true, message: 'Nie udało się dobrać typoszeregu' },
            });
        }
        return response.data.data;
    }
    catch (e) {
        params.setStatus({ key: 'pending', value: false });
        if (e.response) {
            // handle router
            // if (e.response.status === 401) {
            //     const entryUrl = (router as any).history.current.query.entryUrl;
            //     router.push({
            //         path: '/login',
            //         query: {entryUrl},
            //     });
            // }
            if (e.response.data.error) {
                if (e.response.data.error.hasOwnProperty('errors') &&
                    e.response.data.error.errors) {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.errors },
                    });
                }
                else {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.message },
                    });
                }
            }
            else if (e.response.data.data?.message) {
                params.setStatus({
                    key: 'error',
                    value: { value: true, message: e.response.data.data.message },
                });
            }
        }
        return e.response?.data;
    }
};
export const getAsync = async (params) => {
    params.setStatus({ key: 'pending', value: true });
    try {
        const response = await api.get(params.url);
        params.setStatus({ key: 'pending', value: false });
        params.setStatus({ key: 'error', value: { value: false, message: '' } });
        params.setStatus({ key: 'success', value: true });
        return response.data.data;
    }
    catch (e) {
        params.setStatus({ key: 'pending', value: false });
        if (e.response) {
            // handle router
            // if (e.response.status === 401) {
            //     const entryUrl = (router as any).history.current.query.entryUrl;
            //     router.push({
            //         path: '/login',
            //         query: {entryUrl},
            //     });
            // }
            if (e.response.data.error) {
                if (e.response.data.error.hasOwnProperty('errors') &&
                    e.response.data.error.errors) {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.errors },
                    });
                }
                else {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.message },
                    });
                }
            }
            else if (e.response.data.message) {
                params.setStatus({
                    key: 'error',
                    value: { value: true, message: e.response.data.message },
                });
            }
            else if (e.response.data.data.message) {
                params.setStatus({
                    key: 'error',
                    value: { value: true, message: e.response.data.data.message },
                });
            }
        }
        return e.response?.data;
    }
};
export const putAsync = async (params) => {
    params.setStatus({ key: 'pending', value: true });
    try {
        const response = await api.put(params.url, params.payload);
        params.setStatus({ key: 'pending', value: false });
        params.setStatus({ key: 'error', value: { value: false, message: '' } });
        params.setStatus({ key: 'success', value: true });
        return response.data.data;
    }
    catch (e) {
        params.setStatus({ key: 'pending', value: false });
        if (e.response) {
            // handle router
            // if (e.response.status === 401) {
            //     const entryUrl = (router as any).history.current.query.entryUrl;
            //     router.push({
            //         path: '/login',
            //         query: {entryUrl},
            //     });
            // }
            if (e.response.data.error) {
                if (e.response.data.error.hasOwnProperty('errors') &&
                    e.response.data.error.errors) {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.errors },
                    });
                }
                else {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.message },
                    });
                }
            }
            else if (e.response.data.data.message) {
                params.setStatus({
                    key: 'error',
                    value: { value: true, message: e.response.data.data.message },
                });
            }
        }
        return e.response.data;
    }
};
export const deleteAsync = async (params) => {
    params.setStatus({ key: 'pending', value: true });
    try {
        const response = await api.delete(params.url, {
            data: { ...params.payload },
        });
        params.setStatus({ key: 'pending', value: false });
        params.setStatus({ key: 'error', value: { value: false, message: '' } });
        params.setStatus({ key: 'success', value: true });
        return response.data.data;
    }
    catch (e) {
        params.setStatus({ key: 'pending', value: false });
        if (e.response) {
            // handle router
            // if (e.response.status === 401) {
            //     const entryUrl = (router as any).history.current.query.entryUrl;
            //     router.push({
            //         path: '/login',
            //         query: {entryUrl},
            //     });
            // }
            if (e.response.data.error) {
                if (e.response.data.error.hasOwnProperty('errors') &&
                    e.response.data.error.errors) {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.errors },
                    });
                }
                else {
                    params.setStatus({
                        key: 'error',
                        value: { value: true, message: e.response.data.error.message },
                    });
                }
            }
            else if (e.response.data.data.message) {
                params.setStatus({
                    key: 'error',
                    value: { value: true, message: e.response.data.data.message },
                });
            }
        }
        return e.response.data;
    }
};
